import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/components/login/index"
import Statistics from "@/pages/statistics"
import EmptyView from "@/pages/Layout/EmptyView";
import Home from "@/pages/home"
import Admin from "@/pages/admin"
import MemberList from "@/pages/memberList"
import AnchorList from "@/pages/anchorList"
import LiveList from "@/pages/liveList"
import LiveOrder from "@/pages/liveOrder"
import LivingList from "@/pages/livingList"
import LiveRecord from "@/pages/liveRecord"
import LeagueList from "@/pages/leagueList"
import MatchList from "@/pages/matchList"
import LeagueListB from "@/pages/leagueListB"
import MatchListB from "@/pages/matchListB"
import AdvList from "@/pages/advList"
import AnnounceManage from "@/pages/announceManage"
import AnnounceManageByRoom from "@/pages/announceManageByRoom"
import DownloadSet from "@/pages/downloadSet";
import ContentManage from "@/pages/contentManage"
import FeedbackManage from "@/pages/feedbackManage"
import DangerManageList from "@/pages/dangerManageList"
import Content_1 from "@/pages/content_1"
import Content_2 from "@/pages/content_2"
import Content_3 from "@/pages/content_3"
import Content_4 from "@/pages/content_4"
import Content_5 from "@/pages/content_5"
import Content_6 from "@/pages/content_6"
import Content_7 from "@/pages/content_7"
import Content_8 from "@/pages/content_8"
import Content_9 from "@/pages/content_9"
import Channel from "@/pages/channel"
import ChannelMonth from "@/pages/channelMonth"
import AppSet from "@/pages/appSet"
import AndVersion from "@/pages/andVersion"
import IosVersion from "@/pages/iosVersion"
import WebSet from "@/pages/webSet"
import WhiteList from "@/pages/whiteList"
import GreenBroadList from '@/pages/greenBroadList'
import Forbid from "@/pages/forbid"
import Authentication from "@/pages/authentication"
import RealNameList from "@/pages/realNameList"
import ExpertList from "@/pages/expertList"
import HaveList from "@/pages/haveList"
import HaveAudit from "@/pages/haveAudit"
import CashAudit from '@/pages/cashAudit'
import HaveDetails from '@/pages/haveDetails'
import Information from "@/pages/information"
import TopUpRecord from '@/pages/topUpRecord'
import ConsumerRecord from '@/pages/consumerRecord'
import SifangPayOrder from '@/pages/sifangPayOrder'
import PaymentChannel from '@/pages/paymentChannel'
import TopUpSetting from '@/pages/topUpSetting'
import GiftList from '@/pages/giftList'
import HaveSetting from '@/pages/haveSetting'
import ManualRechar from '@/pages/manualRechar'
import Billing from '@/pages/billing'
// import Marquee from "@/pages/marquee"


Vue.use(VueRouter)

const routes = [
    {path: "/", name: "Login", component: Login,},
    {path: "/admin", name: "Admin", component: Admin,},
    {path: "/statistics", name: "Statistics", component: Statistics,},
    {path: "/home", name: "Home", component: Home,},
    {path: "/memberList", name: "MemberList", component: MemberList},
    {path: "/whiteList", name: "WebSet", component: WhiteList},
    {path: "/anchorList", name: "AnchorList", component: AnchorList},
    {path: "/liveList", name: "LiveList", component: LiveList},
    {path: "/liveOrder", name: "liveOrder", component: LiveOrder},
    {path: "/livingList", name: "livingList", component: LivingList},
    {path: "/liveRecord", name: "LiveRecord", component: LiveRecord},
    {path: "/leagueList", name: "LeagueList", component: LeagueList},
    {path: "/matchList", name: "MatchList", component: MatchList},
    {path: "/leagueListB", name: "LeagueListB", component: LeagueListB},
    {path: "/matchListB", name: "MatchListB", component: MatchListB},
    {path: "/announceManage", name: "AnnounceManage", component: AnnounceManage},
    {path: "/announceManageByRoom", name: "AnnounceManageByRoom", component: AnnounceManageByRoom},
    {path: "/information", name: 'Information', component: Information},  //资讯管理
    // {path: '/marquee', name: 'Marquee', component: Marquee}, // 跑马灯
    {path: "/advList", name: "AdvList", component: AdvList},
    {path: "/contentManage", name: "ContentManage", component: ContentManage},
    {path: "/downloadSet", name: "DownloadSet", component: DownloadSet},
    {path: "/feedbackManage", name: "FeedbackManage", component: FeedbackManage},
    {path: "/dangerManageList", name: "DangerManageList", component: DangerManageList},
    {
      path: '/contentEdit',
      name: 'ContentEdit',
      component: EmptyView,
      redirect: '/contentEdit/list',
      children: [
          {
            path: '/contentEdit/list',
            name: 'ContentList',
            component: () => import('@/pages/ContentEdit/index'),
          },
          {
              path: '/contentEdit/edit',
              name: 'ContentEditContent',
              component: () => import('@/pages/ContentEdit/EditContent'),
          }
      ]
    },
    {
        path: '/quick-speech',
        name: 'QuickSpeech',
        component: EmptyView,
        redirect: '/quick-speech/common',
        children: [
            {
                path: '/quick-speech/common',
                name: 'CommonSpeech',
                component: () => import('@/pages/speech/CommonSpeech'),
            },
            {
                path: '/quick-speech/super',
                name: 'SupperSpeech',
                component: () => import('@/pages/speech/SupperSpeech'),
            }
        ]
    },
    {path: "/expertList", name: "expertList", component: ExpertList}, //专家列表
    {path: "/haveList", name: "haveList", component: HaveList}, //有料列表
    {path: "/haveAudit", name: "haveAudit", component: HaveAudit}, //有料审核
    {path: "/cashAudit", name: "cashAudit", component: CashAudit}, //提现审核
    {path: "/haveDetails", name: "haveDetails", component: HaveDetails}, //有料明细
    {path: "/haveSetting", name: "haveSetting", component: HaveSetting}, //有料设置
    {path: "/topUpRecord", name: "topUpRecord", component: TopUpRecord}, //充值记录
    {path: "/consumerRecord", name: "consumerRecord", component: ConsumerRecord}, //消费记录
    {path: "/sifangPayOrder", name: "sifangPayOrder", component: SifangPayOrder}, //四方支付
    {path: "/paymentChannel", name: "paymentChannel", component: PaymentChannel}, //支付渠道
    {path: "/topUpSetting", name: "topUpSetting", component: TopUpSetting}, //充值设置
    {path: "/manualRechar", name: "manualRechar", component: ManualRechar}, // 人工充值
    {path: "/billing", name: "billing", component: Billing}, // 用户流水
    {path: "/giftList", name: "giftList", component: GiftList}, //礼物列表
    {
        path: "/code",
        name: "Code",
        component: () => import('@/pages/ValidateCode/Index')
    },
    {path: "/content_1", name: "Content_1", component: Content_1},
    {path: "/content_2", name: "Content_2", component: Content_2},
    {path: "/content_3", name: "Content_3", component: Content_3},
    {path: "/content_4", name: "Content_4", component: Content_4},
    {path: "/content_5", name: "Content_5", component: Content_5},
    {path: "/content_6", name: "Content_6", component: Content_6},
    {path: "/content_7", name: "Content_7", component: Content_7},
    {path: "/content_8", name: "Content_8", component: Content_8},
    {path: "/content_9", name: "Content_9", component: Content_9},
    {path: "/channel", name: "Channel", component: Channel},
    {path: "/channelMonth", name: "ChannelMonth", component: ChannelMonth},
    {path: "/andVersion", name: "AndVersion", component: AndVersion},
    {path: "/iosVersion", name: "IosVersion", component: IosVersion},
    {path: "/webSet", name: "WebSet", component: WebSet},
    {path: "/greenBroadList", name: "GreenBroadList", component: GreenBroadList},
    {path: "/appSet", name: "AppSet", component: AppSet},
    {path: "/forbid", name: "Forbid", component: Forbid},
    {path: "/authentication", name: "Authentication", component: Authentication},//身份认证管理
    {path: "/realNameList", name: "RealNameList", component: RealNameList},  //实名认证审核
    {path: '*', redirect: "/admin"},
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
