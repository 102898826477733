<template>
  <el-dialog
      :close-on-click-modal="false"
      :visible="value"
      destroy-on-close
      append-to-body
      close-on-press-escape
      custom-class="open-live-dialog"
      title="编辑/新增"
      @close="close"
  >
    <div class="content-body p-b-30">
      <input-with-error
          class=" m-t-15"
          showLabel
          :label="form.ip.label"
          :error-info="errorInfo.ip"
          :row-info.sync="form.ip"
          @validate="validateRow"
          :key="form.ip.updateKey"
      />
      <textarea-with-error
          class=" m-t-15"
          showLabel
          :label="form.remark.label"
          :error-info="errorInfo.remark"
          :row-info.sync="form.remark"
          @validate="validateRow"
          :key="form.remark.updateKey"
      />
      <div class="row-outer flex align-center p-l-30 m-t-15 ">
        <span class="label">
            状态
        </span>
        <div class="content">
          <el-switch
              v-model="status"
              :active-value="1"
              :inactive-value="2"
          >
          </el-switch>
          <span>{{ status === 1 ? '开' : '关'}}</span>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">提交</el-button>
      <el-button @click="cancelEdit">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import InputWithError from '@/components/Form/InputWithError'
import TextareaWithError from '@/components/Form/TextareaWithError'
import {isRequire, isIp } from "@/util/validator";
import {isEmpty, omit} from "@/util/lodashUtil";
import { addWhiteList, editWhiteList, } from '@/api/control'
import {statusCode} from "@/util/statusCode";
export default {
  name: "EditPkg",
  components: {
    InputWithError,
    TextareaWithError
  },
  props: {
      value: {
        type: Boolean,
        default: false
      },
      info: {
        type: Object,
        default: () => ({})
      }
  },
  computed: {
    isAdd() {
      return isEmpty(this.info)
    }
  },
  data() {
    return {
      form: {
        ip: {
          label: 'ip',
          value: null,
          key: 'ip',
          validators: [isRequire('ip'), isIp],
          validateLabel: ['isRequire', 'isIp'],
          updateKey: 'ip-false'
        },
        remark: {
          label: '备注',
          value: null,
          key: 'remark',
          validators: [],
          validateLabel: [],
          updateKey: 'remark-false'
        },
      },
      status: false,
      errorInfo: {
        ip: {},
        remark: {}
      }
    }
  },
  watch: {
    value: {
      handler() {
          if (this.value) {
            this.initForm()
          }
      },
      immediate: true
    }
  },
  methods: {
    initForm() {
      if (!isEmpty(this.info)) {
        // 为空啥都不干
        console.log(this.info.status, 'asdf', this.info)
        this.form.ip.value = this.info.ip
        this.form.remark.value = this.info.remark
        this.status = this.info.status
      }
    },
    async submit() {
      const isValidate = this.validate()
      if (isValidate) {
        // 提交
        const request = this.isAdd ? addWhiteList : editWhiteList
        const params = {
          ip: this.form.ip.value,
          status: this.status,
          remark: this.form.remark.value,
        }
        if (!this.isAdd) {
          params.id = this.info.id
        }
        const { data } = await request(params)
        if (data.code === statusCode.success) {
          this.$message({
            message: '编辑成功',
            type: 'success',
            duration: 2000
          });
          this.close()
        }
      }
    },
    validate () {
      const res = []
      Object.keys(this.form).forEach(key => {
        res.push(this.validateRow(key))
      })
      return res.every(x => x)
    },
    validateRow (key) {
      this.form[key].validators.forEach(validator => {
        const { message, type } = validator(this.form[key].value)
        console.log(message, type, 'asdf', validator, this.form[key].value)
        if (message) {
          this.errorInfo[key][type] = message
        } else {
          this.errorInfo[key] = omit(this.errorInfo[key], type, message)
        }
      })
      this.changeKey(key)
      return isEmpty(this.errorInfo[key])
    },
    changeKey (key) {
      const flag = JSON.parse(this.form[key].updateKey.split('-')[1])
      this.form[key].updateKey = `${key}-${!flag}`
    },
    close() {
      this.reset()
      this.$emit('update:value', false)
      this.$emit('refresh')
    },
    reset() {
      Object.keys(this.form).forEach(key => {
        this.form[key].value = null
      })
      Object.keys(this.errorInfo).forEach(key => {
        this.errorInfo[key]  = { }
      })
      this.status = 2
    },
    cancelEdit() {
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .el-dialog__header {
    background: #0E3F6A;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
  }
  .el-dialog__title {
    color: #fff;
  }

  .content-body {
    .row-inner {
      max-width: 570px;
      padding-left: 30px!important;
      .label {
        width: 80px;
        line-height: 30px;
        margin-right: 45px;

      }
      .input-section {
        background-color: transparent!important;
        border: 1px solid #E2E1E1;
        border-radius: 2px;
      }
      .el-input__icon {
        line-height: 30px;
      }
      .error{
        left: 157px;
      }
    }
  }
}
.row-outer {
  width: 570px;
  .label {
    width: 80px;
    margin-right: 45px;
  }
  .content {
    line-height: 40px;
  }
}

</style>
