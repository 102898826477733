<template>
    <div class="echarts-box">
        <ul class="title-list">
            <div class="title-item" v-for="(item,i) in titleList" :class="{active:current === item.tab}" :key="i"
                 @click="getCurrentData(item)">
                <p>{{ item.title }}</p>
                <p>{{ item.count }}</p>
            </div>
        </ul>
        <div id="echarts"></div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';


    export default {
        name: "",
        data() {
            return {
                current: 'addNew',
                titleList: [
                    {title: '新增用户', count: 125, tab: 'addNew'},
                    {title: 'APP启动次数', count: 2100, tab: 'appAct'},
                    {title: '活跃用户数', count: 1500, tab: 'activeMem'},
                    {title: '平均启动时常', count: 45, tab: 'actTime'},
                    {title: '总注册数', count: 12369, tab: 'allRes'},
                ],
                option: {
                    xAxis: {
                        type: 'category',
                        data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
                            '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: [0, 22, 14, 24, 12, 21, 26, 14, 15, 21, 6, 8, 18, 22, 14, 24, 12, 21, 26, 14, 15, 21, 6, 8],
                            type: 'line'
                        }
                    ]
                }
            }
        },
        mounted() {
            let chartDom = document.getElementById('echarts');
            let myChart = echarts.init(chartDom);
            this.option && myChart.setOption(this.option);
        },
        methods: {
            getCurrentData(item) {
                this.current = item.tab
            }
        }
    }
</script>

<style scoped lang="scss">
    .echarts-box {
        width: 100%;
        height: 100%;

        .title-list {
            padding: 30px 0 0 120px;
            display: flex;
            justify-content: flex-start;
            height: 10%;
            font-size: 14px;

            .title-item {
                width: 120px;
                height: 60px;
                margin-left: 10px;

                & > p:first-child {
                    padding: 5px;
                    font-weight: 500;
                }

                & > p:last-child {
                    padding: 5px;
                    font-weight: 600;
                }
            }

            .title-item {
                z-index: 1;
                position: relative;
                font-size: inherit;
                font-family: inherit;
                color: white;
                padding: 5px 10px;
                outline: none;
                border: none;
                background-color: hsl(236, 32%, 26%);
                overflow: hidden;
                cursor: pointer;
            }

            .title-item::after {
                content: '';
                z-index: -1;
                background-color: hsla(0, 0%, 100%, 0.2);
                position: absolute;
                top: -50%;
                bottom: -50%;
                width: 1.25em;
                transform: translate3d(-525%, 0, 0) rotate(35deg);
            }

            .title-item:hover::after {
                transition: transform 0.45s ease-in-out;
                transform: translate3d(600%, 0, 0) rotate(35deg);
            }

            .active {
                background-color: #545c64;
            }


        }

        #echarts {
            width: 90%;
            height: 80%;
        }
    }

</style>
