<template>
    <div id="top-up-setting">
        <div class="search flex">
            <CommonButton @click.native="handlerAdd" class="btnAdd" title="礼物添加" />
        </div>
        <div class="table">
            <el-table v-loading="loading" :data="tableData" height="100%" style="width:100%" :row-style="{ height: '40px' }"
                :header-row-style="{ height: '40px' }" border>
                <el-table-column label="排序" prop="sort" width="60" align="center" />
                <el-table-column label="礼物ID" prop="id" align="center" />
                <el-table-column label="礼物类型" prop="type" align="center">
                    <template slot-scope="scope">
                        {{ ['-','豪华礼物','普通礼物'][scope.row.type] }}
                    </template>
                </el-table-column>
                <el-table-column label="礼物标识" prop="flag" align="center">
                    <template slot-scope="scope">
                        {{ ['-','普通','热门','贵族','球票'][scope.row.flag] }}
                    </template>
                </el-table-column>
                <el-table-column label="礼物名称" prop="name" align="center" />
                <el-table-column label="礼物金额" prop="amount" align="center" />
                <el-table-column label="购买方式" prop="sales_type" align="center">
                    <template slot-scope="scope">
                        {{ ['-','球币','球票'][scope.row.sales_type] }}
                    </template>
                </el-table-column>
                <el-table-column label="礼物图片" prop="img" align="center">
                    <template slot-scope="scope">
                        <el-image style="max-width: 32px;max-height: 32px"  :src="scope.row.img" :preview-src-list="[scope.row.img]" />
                    </template>
                </el-table-column>
                <el-table-column label="SVGA文件" prop="animation" align="center">
                    <template slot-scope="scope">
                        <CommonSvga :url="scope.row.animation" :width="30" :height="30" :id="scope.row.id" @click.native="showSvga(scope.row)" style="cursor: pointer;" />
                        <!-- {{ scope.row.animation }} -->
                    </template>
                </el-table-column>
                <el-table-column label="播放时间" prop="duration" align="center" />
                <el-table-column label="添加时间" prop="create_time" align="center" width="160" />
                <el-table-column label="最后修改时间" prop="update_time" align="center" width="160" />
                <el-table-column label="操作人" prop="update_by" align="center" />
                <el-table-column label="操作" align="center" width="160">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" @click="handlerEdit(scope.row)">编辑</el-button>
                        <el-button size="small" type="danger" @click="handlerDel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- <div class="pagin">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div> -->
        <el-dialog :close-on-click-modal="false" title="新增/编辑" :visible.sync="dialogFormVisible"
        width="35%" :before-close="cancel">
            <el-form :model="form" :rules="rules" ref="formName">
                <el-form-item label="礼物类型" prop="type" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.type">
                        <el-radio :label="1">豪华礼物</el-radio>
                        <el-radio :label="2">普通礼物</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="礼物标识" prop="flag" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.flag">
                        <el-radio :label="1">普通</el-radio>
                        <el-radio :label="2">热门</el-radio>
                        <!-- <el-radio :label="3">贵族</el-radio> -->
                        <el-radio :label="4">球票</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="礼物名称" prop="name" :label-width="formLabelWidth">
                    <el-input v-model="form.name" />
                </el-form-item>
                <div class="flex">
                    <el-form-item label="礼物金额" prop="amount" :label-width="formLabelWidth" >
                        <el-input v-model="form.amount" />
                    </el-form-item>
                    <el-form-item label="购买方式" prop="sales_type" :label-width="formLabelWidth">
                        <el-select v-model="form.sales_type">
                            <el-option label="球币" :value="1"></el-option>
                            <el-option label="球票" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item label="礼物介绍" prop="remark" :label-width="formLabelWidth">
                    <el-input v-model="form.remark" type="textarea" />
                </el-form-item>
                <el-form-item label="礼物图片" prop="img" :label-width="formLabelWidth">
                    <CommonUpload :icon.sync="form.img" type="avatar" />
                </el-form-item>
                <el-form-item label="SVGA动画" prop="animation" :label-width="formLabelWidth">
                    <CommonUpload :icon.sync="form.animation" type="gift" />
                </el-form-item>
                <el-form-item label="动画时间" prop="duration" :label-width="formLabelWidth">
                    <el-input v-model="form.duration" placeholder="秒：精确小数点后2位" />
                </el-form-item>
                <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
                    <el-input v-model="form.sort" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="formValidate('formName')">提交</el-button>
                <el-button @click="cancel">取消</el-button>
            </div>
        </el-dialog>
        <!-- svga预览 -->
        <div v-if="svgaDialog" class="mask" @click="hideSvgaDialog">
            <CommonSvga :url="row.animation" :width="300" :height="300" :id="row.id + 100" class="bigSvga" />
        </div>
    </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput.vue';
import CommonButton from "@components/common/CommonButton";
import CommonUpload from '@/components/common/CommonUpload';
import Pagination from '@/pages/Layout/Pagination.vue';
import { getGiftList,addGift,editGift,delGift } from '@/api/topUp'
import { statusCode } from '@/util/statusCode'
import CommonSvga from '@/components/common/CommonSvga.vue';
export default {
    components: {
        SearchInput,
        CommonButton,
        CommonUpload,
        Pagination,
        CommonSvga
    },
    data () {
        return {
            order: '',
            dateRange: '',
            type: '',
            loading: false,
            tableData: [],
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            formLabelWidth: '100px',
            form: {},
            dialogFormVisible: false,
            popType: 'add', // add || edit
            rules: {
                type: [
                    {required: true, message: '请输选择礼物类型', trigger: 'change'}
                ],
                flag: [
                    {required: true, message: '请选择礼物标识', trigger: 'change'}
                ],
                name: [
                    {required: true, message: '请输入礼物名称', trigger: 'blur'}
                ],
                amount: [
                    {required: true, message: '请输入礼物金额', trigger: 'blur'}
                ],
                sales_type: [
                    {required: true, message: '请选择购买方式', trigger: 'change'}
                ],
                remark: [
                    {required: true, message: '请输入礼物介绍', trigger: 'blur'}
                ],
                duration: [
                    {required: true, message: '请输入动画时间', trigger: 'blur'}
                ],
                sort: [
                    {required: true, message: '请输入排序', trigger: 'blur'}
                ],
                img: [
                    {required: true, message: '请上传礼物图片', trigger: 'change'}
                ],
                animation: [
                    {required: true, message: '请上传礼物SVGA动画', trigger: 'change'}
                ]
            },
            row: {},
            svgaDialog: false
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        async init () {
            this.loading = true
            try {
                const { data } = await getGiftList()
                console.log(data)
                if (data.code === statusCode.success) {
                    if (data.data && data.data.length) {
                        this.tableData = data.data
                    } else {
                        this.tableData = []
                    }
                } else {
                    console.log(data.msg)
                }
                this.loading = false
            } catch (e) {
                console.error(e)
            }
        },
        // 新增弹窗
        handlerAdd () {
            this.popType = 'add'
            this.dialogFormVisible = true
        },
        // 编辑
        handlerEdit (row) {
            this.dialogFormVisible = true
            this.popType = 'edit'
            this.$nextTick(() => {
                this.form = {
                    id: row.id,
                    type: row.type,
                    flag: row.flag,
                    name: row.name,
                    amount: row.amount,
                    remark: row.remark,
                    img: row.img,
                    animation: row.animation,
                    duration: row.duration,
                    sort: row.sort,
                    sales_type: row.sales_type
                }
            })
        },
        // 表单验证
        formValidate (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submit()
                }
            })
        },
        // 提交
        async submit() {
            const jsonData = {
                type: this.form.type,
                flag: this.form.flag,
                name: this.form.name,
                amount: this.form.amount,
                remark: this.form.remark,
                img: this.form.img,
                animation: this.form.animation,
                duration: this.form.duration,
                sort: this.form.sort,
                sales_type: this.form.sales_type
            }
            if (this.popType === 'edit') jsonData.id = this.form.id
            const request = this.popType === 'add' ? addGift : editGift
            let message = '操作成功', type = 'success'
            const { data } = await request(jsonData)
            if (data.code === statusCode.success) {
                this.dialogFormVisible = false
                this.init()
            } else {
                message = data.msg, type = 'warning'
            }
            this.cancel()
            this.$message({
                message,
                type
            })

        },
        handlerDel (row) {
            this.$confirm('确认删除吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data } = await delGift({id:row.id})
                if (data.code === statusCode.success) {
                this.$message({
                    message: data.data,
                    type: 'success',
                    duration: 2000
                });
                }
                this.init()
            })
        },
        cancel() {
            this.$refs['formName'].resetFields()
            this.dialogFormVisible = false
            this.form = {}
        },
        // 预览 svga
        showSvga (row){
            this.row = row
            this.svgaDialog = true
        },
        hideSvgaDialog () {
            this.svgaDialog =false
        }
    }
}
</script>

<style lang="scss" scoped>
#top-up-setting {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    .search {
        margin-bottom: 20px;

        .date {
            margin-right: 15px;
        }

        .addBtn {
            margin-left: 15px;
        }
    }

    .table {
        height: calc(100% - 100px);
    }

    .pagin {
        margin-top: 10px;
    }
}
.mask{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    background: rgba(0,0,0,0.3);
}
.bigSvga{
    position: absolute;
    top: calc(50% - 150px);
    left: calc(50% - 150px);
    margin: auto;
    overflow: hidden;
}

// 表格
/deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
}


/deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}


//弹窗
/deep/ .el-dialog {
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
/deep/ .el-form-item{
    &.w30{
        .el-form-item__content{
            width: 20%;
        }
    }
}
</style>