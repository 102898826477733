<template>
    <div class="report">
            <div class="echarts-box">
                <ul class="title-list">
                    <div class="title-item" v-for="(item,i) in titleList" :class="{active:current === item.tab}" :key="i"
                         @click="getCurrentData(item)">
                        <p>{{ item.title }}</p>
                        <p>{{ item.count }}</p>
                    </div>
                </ul>
            </div>
            <div id="echarts-zhe"></div>
            <div id="echarts-device"></div>
            <div id="echarts-register-channel"></div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';

    export default {
        name: "",
        data() {
            return {
                option1: {
                    title: {
                        text: '设备终端',
                        subtext: '已注册用户使用设备类型占比'
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Android', 'IOS', 'Wed', 'H5']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: [40, 20, 10, 30],
                            type: 'bar',
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                            }
                        }
                    ]
                },
                option2: {
                    title: {
                        text: '总注册量',
                        subtext: '100',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left'
                    },
                    series: [
                        {
                            name: 'Access From',
                            type: 'pie',
                            radius: '50%',
                            data: [
                                {value: 1048, name: 'wx'},
                                {value: 735, name: 'qq'},
                                {value: 580, name: 'iphone'},
                                {value: 484, name: 'facebook'},
                                {value: 300, name: 'google'}
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                },
                current: 'addNew',
                titleList: [
                    {title: '新增用户', count: 125, tab: 'addNew'},
                    {title: 'APP启动次数', count: 2100, tab: 'appAct'},
                    {title: '活跃用户数', count: 1500, tab: 'activeMem'},
                    {title: '平均启动时常', count: 45, tab: 'actTime'},
                    {title: '总注册数', count: 12369, tab: 'allRes'},
                ],
                option3: {
                    xAxis: {
                        type: 'category',
                        data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
                            '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: [0, 22, 14, 24, 12, 21, 26, 14, 15, 21, 6, 8, 18, 22, 14, 24, 12, 21, 26, 14, 15, 21, 6, 8],
                            type: 'line'
                        }
                    ]
                }
            }
        },
        mounted() {
            this.getChartDomDevice()
            this.getChartDomChannel()
            this.getChartDom()
            // this.option && myChart2.setOption(this.option);
        },
        methods: {
            getChartDomDevice() {
                let chartDom1 = document.getElementById('echarts-device');
                let myChart1 = echarts.init(chartDom1);
                this.option1 && myChart1.setOption(this.option1);
            },
            getChartDomChannel() {
                let chartDom2 = document.getElementById('echarts-register-channel');
                let myChart2 = echarts.init(chartDom2);
                this.option2 && myChart2.setOption(this.option2);
            },
            getChartDom() {
                let chartDom = document.getElementById('echarts-zhe');
                let myChart3 = echarts.init(chartDom);
                this.option3 && myChart3.setOption(this.option3);
            },
            getCurrentData(item) {
                this.current = item.tab
            }
        }
    }
</script>

<style scoped lang="scss">
    .report {
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        background-color: #f7f7f7;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        overflow-x: hidden;
        overflow-y: scroll;

        #echarts-zhe {
            width: 100%;
            height: 400px;
            background-color: #fff;
            border-radius: 10px;
        }

        #echarts-device {
            margin-top: 10px;
            width: calc(50% - 5px);
            margin-right: 10px;
            height: 400px;
            border-radius: 15px;
            background-color: #fff;
        }

        #echarts-register-channel {
            margin-top: 10px;
            width: calc(50% - 5px);
            height: 400px;
            border-radius: 15px;
            background-color: #fff;
        }

        .echarts-box {
            width: 100%;
        }
    }

    .title-list {
        display: flex;
        justify-content: flex-start;
        height: 10%;
        font-size: 14px;
        margin-bottom: 10px;

        .title-item {
            width: 120px;
            height: 60px;
            margin-right: 10px;

            & > p:first-child {
                padding: 5px;
                font-weight: 500;
            }

            & > p:last-child {
                padding: 5px;
                font-weight: 600;
            }
        }

        .title-item {
            z-index: 1;
            position: relative;
            font-size: inherit;
            font-family: inherit;
            color: white;
            padding: 5px 10px;
            outline: none;
            border: none;
            background-color: hsl(236, 32%, 26%);
            overflow: hidden;
            cursor: pointer;
        }

        .title-item::after {
            content: '';
            z-index: -1;
            background-color: hsla(0, 0%, 100%, 0.2);
            position: absolute;
            top: -50%;
            bottom: -50%;
            width: 1.25em;
            transform: translate3d(-525%, 0, 0) rotate(35deg);
        }

        .title-item:hover::after {
            transition: transform 0.45s ease-in-out;
            transform: translate3d(600%, 0, 0) rotate(35deg);
        }

        .active {
            background-color: #545c64;
        }
    }
</style>
