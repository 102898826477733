import request from "../request"
import url from "./url"

export function topUpConfig () { // 充值设置列表
    return request({
        method: 'get',
        url: url.topUpConfig,
    })
}

export function addTopUpConfig (data) { // 充值设置新增
    return request({
        method: 'post',
        url: url.addTopUpConfig,
        data
    })
}

export function editTopUpConfig (data) { // 充值设置编辑
    return request({
        method: 'post',
        url: url.editTopUpConfig,
        data
    })
}

export function delTopUpConfig (data) { // 充值设置删除
    return request({
        method: 'post',
        url: url.delTopUpConfig,
        data
    })
}

export function getRatio () { // 获取球币比例
    return request({
        method: 'get',
        url: url.getRatio,
    })
}

export function setRatio (data) { // 设置球币比例
    return request({
        method: 'post',
        url: url.setRatio,
        data
    })
}

export function getGiftList (params) { // 礼物列表
    return request({
        method: 'post',
        url: url.getGiftList,
        params
    })
}

export function addGift (data) { // 添加礼物
    return request({
        method: 'post',
        url: url.addGift,
        data
    })
}

export function editGift (data) { // 编辑礼物
    return request({
        method: 'post',
        url: url.editGift,
        data
    })
}

export function delGift (data) { // 删除礼物
    return request({
        method: 'post',
        url: url.delGift,
        data
    })
}

export function getPayRecordList (params) { //充值记录
    return request({
        method: 'get',
        url: url.getPayRecordList,
        params
    })
}

export function getGiftsConsumeList (params) { // 礼物消费记录
    return request({
        method: 'get',
        url: url.getGiftsConsumeList,
        params
    })
}

export function getSifangOrder (params) { // 四方订单
    return request({
        method: 'get',
        url: url.getSifangOrder,
        params
    })
}

export function getPayChannelList (params) { // 支付渠道列表
    return request({
        method: 'get',
        url: url.getPayChannelList,
        params
    })
}

export function addPayChannel (data) { // 新增渠道
    return request({
        method: 'post',
        url: url.addPayChannel,
        data
    })
}

export function editPayChannel (data) { // 编辑渠道
    return request({
        method: 'post',
        url: url.editPayChannel,
        data
    })
}

export function delPayChannel (data) { // 删除渠道
    return request({
        method: 'post',
        url: url.delPayChannel,
        data
    })
}

export function getWithdraw (params) { // 取款列表
    return request({
        method: 'get',
        url: url.getWithdraw,
        params
    })
}

export function editWithdraw (data) { // 取款列表
    return request({
        method: 'post',
        url: url.editWithdraw,
        data
    })
}

export function getManualList (params) { // 上下分列表
    return request({
        method: 'get',
        url: url.getManualList,
        params
    })
}

export function addManualList (data) { // 添加上下分
    return request({
        method: 'post',
        url: url.addManualList,
        data
    })
}

export function getMemberList (params) { // 上下分会员列表
    return request({
        method: 'get',
        url: url.getMemberList,
        params
    })
}

export function getBillingList (params) { // 流水列表
    return request({
        method: 'get',
        url: url.getBillingList,
        params
    })
}
