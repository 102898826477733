<template>
<div class="layout">
  <div class="w-100 h-100 flex" v-if="token">

    <div class="nav-bar">
        <NavBar />
    </div>
    <div class="body h-100">
      <Header />
      <div class="content" :class="{
        'has-padding': hasPadding
      }">
        <slot />
      </div>
    </div>
  </div>
  <slot v-else />
</div>
</template>

<script>
import Header from "@/pages/Layout/Header";
import NavBar from "@/pages/Layout/NavBar";
export default {
name: "BasicLayout",
  components: {
    Header,
    NavBar
  },
  data() {
    return {
      routerName: ''
    }
  },
  watch: {
    '$route': {
      handler() {
        this.routerName = this.$route.name
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    token() {
      console.log(this.$store.state, '22222')
      return this.$store.state.token
    },
    hasPadding() {
      console.log(this.routerName, 'routerName')
      return this.routerName !== 'Home'
    }
  },
}
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .body {
    width: calc(100% - 210px);
    .nav-bar {
      height: 100%;
    }
    .nav-bar {
      width: 210px;
    }
    .content {
      width: 100%;
      height: calc(100% - 60px);
      box-sizing: border-box;
      &.has-padding {
        padding: 20px 20px 0;
      }
    }
  }
}

</style>
