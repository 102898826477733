<template>
    <div class="page-box">
        <div class="filter-box" style="display: flex">
          <SearchInput
              :search.sync="search"
              placeholder="请输入会员ID/手机号/昵称"
              @search="searchData"
          />
        </div>
      <div class="table">
        <el-table :data="tableData"
                  :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                  height="100%"
                  style="width: 100%">
          <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
          <el-table-column align="center" prop="member_id" label="直播间ID"></el-table-column>
          <el-table-column align="center" prop="title" label="直播间标题"></el-table-column>
          <el-table-column align="center" prop="anchor_name" label="主播昵称"></el-table-column>
          <el-table-column align="center" prop="leagueChsShort" label="联赛名称"></el-table-column>
          <!--            <el-table-column prop="leagueId" label="联赛ID "></el-table-column>-->
          <!--            <el-table-column prop="matchId" label="比赛ID "></el-table-column>-->
          <el-table-column align="center" prop="homeChs" label="主队名"></el-table-column>
          <el-table-column align="center" prop="awayChs" label="客队名"></el-table-column>
          <el-table-column align="center" prop="matchTime" label="比赛时间"></el-table-column>
          <el-table-column align="center" prop="create_time" label="创建时间"></el-table-column>
<!--          <el-table-column align="center" prop="recommend" label="是否推荐"  width="80">-->
<!--            <template slot-scope="scope">-->
<!--              <el-switch-->
<!--                  disabled-->
<!--                  active-text=""-->
<!--                  inactive-text=""-->
<!--                  active-color="green"-->
<!--                  inactive-color="#ccc"-->
<!--                  @change="changeSwitch(scope.row,1)"-->
<!--                  v-model="scope.row.is_position == 2 ">-->
<!--              </el-switch>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column align="center" prop="leagueType" label="联赛类型">
            <template slot-scope="scope">
              <div>{{ leagueTypeList[scope.row.leagueType] }}</div>
            </template>
          </el-table-column>
<!--          <el-table-column align="center" prop="leagueType" label="状态">-->
<!--            <template slot-scope="scope">-->
<!--              <div>{{ preordainStatus[scope.row.preordain_status] }}</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column align="center" prop="live_cover" label="直播封面">
            <template slot-scope="scope">
              <el-image
                  style="max-width: 32px;max-height: 32px"
                  :src="scope.row.live_cover"
                  :preview-src-list="[scope.row.live_cover,scope.row.live_cover]">
              </el-image>
            </template>
          </el-table-column>
          <!--            <el-table-column prop="status" label="状态" width="150">-->
          <!--                <template slot-scope="scope">-->
          <!--                    <el-switch-->
          <!--                        active-text="正常"-->
          <!--                        inactive-text="禁播"-->
          <!--                        active-color="green"-->
          <!--                        inactive-color="#ccc"-->
          <!--                        @change="changeSwitch(scope.row,2)"-->
          <!--                        v-model="scope.row.status == 1 ">-->
          <!--                    </el-switch>-->
          <!--                </template>-->
          <!--            </el-table-column>-->
          <el-table-column align="center" label="操作" width="150">
            <template slot-scope="scope">
                <div :key="scope.row.id">
                  <el-button
                      v-if="scope.row.preordain_status === 1" type="primary" @click="cancel(scope.row.id)">取消</el-button>
                  <el-button  v-else type="info" disabled>{{ preordainStatus[scope.row.preordain_status]  }}</el-button>

                </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination block">
        <pagination
            :current-page.sync="pagination.currentPage"
            :page-size.sync="pagination.pageSize"
            :total="pagination.total"
            @loadData="init"
        />
      </div>
    </div>
</template>

<script>
    import {editLive, forbidLive, getLiveOrder, cancelReverse} from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";
    export default {
        components: {SearchInput, Pagination},
        data() {
            return {
                search: '',
                total: 0,
                loading: true,
                tableData: [],
                isEditTxt: "详情/编辑",
                currentId: '',
                dialog: false,
                formItem: {
                    room_title: '',
                    is_position: '',
                    sort: '',
                    status: '',
                },
                leagueTypeList: {
                    1: '足球', 2: '篮球', 3: '电竞', 4: '其他',
                },
                preordainStatus: {
                  1: '正常', 2: '已取消', 3: '已开播', 4: '已过期',
                },
                // searchCon: [
                //     {key: "member_id", val: '', pla: '直播ID/主播ID', label: '直播ID/主播ID', labelFlag: false},
                // ],
                pagination: {
                  currentPage: 1,
                  pageSize: 20,
                  total: 0
                },
                openLiveDialog: false,
            }
        },
        created() {
            this.init()
        },
        computed: {
          apiParams() {
            return {
              pageSize: this.pagination.pageSize,
              pageNum: this.pagination.currentPage,
              member_id: this.search,
            }
          }
        },
        methods: {
            async init() {
                this.loading = true
                try {
                    let {data} = await getLiveOrder(this.apiParams)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        this.tableData = JSON.parse(JSON.stringify(data.rows))
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            setSellStyle({row, column, rowIndex, columnIndex}) {
                if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
                if (columnIndex == 7) return "borderRadius: 0  10px 10px 0"
            },
            handleEdit(index, row) {
                this.dialog = true
                this.status = row.status
                this.currentId = this.tableData[index].id
                for (let k in row) {
                    for (let key in this.formItem) {
                        if (k === key) {
                            this.formItem[key] = row[k]
                        }
                    }
                }
                this.formItem.room_id = row.room_id
            },
            async onSubmit() { //编辑提交
                let msg = ''
                let type = 'success'
                let {data} = await editLive(this.formItem)
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.addDialog = false
                    this.init()
                    this.dialog = false
                } else {
                    msg = data.msg
                    type = 'warning'
                }
                this.$message({
                    message: msg,
                    type,
                    duration: 2000
                });
            },
            async changeSwitch(item, num) {  // 1推荐  2直播间状态
                if (num === 1) {  //设置推荐
                    let msg = ''
                    let type = 'success'
                    let {data} = await editLive({room_id: item.room_id, is_position: item.is_position == 1 ? 2 : 1})
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.addDialog = false
                        this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                } else { // 禁播
                    let msg = ''
                    let type = 'success'
                    let {data} = await forbidLive({room_id: item.room_id, status: item.status == 1 ? 2 : 1})
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.addDialog = false
                        this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            resetForm() {
                for (let key in this.formItem) {
                    if (typeof this.formItem[key] !== "boolean") this.formItem[key] = ''
                }
            },
            async cancel(id) {
                await cancelReverse({id: id}).then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: res.data.msg,
                            type:"success"
                        })
                        this.pagination.pageSize = 20
                        this.pagination.currentPage = 1
                        this.init()
                    } else {
                        this.$message(res.data.msg)
                    }
                })
            },
            searchData() {
              this.pagination.pageSize = 20
              this.pagination.currentPage = 1
              this.init()
            },

        }
    }
</script>

<style scoped lang="scss">
.page-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .filter-box {
    margin-bottom: 20px;
  }
  .pagination {
    margin-top: 10px;
  }
  .table {
    height: calc(100% - 100px);
  }
}
    .editBtn {
        color: #1e82d2;
    }

    .popImg {
        width: 50%;

        img {
            width: 100%;
        }
    }

    .popImgSee {
        width: 100%;
    }

    /deep/ .el-table--border .el-table__cell,
    .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
        //border: 0;
    }

    /deep/ .el-table tr {
        cellspacing: 10px;
        border: 1px solid #cccccc;
        padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        //border-spacing: 0 10px;
        background-color: #f6f6f6;
    }

    /deep/ .el-dialog {
        //弹窗
        //width: 400px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-dialog__header {
        background-color: #189e90;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

</style>
