import request from "../request"
import url from "./url"

export function startLive ({
    memberId,
placard, matchId, title, liveCover, category, videoUrl, wechat, qq, qrcode
}) {
    return request({
        method: 'post',
        url: url.startLive,
        data: {
            live_cover: liveCover,
            room_title: title,
            type: category, // 分类
            match_id: matchId,
            placard,
            video_url: videoUrl,
            member_id: memberId,
            wechat,
            qq,
            qrcode
        }
    })
}

export function closeLive () {
    return request({
        method: 'post',
        url: url.closeLive
    })
}

export function getOBSAddress () {
    return request({
        method: 'post',
        url: url.getOBSAddress
    })
}


export function getMatchesByDay ({ day, leagueId, leagueType, schedule = null }) {
    return request({
        method: 'post',
        url: url.getMatchesByDay,
        data: {
            day,
            leagueId,
            leagueType,
            schedule
        }
    })
}
// 联赛列表
export function getLeagues (type, day, playing, hierarchy) {
    return request({
        method: 'post',
        url: url.getLeagues,
        data: { // 联赛类型 1足球 2篮球 3电竞 4其他
            league_type: type,
            day,
            playing,
            hierarchy
        }
    })
}

// 上传
export function uploadImage (formData) {
    return request({
        method: 'post',
        url: url.uploadImage,
        data: formData
    })
}

export function getOpenAnchorList (data) { // 主播开播-》主播列表
    return request({
        method: 'post',
        url: url.getOpenAnchorList,
        data
    })
}


// 获取直播间统计
export function getLiveRoomStatics() {
    return request({
        method: 'get',
        url: url.getLiveRoomStatics
    })
}

// 直播记录统计
export function getLivingRecordStatics({ startDate, endDate}) {
    return request({
        method: 'get',
        url: url.getLivingRecordStatics,
        params: {
            start_date: startDate,
            end_date: endDate
        }
    })
}
