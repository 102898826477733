<template>
    <div class="page-box" v-if="userInfo && userInfo.account === 'admin'" style="display: flex;flex-wrap: wrap">
        <div class="box">
            <div  class="box-item">
                <div>
                    <h4>绿包模块总开关</h4>
                    <el-radio @change="handleEdit(13,2)" v-model="switchGreen" :label="1">开启</el-radio>
                    <el-radio @change="handleEdit(13,1)"  v-model="switchGreen" :label="2">关闭</el-radio>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        当前账户暂无权限查看
    </div>
</template>

<script>
    import {chatroomMasterSwitch, checkMode} from "@/api/control";
    import {statusCode} from "@/util/statusCode";

    export default {
        name: 'greenBroadList',
        props: {
            adminInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                IOS: '',
                Android: '',
                huawei: '',
                vivo: '',
                yingyongbao: '',
                youqiubiying: '',
                kuaishou: '',
                samsung: '',
                baidu: '',
                miqiu: '',
                caiqiu: '',
                hbxiaomi: '',
                switchGreen: '', //绿包总开关
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo
            }
        },
        created() {
            this.init()
            this.handleEditGreen()
        },
        methods: {
            async init(json) {
                this.loading = true
                try {
                    let {data} = await checkMode(json)
                    if (data.code === statusCode.success) {
                        this.IOS = data.data.ios
                        this.Android = data.data.android
                        this.huawei = data.data.huawei
                        this.vivo = data.data.vivo
                        this.yingyongbao = data.data.yingyongbao
                        this.youqiubiying = data.data.youqiubiying
                        this.kuaishou = data.data.kuaishou
                        this.samsung = data.data.samsung
                        this.baidu = data.data.baidu
                        this.miqiu = data.data.miqiu
                        this.caiqiu = data.data.caiqiu
                        this.hbxiaomi = data.data.hbxiaomi
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            async handleEdit(type, val) {
                // console.log(this.Android)
                // console.log(val === 2)
                if (type === 1) {
                    this.Android = val === 1 ? 2 : 1
                } else if (type === 2) {
                    this.IOS = val === 1 ? 2 : 1
                } else if (type === 3) {
                    this.huawei = val === 1 ? 2 : 1
                } else if (type === 4) {
                    this.vivo = val === 1 ? 2 : 1
                } else if (type === 5) {
                    this.yingyongbao = val === 1 ? 2 : 1
                } else if (type === 6) {
                    this.youqiubiying = val === 1 ? 2 : 1
                } else if (type === 7) {
                    this.kuaishou = val === 1 ? 2 : 1
                } else if (type === 8) {
                    this.samsung = val === 1 ? 2 : 1
                } else if (type === 9) {
                    this.baidu = val === 1 ? 2 : 1
                } else if (type === 10) {
                    this.miqiu = val === 1 ? 2 : 1
                } else if (type === 11) {
                    this.caiqiu = val === 1 ? 2 : 1
                } else if (type === 12) {
                    this.hbxiaomi = val === 1 ? 2 : 1
                }
                console.log(this.Android)
                let {data} = await checkMode({
                    IOS: this.IOS,
                    Android: this.Android,
                    huawei: this.huawei,
                    vivo: this.vivo,
                    yingyongbao: this.yingyongbao,
                    youqiubiying: this.youqiubiying,
                    kuaishou: this.kuaishou,
                    samsung: this.samsung,
                    baidu: this.baidu,
                    miqiu: this.miqiu,
                    caiqiu: this.caiqiu,
                    hbxiaomi: this.hbxiaomi,
                })
                if (data.code == statusCode.success) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    await this.init()
                }
            },
            // async initSwitch() {
            //     let {data} = await chatroomMasterSwitch({switch:1})
            //     if (data.code == statusCode.success) {
            //         console.log(1234,data)
            //     }
            // },
            async handleEditGreen(val) {
                let {data} = await chatroomMasterSwitch({switch: val})
                if (data.code == statusCode.success) {
                    if (!val) {
                        this.switchGreen = data.data.switch
                    } else {
                        this.switchGreen = this.switchGreen == 1 ? 2 : 1
                    }
                    if (val) this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                }
            },
        }
    }
</script>

<style scoped lang="scss">
.page-box{
  width: 100%;
}
    .box {
      width: 50%;
        display: flex;
        flex-wrap: wrap;

        .box-item {
            width: 100%;
            padding: 10px;
            border: 1px solid #eee;
            margin: 10px;
            border-radius: 5px;

            h4 {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;
            }
        }
    }

    .box-item {
        width: 20%;
    }

    .editBtn {
        color: #1e82d2;
    }

    .popImg {
        width: 50%;

        img {
            width: 100%;
        }
    }

    .popImgSee {
        width: 100%;
    }

    /deep/ .el-table--border .el-table__cell,
    .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
        border: 0;
    }

    /deep/ .el-table tr {
        cellspacing: 10px;
        border: 1px solid #cccccc;
        padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        border-spacing: 0 10px;
        background-color: #f6f6f6;
    }

    /deep/ .el-dialog {
        //弹窗
        width: 400px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-dialog__header {
        background-color: #189e90;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

    /deep/ .el-input__inner {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #ccc;
    }
</style>
