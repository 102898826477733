<template>
    <div id="pageContaine" class="page-box">
        <div class="search flex">
            <search-input :search.sync="author" placeholder="昵称" @search="searchData" />
            <search-input :search.sync="title" placeholder="文章标题" @search="searchData" />
            <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" class="date">
            </el-date-picker>
            <div>
                <el-select v-model="type" placeholder="请选择资讯类型" @change="init">
                    <el-option :label="item" :value="item" v-for="item,index in informationTypeData" :key="index" />
                </el-select>
            </div>
            <common-button @click.native="handleAdd" title="资讯新增" class="addBtn" />
        </div>
        <div class="table">
            <el-table v-loading="loading" :data="tableData" height="100%" style="width:100%" :row-style="{ height: '40px' }"
                :header-row-style="{ height: '40px' }" border>
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column label="作者昵称" prop="author" align="center" />
                <el-table-column label="资讯标题" prop="title" align="center" />
                <el-table-column label="封面" prop="cover" align="center" width="80">
                    <template slot-scope="scope">
                        <el-image style="max-width: 32px;max-height: 32px" :src="scope.row.cover"
                            :preview-src-list="[scope.row.cover, scope.row.cover]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="排序" prop="sort" align="center" width="80" />
                <el-table-column label="资讯类型" prop="type" align="center" width="80" />
                <el-table-column label="跳转方式" prop="jump_type" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.jump_type === 1 ? "内跳" : "外跳" }}</span>
                    </template>
                </el-table-column>
                <table-one-empty label="链接地址" prop="jump_url" align="center" min-width="200" />
                <el-table-column label="发布时间" prop="create_time" align="center" width="150" />
                <el-table-column label="最后编辑时间" prop="update_time" align="center" width="150" />
                <el-table-column label="状态" prop="status" align="center">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2" @change="switchStatus(scope.row)" />
                    </template>
                </el-table-column>
                <el-table-column label="操作人" prop="update_by" align="center" />
                <el-table-column label="操作" prop="" align="center" width="160">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button size="small" type="danger" @click="handleDel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagin">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div>
        <!-- <form-dialog :value.sync="dialogFormVisible" :popupType="popupType" :info="info" @refresh="init" :informationTypeData="informationTypeData" /> -->
        <el-dialog :close-on-click-modal="false" title="新增/编辑" :visible.sync="dialogFormVisible"
        width="50%" :before-close="handleCancel">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="昵称" prop="author" :label-width="formLabelWidth">
                    <el-input v-model="form.author" />
                </el-form-item>
                <el-form-item label="资讯标题" prop="title" :label-width="formLabelWidth">
                    <el-input v-model="form.title" />
                </el-form-item>
                <el-form-item label="封面" :label-width="formLabelWidth">
                    <CommonUpload :icon.sync="form.cover" />
                </el-form-item>
                <el-form-item label="排序" prop="amount" :label-width="formLabelWidth">
                    <el-input v-model="form.sort" />
                </el-form-item>
                <el-form-item label="资讯类型" prop="type" :label-width="formLabelWidth">
                    <el-select v-model="form.type" placeholder="请选择" style="width: 60%;">
                        <el-option
                        v-for="item in typeDataFilter"
                        :key="item"
                        :label="item"
                        :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="跳转方式" prop="jump_type" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.jump_type">
                        <el-radio :label="1">内跳</el-radio>
                        <el-radio :label="2">外跳</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="链接地址" prop="amount" :label-width="formLabelWidth" v-if="form.jump_type === 2">
                    <el-input v-model="form.jump_url" />
                </el-form-item>
                <el-form-item label="正文内容" prop="content" :label-width="formLabelWidth">
                    <editor-bar
                        class="editor"
                        style="min-height: 300px;width:750px;background-color: #f6f6f6;"
                        v-model="form.content"
                        :isNews="true">
                    </editor-bar>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="formValidated('form')">提交</el-button>
                <el-button @click="handleCancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput.vue';
import CommonButton from '@/components/common/CommonButton.vue';
import TableOneEmpty from '@/components/common/tableOneEmpty.vue';
import Pagination from '@/pages/Layout/Pagination.vue';
import FormDialog from '@/pages/information/formDialog.vue'
import { informationList,editInformation,informationType,delInformation,addInformation } from '@/api/control'
import dayjs from 'dayjs'
import { statusCode } from '@/util/statusCode'
import {debounce} from "@/util/lodashUtil";
import EditorBar from "@components/common/editoritem";
import CommonUpload from '@/components/common/CommonUpload'
export default {
    components: {
        SearchInput,
        CommonButton,
        TableOneEmpty,
        Pagination,
        FormDialog,
        EditorBar,
        CommonUpload
    },
    data() {
        return {
            loading: false,
            author: '',
            title: '',
            dateRange: '',
            type: '',
            tableData: [],
            total: 0,
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            dialogFormVisible: false,
            popupType:'add',
            info:{},
            informationTypeData:[],
            form: {},
            formLabelWidth: '100px',
            options:[],
            rules: {
                author: [
                    {required: true, message: '昵称不能为空', trigger: 'blur'}
                ],
                title: [
                    {required: true, message: '资讯标题不能为空', trigger: 'blur'}
                ],
                // cover: [
                //     {required: true, message: '封面不能为空', trigger: 'change'}
                // ],
                type: [
                    {required: true, message: '资讯类型不能为空', trigger: 'change'}
                ],
                jump_type: [
                    {required: true, message: '跳转方式不能为空', trigger: 'change'}
                ],
                content: [
                    {required: true, message: '正文不能为空', trigger: 'change'}
                ]
            }

        }
    },
    computed: {
        apiParams() {
            return {
                pageSize: this.pagination.pageSize,
                pageNum: this.pagination.currentPage,
                start_date: this.dateRange && this.dateRange[0] ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : '',
                end_date: this.dateRange && this.dateRange[1] ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : '',
                title: this.title,
                author: this.author,
                type: this.type
            }
        },
        typeDataFilter () {
            return this.informationTypeData.filter((item) => item !== '全部')
        }
    },
    watch: {
        dateRange(newVal,oldVal) {
            this.init()
        }
    },
    mounted() {
        this.init()
        this.getInformationType()
    },
    methods: {
        async init() {
            this.loading = true
            try {
                let { data } = await informationList(this.apiParams)
                if (data.code === statusCode.success) {
                    this.total = data.total
                    this.pagination.total = data.total
                    if (data.rows && data.rows.length) {
                        this.tableData = JSON.parse(JSON.stringify(data.rows))
                    } else {
                        this.tableData = []
                    }
                    this.loading = false
                }
            } catch (e) {
                console.log(e)
            }
        },
        searchData() {
            this.pagination.pageSize = 20
            this.pagination.currentPage = 1
            this.init()
         },
        handleAdd() {
            this.popupType = 'add'
            this.dialogFormVisible = true
            this.form.jump_type = 1
            this.form.author = '野樱'
        },
        handleEdit(row) {
            console.log(row)
            this.form = {
                id: row.id,
                title: row.title,
                author: row.author,
                content: row.content,
                cover: row.cover,
                type: row.type,
                sort: row.sort,
                jump_url: row.jump_url,
                jump_type: row.jump_type,
                status: row.status
            }
            this.popupType = 'edit'
            this.dialogFormVisible = true
        },
        formValidated () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.submit()
                }
            })
        },
        async submit() {
            console.log(this.form)
            let jsonData = {
                title: this.form.title,
                author: this.form.author,
                content: this.form.content,
                cover: this.form.cover,
                type: this.form.type,
                sort: this.form.sort,
                jump_url: this.form.jump_url,
                jump_type: this.form.jump_type,
                status: 1   // 1开启  2关闭,添加时默认关闭
            }
            let request, message = '操作成功', type = 'success'
            if (this.popupType === 'add') {
                request = addInformation
            } else {
                request = editInformation
                jsonData.id = this.form.id
                jsonData.status = this.form.status
            }
            const {data} = await request(jsonData)
            if (data.code === statusCode.success) {
                this.form = {}
                this.dialogFormVisible = false
                this.init()
            } else {
                message = data.msg, type = 'warning'
            }
            this.$message({message, type})
        },
        handleCancel () {
            this.form = {}
            this.$refs['form'].resetFields()
            this.dialogFormVisible = false
        },
        handleDel(row) {
            this.$confirm('确认删除吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data } = await delInformation({id:row.id})
                if (data.code === statusCode.success) {
                this.$message({
                    message: data.data,
                    type: 'success',
                    duration: 2000
                });
                }
                this.init()
            })
        },
        // 状态修改-》防抖处理
        switchStatus : debounce(async function (row) {
            let dataJson = {
                title: row.title,
                author: row.author,
                content: row.content,
                cover: row.cover,
                type: row.type,
                sort: row.sort,
                jump_url: row.jump_url,
                jump_type: row.jump_type,
                status: row.status,
                id: row.id
            }
            const {data} = await editInformation(dataJson)
            if (data.code === statusCode.success) {
                this.$message({
                    message: '成功',
                    type: 'success',
                    duration: 2000
                })
            }
        },300),
        // 资讯类型
        async getInformationType() {
            console.log('typtype')
            let {data} = await informationType()
            if (data.code === statusCode.success) {
                this.informationTypeData = data.data
            } else {
                this.$message({
                    message: data.msg,
                    type: 'warning',
                    duration: 20000
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#pageContaine {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .search {
        margin-bottom: 20px;

        .date {
            margin-right: 15px;
        }

        .addBtn {
            margin-left: 15px;
        }
    }

    .table {
        height: calc(100% - 100px);
    }

    .pagin {
        margin-top: 10px;
    }
}

// 表格
/deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
}


/deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}


// /deep/ .el-table th.el-table__cell > .cell {
//     color: #6d6d6e;
//     font-size: 14px;
// }
//弹窗
.el-form{
    height: 500px;
    overflow: auto;
}
/deep/ .el-dialog {
//   width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
/deep/ .el-input__inner{
    width: 60%;
}
/deep/ .el-input--suffix{
    .el-input__inner{
        width: 100%;
    }
}
// 富文本
/deep/ .w-e-text{
    min-height:500px !important;
}
/deep/ .el-dialog__body{
    max-height:600px !important;
    overflow: auto;
}
</style>