<template>
    <div id="customerList">
        <div class="filter-box" style="display: flex">
            <el-date-picker
                v-model="dataRange"
                type="month"
                placeholder="选择月">
            </el-date-picker>
            <SearchInput
                style="margin-left: 20px"
                :search.sync="search"
                placeholder="渠道编号"
                @search="searchData"
            />
        </div>
        <div class="table">
            <el-table
                :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                v-loading="loading"
                ref="multipleTable"
                tooltip-effect="dark"
                :data="tableData"
                height="100%"
                style="width: 100%">
                <el-table-column type="index" width="80" align="center" label="序号"></el-table-column>
                <table-one-empty prop="month" width="80" align="center" label="月份"></table-one-empty>
                <table-one-empty prop="channel" show-overflow-tooltip align="center" label="渠道编号"></table-one-empty>
                <table-one-empty prop="inst_num" show-overflow-tooltip align="center" label="月安装量"></table-one-empty>
                <table-one-empty prop="reg_num" show-overflow-tooltip align="center" label="月注册量"></table-one-empty>
                <table-one-empty prop="month_active_member" show-overflow-tooltip align="center"
                                 label="月活跃人数（注册）"></table-one-empty>
                <table-one-empty prop="month_active_visitor" show-overflow-tooltip align="center"
                                 label="月活跃人数（游客）"></table-one-empty>
            </el-table>
        </div>
    </div>
</template>

<script>
    import {
        addMember,
        updateMember,
        siteManage,
        cancelManage,
        statisticalMonth
    } from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import InpSearch from "@components/common/inpSearch";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";
    import CommonButton from "@components/common/CommonButton";
    import TableOneEmpty from "@components/common/tableOneEmpty";

    export default {
        components: {
            InpSearch,
            SearchInput,
            Pagination,
            CommonButton,
            TableOneEmpty
        },
        data() {
            return {
                search: '',
                total: 0,
                currentId: '',
                popupType: 'add',    //add || edit
                loading: true,
                selectList: [],
                dialogFormVisible: false,
                form: {
                    account: '',
                    nickname: '',
                    password: "",
                },
                formLabelWidth: '100px',
                tableData: [],
                searchCon: [],
                pagination: {
                    currentPage: 1,
                    pageSize: 200,
                    total: 0
                },
                dataRange: null,
            }
        },
        watch: {
            dialogFormVisible(newVal, oldVal) {
                if (!newVal && this.popupType != 'add') { //新增窗口 &&  弹窗关闭状态
                    this.form = {account: "", nickname: "", password: "",}
                }
            },
            dataRange(newVal, oldVal) {
                this.pagination.pageSize = 20
                this.pagination.currentPage = 1
                this.init()
            }
        },
        computed: {
            apiParams() {
                return {
                    pageSize: this.pagination.pageSize,
                    pageNum: this.pagination.currentPage,
                    channel: this.search,
                    month: this.dataRange ? '2023-0' +  (new Date(this.dataRange).getMonth() + 1) : ''
                }
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            async init() {
                this.loading = true
                try {
                    let {data} = await statisticalMonth(this.apiParams)
                    console.log(data)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        if (data.data && data.data.length) {
                            this.tableData = JSON.parse(JSON.stringify(data.data))
                        } else {
                            this.tableData = []
                        }
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            add() {
                this.dialogFormVisible = true
                this.popupType = "add"
            },
            handleEdit(index, row) {
                this.currentId = row.member_id
                this.dialogFormVisible = true
                this.popupType = "edit"
                this.form = {
                    account: row.account,
                    nickname: row.nickname,
                    password: row.password,
                }
            },
            async submit() {
                if (this.popupType === 'add') {
                    let dataJson = {
                        account: this.form.account,
                        nickname: this.form.nickname,
                        password: this.form.password
                    }
                    let {data} = await addMember(dataJson)
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialogFormVisible = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                } else {
                    let dataJson = {
                        member_id: this.currentId,
                        nickname: this.form.nickname,
                        password: this.form.password
                    }
                    let {data} = await updateMember(dataJson)
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialogFormVisible = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            async changeSwitch(item) {
                let msg = ''
                let type = 'success'
                let {data} = await updateMember({member_id: item.member_id, status: item.status == 1 ? 2 : 1})
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.addDialog = false
                    await this.init()
                } else {
                    msg = data.msg
                    type = 'warning'
                }
            },
            async setSuper(item) {
                if (item.super_manage == 1) {
                    await siteManage({member_id: item.member_id}).then(res => {
                        if (res.data.code == 200) {
                            this.init()
                        }
                    })
                } else {
                    await cancelManage({member_id: item.member_id}).then(res => {
                        if (res.data.code == 200) {
                            this.init()
                        }
                    })
                }
            },
            searchData() {
                this.pagination.pageSize = 200
                this.pagination.currentPage = 1
                this.init()
            },
        }
    }
</script>

<style scoped lang="scss">
    #customerList {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .filter-box {
            margin-bottom: 20px;
        }

        .pagination {
            margin-top: 10px;
        }

        .table {
            height: calc(100% - 100px);
        }
    }

    .btnAdd {
        margin-left: 15px;
    }

    /deep/ .el-table .descending .sort-caret.descending {
        border-top-color: #189e90;
    }

    /deep/ .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #189e90;
    }


    /deep/ .el-table tr {
        cellspacing: 10px;
        border: 1px solid #CCCCCC;
        padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        //border-spacing: 0 10px;
        background-color: #f6f6f6;
    }


    /deep/ .el-table th.el-table__cell > .cell {
        color: #6d6d6e;
        font-size: 14px;
    }


    /deep/ .el-dialog { //弹窗
        width: 600px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__header {
        background-color: #0E3F6A;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-form-item__label {
        color: #000;
        font-size: 14px;
    }

    /deep/ .el-input__inner {
        border: 0;
        border-radius: 5px;
        border: 1px solid #B5B5B5;
    }
</style>
