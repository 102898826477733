<template>

    <div class="page-box">
        <div class="filter-box" style="display: flex">
          <SearchInput
              :search.sync="search"
              placeholder="直播ID/主播ID"
              @search="searchData"
          />
          <el-button type="primary" @click="openLiveHost">开播</el-button>
            <div class="statics flex-1 flex align-center flex-end " v-if="staticInfo">
              <span
                      class="font-14 m-r-10"
                v-for="(label, key) in  staticMap"
                :key="key"
              >{{ label }}: <span class="font-16 font-600">{{ staticInfo[key]}}</span>
              </span>
            </div>
        </div>
        <div class="table">
          <el-table :data="tableData"
                    :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                    height="100%"
                    style="width: 100%">
            <el-table-column type="index" width="80" align="center"  label="序号"></el-table-column>
            <el-table-column prop="room_id" align="center"  label="直播间ID" width="100"></el-table-column>
            <el-table-column prop="room_title" align="center" :min-width="120" label="直播间标题"></el-table-column>
            <el-table-column prop="live_type" align="center"  label="主播类型">
              <template slot-scope="scope">
                <span class="">{{ scope.row.live_type === 2 ? '自挂直播' : '正常直播'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="heat_num" align="center"  label="主播热度"></el-table-column>
            <el-table-column prop="nick" align="center"  label="主播昵称"></el-table-column>
            <el-table-column prop="recommend" align="center"  label="是否推荐" width="120">
              <template slot-scope="scope">
<!--                <el-switch-->
<!--                    active-text=""-->
<!--                    inactive-text=""-->
<!--                    active-color="green"-->
<!--                    inactive-color="#ccc"-->
<!--                    @change="changeSwitch(scope.row,1)"-->
<!--                    v-model="scope.row.is_position == 2 ">-->
<!--                </el-switch>-->
<!--                <status-button-group-->
<!--                    :values="[2,1]"-->
<!--                    active-label="是"-->
<!--                    in-active-label="否"-->
<!--                    :tips="['推荐', '不推荐']"-->
<!--                    target="该直播间"-->
<!--                    :value="scope.row.is_position"-->
<!--                    @change="changeSwitch(scope.row,1)"-->
<!--                />-->
                <status-button
                    active-label="是"
                    in-active-label="否"
                    :tips="['推荐', '不推荐']"
                    target="该直播间"
                    :value="scope.row.is_position == 2"
                    @change="changeSwitch(scope.row,1)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="sort" sortable align="center" :min-width="120" label="热门" ></el-table-column>
            <el-table-column prop="start_time"  align="center" label="最近开播时间" width="200"></el-table-column>
            <el-table-column prop="view_num" sortable align="center"  label="访问"  width="80"></el-table-column>
            <el-table-column prop="online_num" sortable align="center" label="在线"  width="80" ></el-table-column>
            <el-table-column prop="live_cover" align="center"  label="直播封面">
              <template slot-scope="scope">
                <el-image
                    style="max-width: 32px;max-height: 32px"
                    :src="scope.row.live_cover"
                    :preview-src-list="[scope.row.live_cover,scope.row.live_cover]">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="is_mobile" align="center"  label="绿色房间" width="120">
              <template slot-scope="scope">
<!--                <el-switch-->
<!--                    active-text=""-->
<!--                    inactive-text=""-->
<!--                    active-color="green"-->
<!--                    inactive-color="#ccc"-->
<!--                    @change="changeSwitch(scope.row,3)"-->
<!--                    v-model="scope.row.is_mobile == 1 ">-->
<!--                </el-switch>-->
                <status-button
                    active-label="是"
                    in-active-label="否"
                    :tips="['开启', '关闭']"
                    target="绿色房间"
                    :value="scope.row.is_mobile == 1"
                    @change="changeSwitch(scope.row,3)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="chatroom_status" align="center"  label="绿色聊天" width="120">
              <template slot-scope="scope">
<!--                <el-switch-->
<!--                    active-text=""-->
<!--                    inactive-text=""-->
<!--                    active-color="green"-->
<!--                    inactive-color="#ccc"-->
<!--                    @change="changeSwitch(scope.row,4)"-->
<!--                    v-model="scope.row.chatroom_status == 1 ">-->
<!--                </el-switch>-->
<!--                <status-button-group-->
<!--                    :values="[1,2]"-->
<!--                    active-label="是"-->
<!--                    in-active-label="否"-->
<!--                    :tips="['开启', '关闭']"-->
<!--                    target="绿色聊天"-->
<!--                    :value="scope.row.chatroom_status"-->
<!--                    @change="changeSwitch(scope.row,4)"-->
<!--                />-->
                <status-button
                    active-label="是"
                    in-active-label="否"
                    :tips="['开启', '关闭']"
                    target="绿色聊天"
                    :value="scope.row.chatroom_status == 1"
                    @change="changeSwitch(scope.row,4)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="status" align="center" width="200" label="状态">
              <template slot-scope="scope">
<!--                <el-switch-->
<!--                    active-text="直播中"-->
<!--                    inactive-text="下播"-->
<!--                    active-color="green"-->
<!--                    inactive-color="#ccc"-->
<!--                    @change="changeSwitch(scope.row,2)"-->
<!--                    v-model="scope.row.status == 1 ">-->
<!--                </el-switch>-->

                <status-button
                    active-label="强制下播"
                    in-active-label="强制下播"
                    :tips="['直播', '强制下播']"
                    :target="''"
                    :value="scope.row.status == 1"
                    @change="changeSwitch(scope.row,2)"
                />
              </template>
            </el-table-column>
            <el-table-column  align="center"  label="操作">
              <template slot-scope="scope">
                <!--                    <button class="btnEditList" @click="handleEdit(scope.$index, scope.row)" type="text">编辑</button>-->
                <el-button @click="handleEdit(scope.$index, scope.row)" type="primary"
                >编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      <div class="pagination block">
        <pagination
            :current-page.sync="pagination.currentPage"
            :page-size.sync="pagination.pageSize"
            :total="pagination.total"
            @loadData="init"
        />
      </div>
        <el-dialog :close-on-click-modal="false" title="新增/编辑" :visible.sync="dialog" width="40%">
            <el-form ref="ruleForm" :model="formItem" label-width="100px">
                <el-form-item label="直播间标题">
                    <el-input v-model="formItem.room_title"></el-input>
                </el-form-item>
                <el-form-item label="是否推荐">
                    <el-switch v-model="formItem.is_position" :active-value="2" :inactive-value="1" />
                </el-form-item>
                <el-form-item label="推荐排序">
                    <el-input v-model="formItem.sort"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch v-model="formItem.status" :active-value="1" :inactive-value="2"></el-switch>
                </el-form-item>
                <el-form-item label="微信">
                    <el-input v-model="formItem.wechat"></el-input>
                </el-form-item>
                <el-form-item label="QQ">
                    <el-input v-model="formItem.qq"></el-input>
                </el-form-item>
                <el-form-item label="二维码">
                    <CommonUpload :icon.sync="formItem.qrcode" type="live-qrcode" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">提交</el-button>
                    <el-button @click="dialog = false">取消</el-button>
                    <el-button @click="resetForm()">重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
      <openLive
          :value.sync="openLiveDialog"
          @refresh="init"
      />
    </div>
</template>

<script>
    import {getLiveList, editLive, forbidLive, } from "@/api/control";
    import { getLiveRoomStatics } from '@/api/Host/Host'
    import {statusCode} from "@/util/statusCode";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";
    import openLive from "@/pages/livingList/openLive";
    import CommonUpload from "@/components/common/CommonUpload.vue";

    export default {
        components: {SearchInput, Pagination, openLive,CommonUpload},
        data() {
            return {

                total: 0,
                loading: true,
                tableData: [],
                isEditTxt: "详情/编辑",
                currentId: '',
                dialog: false,
                formItem: {
                    room_title: '',
                    is_position: '',
                    sort: '',
                    status: '',
                    wechat: '',
                    qq: '',
                    qrcode: ''
                },
                searchCon: [
                    {key: "member_id", val: '', pla: '直播ID/主播ID', label: '直播ID/主播ID', labelFlag: false},
                ],
                pagination: {
                  currentPage: 1,
                  pageSize: 20,
                  total: 0
                },
                search: '',
                staticMap: {
                  'sum_room': '直播间总数',
                  'sum_view': '总浏览人数',
                  'sum_online': '总在线人数'
                },
                staticInfo: null,
                openLiveDialog: false,
            }
        },
        computed: {
          apiParams() {
            return {
              pageSize: this.pagination.pageSize,
              pageNum: this.pagination.currentPage,
              member_id: this.search,
              live_status: 2
            }
          }
        },
        created() {
            this.init({pageSize: 10, pageNum: 1})
            this.initStatics()
        },
        methods: {
            async initStatics() {
              try {
                const { data } = await getLiveRoomStatics()
                  const { code, data: staticInfo } = data
                  this.staticInfo = staticInfo
              }  catch (e) {
                  console.log('error--error')
              }
            },
            async init() {
                // Object.assign(dataJson, {live_status: 2})
                this.loading = true
                try {
                    let {data} = await getLiveList(this.apiParams)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        this.tableData = JSON.parse(JSON.stringify(data.rows))
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            changePage(val) {
                this.init()
            },
            setSellStyle({row, column, rowIndex, columnIndex}) {
                if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
                if (columnIndex == 7) return "borderRadius: 0  10px 10px 0"
            },
            handleEdit(index, row) {
                this.dialog = true
                this.status = row.status
                this.currentId = this.tableData[index].id
                for (let k in row) {
                    for (let key in this.formItem) {
                        if (k === key) {
                            this.formItem[key] = row[k]
                        }
                    }
                }
                this.formItem.room_id = row.room_id
            },
            async onSubmit() { //编辑提交
                let msg = ''
                let type = 'success'
                let {data} = await editLive(this.formItem)
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.addDialog = false
                    await this.init()
                    this.dialog = false
                } else {
                    msg = data.msg
                    type = 'warning'
                }
                this.$message({
                    message: msg,
                    type,
                    duration: 2000
                });
            },
            async changeSwitch(item, num) {  // 1推荐  2直播间状态
                if (num === 1) {  //设置推荐
                    let msg = ''
                    let type = 'success'
                    let {data} = await editLive({room_id: item.room_id, is_position: item.is_position == 1 ? 2 : 1})
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.addDialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                } else if (num === 3) {
                    let msg = ''
                    let type = 'success'
                    let {data} = await editLive({room_id: item.room_id, is_mobile: item.is_mobile == 1 ? 2 : 1})
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.addDialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                } else if(num === 4){
                    let msg = ''
                    let type = 'success'
                    let {data} = await editLive({room_id: item.room_id, chatroom_status: item.chatroom_status == 1 ? 2 : 1})
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.addDialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }else{ // 禁播
                    let msg = ''
                    let type = 'success'
                    let {data} = await forbidLive({room_id: item.room_id, status: item.status == 1 ? 2 : 1})
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.addDialog = false
                        this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            resetForm() {
                for (let key in this.formItem) {
                    if (typeof this.formItem[key] !== "boolean") this.formItem[key] = ''
                }
            },
            searchData() {
              this.pagination.pageSize = 20
              this.pagination.currentPage = 1
              this.init()
            },
          openLiveHost() {
            this.openLiveDialog = true
          }
        }
    }
</script>

<style scoped lang="scss">
.page-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .filter-box {
    margin-bottom: 20px;
  }
  .pagination {
    margin-top: 10px;
  }
  .table {
    height: calc(100% - 100px);
  }
}
    .editBtn {
        color: #1e82d2;
    }

    .popImg {
        width: 50%;

        img {
            width: 100%;
        }
    }

    .popImgSee {
        width: 100%;
    }

    /deep/ .el-table--border .el-table__cell,
    .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
        //border: 0;
    }

    /deep/ .el-table tr {
        cellspacing: 10px;
        border: 1px solid #cccccc;
        padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        //border-spacing: 0 1px;
        background-color: #f6f6f6;
    }

    /deep/ .el-dialog {
        //弹窗
        //width: 400px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-dialog__header {
        background-color: #0E3F6A;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

</style>
