<template>
    <div class="page-box">
        <div class="filter-box" style="display: flex">
            <SearchInput
              :search.sync="search"
              placeholder="联赛名"
              @search="searchData"
          />
            <div>
                <el-select v-model="hierarchy" placeholder="联赛选择" style="margin-left: 10px" @change="changeLevel">
                    <el-option
                        v-for="item in options"
                        :key="item.hierarchy"
                        :label="item.label"
                        :value="item.hierarchy">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="table">
            <el-table :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                      v-loading="loading" ref="multipleTable" tooltip-effect="dark" :data="tableData"
                      height="100%"
                      style="width: 100%">
                <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
                <el-table-column align="center" prop="leagueId" label="赛队ID" width="100"></el-table-column>
                <el-table-column align="center" prop="nameChs" label="赛队全称"></el-table-column>
                <el-table-column align="center" prop="nameChsShort" label="赛队简称"></el-table-column>
                <el-table-column align="center" prop="leagueLogo" label="联赛logo">
                    <template slot-scope="scope">
                        <el-image
                            style="max-width: 32px;max-height: 32px"
                            :src="scope.row.leagueLogo"
                            :preview-src-list="[scope.row.leagueLogo,scope.row.leagueLogo]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="hierarchy" sortable label="赛事级别">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.hierarchy" placeholder="请选择"
                                   @change="switchChange(scope.row,1,$event)">
                            <el-option
                                v-for="item in [1,2,3,4]"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="sort" sortable label="排序"></el-table-column>
                <!--                <el-table-column prop="isHot" label="是否设为热门">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <el-switch-->
                <!--                                active-color="green"-->
                <!--                                inactive-color="#ccc"-->
                <!--                                @change="switchChange(scope.row,2)"-->
                <!--                                v-model="scope.row.recommend == 1">-->
                <!--                        </el-switch>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
                <table-one-empty align="center" prop="adminUser" label="操作人"></table-one-empty>
                <el-table-column align="center" prop="update_time" label="操作时间"></el-table-column>
                <table-one-empty align="center" prop="remark" label="备注"></table-one-empty>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.$index, scope.row)" type="primary"
                                   >编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>

        </div>
        <div class="pagination block">
          <pagination
              :current-page.sync="pagination.currentPage"
              :page-size.sync="pagination.pageSize"
              :total="pagination.total"
              @loadData="init"
          />
        </div>
        <el-dialog :close-on-click-modal="false" title="编辑" :visible.sync="dialog" width="20%">
            <el-form :model="form">
                <el-form-item label="赛队ID">
                    <el-input disabled v-model="form.leagueId"></el-input>
                </el-form-item>
                <el-form-item label="赛队简称">
                    <el-input disabled v-model="form.nameChsShort"></el-input>
                </el-form-item>
                <el-form-item label="热门排序">
                    <el-input v-model="form.sort"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit()">提交</el-button>
                <el-button @click="dialog = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getLeaguesList, editLeaguesList} from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";
    import TableOneEmpty from "@/components/common/tableOneEmpty";

    export default {
      components: {SearchInput, Pagination,TableOneEmpty},
        data() {
            return {
                currentPage1:1,
                hierarchy: '',
                options: [
                    {label: '一级', hierarchy: 1},
                    {label: '二级', hierarchy: 2},
                    {label: '三级', hierarchy: 3},
                    {label: '四级', hierarchy: 4},
                ],
                total: 0,
                currentType: '',
                usernameQuery: '',
                loading: true,
                tableData: [],
                dialog: false,
                form: {
                    leagueId: '',
                    nameChsShort: '',
                    sort: '',
                },
                // searchCon: [
                //     {key: "nameChsShort", val: '', pla: '联赛名', label: '联赛关键字', labelFlag: false},
                // ],
                pagination: {
                  currentPage: 1,
                  pageSize: 20,
                  total: 0
                },
                search: ''
            }
        },
        computed: {
          apiParams() {
            return {
              pageSize: this.pagination.pageSize,
              pageNum: this.pagination.currentPage,
              nameChsShort: this.search,
              hierarchy: this.hierarchy
            }
          }
        },
        created() {
            this.init()
        },
        methods: {
            async init() {
                this.loading = true
                try {
                    let {data} = await getLeaguesList(this.apiParams)
                    console.log(data)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        this.tableData = JSON.parse(JSON.stringify(data.rows))
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            changeLevel() {
                this.currentPage1 = 1
                this.pagination.currentPage = 1
                this.pagination.pageSize = 20
                this.init()  //初始化
            },
            setSellStyle({row, column, rowIndex, columnIndex}) {
                if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
                if (columnIndex == 7) return "borderRadius: 0  10px 10px 0"
            },
            async switchChange(row, num, hierarchy) { //num 1 赛事等级  2 热门设置
                if (num === 1) {
                    let {data} = await editLeaguesList({leagueId: row.leagueId, hierarchy})
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.addDialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                } else if (num === 2) {
                    let {data} = await editLeaguesList({leagueId: row.leagueId, recommend: row.recommend == 1 ? 0 : 1})
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.addDialog = false
                        this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            handleEdit(index, row) {
                this.dialog = true
                this.form.leagueId = row.leagueId
                this.form.nameChsShort = row.nameChsShort
                this.form.sort = row.sort
            },
            async submit() {
                let {data} = await editLeaguesList(this.form)
                let msg = ''
                let type = 'success'
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.dialog = false
                    this.init()
                } else {
                    msg = data.msg
                    type = 'warning'
                }
                this.$message({
                    message: msg,
                    type,
                    duration: 2000
                });
            },
            searchData() {
              this.currentPage1 = 1
              this.pagination.pageSize = 20
              this.pagination.currentPage = 1
              this.init()
            },
        }
    }
</script>
<style scoped lang="scss">
.page-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .filter-box {
    margin-bottom: 20px;
  }
  .pagination {
    margin-top: 10px;
  }
  .table {
    height: calc(100% - 100px);
  }
}
    .editBtn {
        color: #1e82d2;
    }

    .popImg {
        width: 50%;

        img {
            width: 100%;
        }
    }

    .popImgSee {
        width: 100%;
    }

    /deep/ .el-table--border .el-table__cell,
    .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
        //border: 0;
    }

    /deep/ .el-table tr {
        //cellspacing: 10px;
        border: 1px solid #cccccc;
        //padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        //border-spacing: 0 10px;
        background-color: #f6f6f6;
    }

    /deep/ .el-dialog {
        //弹窗
        width: 400px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-dialog__header {
        background-color: #189e90;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

</style>
