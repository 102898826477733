<template>
    <div :style="{width: width +'px', height: height + 'px'}" class="auto">
        <div :id="`svga${id}`" :ref="`svga${id}`" :style="{width: width +'px', height: height + 'px', 'z-index': 2}"></div>
    </div>
</template>

<script>
import SVGA from 'svgaplayerweb'
export default {
    props: {
        url: { // url地址，必传
            type: String,
            default: ''
        },
        id: { // 唯一id, 必传
            type: [String, Number],
            default: 'default'
        },
        width: {
            type: Number,
            default: 85
        },
        height: {
            type: Number,
            default: 85
        }
    },
    data () {
        return {
            player: null,
        }
    },
    mounted() {
        this.initSVGA()
    },
    watch: {
        url: {
            handler (newVal,oldVal) {
                this.initSVGA(newVal)
            }
        }
    },
    methods: {
        initSVGA(url) {
            const svgaUrl = url || this.url
            if (!svgaUrl) return
            let self = this
            self.player = new SVGA.Player(`#svga${this.id}`)
            let parser = new SVGA.Parser(`#svga${this.id}`)
            parser.load(svgaUrl,function (vidaoItem) {
                self.player.setVideoItem(vidaoItem)
                self.player.startAnimation()
            })
        },
    },
    beforeDestroy () {
        this.player.stopAnimation()
        this.player = null
    }
}
</script>

<style lang="scss" scoped>
.auto{
    margin: 0 auto;
    overflow: hidden;
}
</style>
