<template lang="html">
    <div class="editor">
        <div ref="toolbar" class="toolbar">
        </div>
        <div ref="editor" class="text content-section" style="width: 100%;">
        </div>
    </div>
</template>

<script>
    import E from 'wangeditor'

    export default {
        name: 'editoritem',
        data() {
            return {
                editor: null,
                info_: null
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            isClear: {
                type: Boolean,
                default: false
            },
            isNews: { // 新闻添加，默认处理行高和底部外边距
                type: Boolean,
                default: false
            }
        },
        watch: {
            isClear(val) {
                // 触发清除文本域内容
                if (val) {
                    this.editor.txt.clear()
                    this.info_ = null
                }
            },
            value: function (value) {
                if (value !== this.editor.txt.html()) {
                    this.editor.txt.html(this.value)
                }
            }
            //value为编辑框输入的内容，监听一下值，当父组件调用，如果给value赋值，子组件将会显示父组件赋给的值
        },
        mounted() {
            this.seteditor()
            this.editor.txt.html(this.value)
        },
        methods: {
            seteditor() {
                // http://192.168.2.125:8080/admin/storage/create
                this.editor = new E(this.$refs.toolbar, this.$refs.editor)
                this.editor.config.uploadImgShowBase64 = false // base 64 存储图片
                this.editor.config.uploadImgServer = `${apiUrl}` + '/common/upload/uploadImg'// 配置服务器端地址
                this.editor.config.uploadImgHeaders = {
                    'Accept': 'application/json'
                }// 自定义 header
                this.editor.config.uploadFileName = 'file' // 后端接受上传文件的参数名
                this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
                this.editor.config.uploadImgMaxLength = 6 // 限制一次最多上传 3 张图片
                this.editor.config.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
                this.editor.config.uploadImgParams = {
                    type: 'cover'
                }// 参数
                // this.editor.config.lineHeights = ['1', '1.15', '1.6', '2', '2.5', '3'] // 行高
                // this.editor.config.pasteFilterStyle = false // 关闭粘贴样式的过滤
                if (this.isNews) {
                    this.editor.config.pasteTextHandle = function (pasteStr) { // 对粘贴的文本进行处理，然后返回处理后的结果
                        // 匹配 p 标签和 img 标签的正则表达式
                        var pattern_p = /<p\b[^>]*>/g;
                        var pattern_img = /<img\b[^>]*>/g;
                        // 在匹配到的 p 标签中添加 margin-bottom 样式
                        pasteStr = pasteStr.replace(pattern_p, function(match) {
                            return match.slice(0, -1) + ' style="margin-bottom: 20px;line-height:1.6;">';
                        });
                        // 在匹配到的 img 标签中添加 width 样式
                        pasteStr = pasteStr.replace(pattern_img, function(match) {
                            return match.slice(0, -1) + ' style="margin-bottom: 20px;width: 100%;">';
                        });
                        console.log(pasteStr)
                        return pasteStr
                    }
                }

                // 配置菜单
                this.editor.config.menus = [
                    'head', // 标题
                    'bold', // 粗体
                    'fontSize', // 字号
                    'lineHeight', // 行高
                    'fontName', // 字体
                    'italic', // 斜体
                    'underline', // 下划线
                    'strikeThrough', // 删除线
                    'foreColor', // 文字颜色
                    'backColor', // 背景颜色
                    'link', // 插入链接
                    // 'list', // 列表
                    'justify', // 对齐方式
                    'quote', // 引用
                    'emoticon', // 表情
                    'image', // 插入图片
                    'table', // 表格
                    // 'video', // 插入视频
                    // 'code', // 插入代码
                    'undo', // 撤销
                    'redo', // 重复
                    'fullscreen' // 全屏
                ]

                this.editor.config.uploadImgHooks = {
                    fail: (xhr, editor, result) => {
                        // 插入图片失败回调

                    },
                    success: (xhr, editor, result) => {
                        // 图片上传成功回调

                    },
                    timeout: (xhr, editor) => {
                        // 网络超时的回调
                    },
                    error: (xhr, editor) => {
                        // 图片上传错误的回调

                    },
                    customInsert: (insertImg, result, editor) => {
                        // 图片上传成功，插入图片的回调
                        //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
                        // console.log(result.data[0].url)
                        //insertImg()为插入图片的函数
                        //循环插入图片
                        // for (let i = 0; i < 1; i++) {
                        // console.log(result)
                        // let url = "http://otp.cdinfotech.top"+result.url
                        // console.log(result)
                        // let url = `${apiUrl}` + result.data.url
                        let url = result.data.url
                        console.log(url)
                        insertImg(url)
                        // }
                    }
                }
                this.editor.config.onchange = (html) => {
                    this.info_ = html // 绑定当前逐渐地值
                    this.$emit('change', this.info_) // 将内容同步到父组件中
                }
                // 创建富文本编辑器
                this.editor.create()
            }
        }
    }
</script>

<style lang="scss">
    .editor {
        /*width: 100%;*/
        /*margin: 0 auto;*/
        /*position: relative;*/
        /*z-index: 0;*/
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        border: solid 1px rgba(0, 0, 0, .2);
    }

    .toolbar {
        width: 100%;
        border-bottom: 1px solid #ccc;
        z-index: 6;
        position: relative;
    }
    .content-section{
      z-index: 5;
      position: relative;
      height: calc(100% - 50px);
    }
    /deep/ .w-e-text{
        background-color: red;
    }

</style>
