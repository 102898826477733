
const url = {
    startLive: '/admin/live/startLive',
    closeLive: '/api/live/close',
    getOBSAddress: '/api/live/getOBS',
    getMatchesByDay: '/api/scheduleMatch/getScheduleByDay', // 赛程按日期
    getLeagues: '/api/leagues/hotLists', // 获取热门联赛

    uploadImage: '/common/upload/uploadImg',

    getOpenAnchorList: '/admin/anchor/rows', // 开播主播列表
    getLiveRoomStatics: '/admin/liveRoom/statistics',
    getLivingRecordStatics: '/admin/liveRoom/statsHistory'
}

export default url
