<template>
    <div id="top-up-record">
        <div class="search flex">
            <search-input :search.sync="order_no" placeholder="订单号" @search="searchData" />
            <search-input :search.sync="search" placeholder="昵称/ID" @search="searchData" />
            <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" class="date">
            </el-date-picker>
            <div>
                <el-select v-model="pay_channel" placeholder="支付方式" @change="init" class="select">
                    <el-option label="全部" value="">全部</el-option>
                    <el-option label="微信支付" value="weixin">微信支付</el-option>
                    <el-option label="支付宝支付" value="alipay">支付宝支付</el-option>
                    <el-option label="银联支付" value="bank">银联支付</el-option>
                </el-select>
            </div>
            <div>
                <el-select v-model="status" placeholder="交易状态" @change="init" class="select">
                    <el-option label="全部" value="">全部</el-option>
                    <el-option label="交易成功" :value="1">交易成功</el-option>
                    <el-option label="交易失败" :value="2">交易失败</el-option>
                    <el-option label="交易取消" :value="3">处理中</el-option>
                </el-select>
            </div>
        </div>
        <div class="table">
            <el-table v-loading="loading" :data="tableData" height="100%" style="width:100%" :row-style="{ height: '40px' }"
                :header-row-style="{ height: '40px' }" border>
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column label="订单号" prop="order_no" align="center" width="160" />
                <table-one-empty label="三方/四方订单号" prop="platform_no" align="center" />
                <el-table-column label="会员ID" prop="member_id" align="center" />
                <el-table-column label="会员昵称" prop="nickname" align="center" />
                <el-table-column label="购买数量" prop="ball_coin" align="center" />
                <el-table-column label="购买类别" prop="type" align="center">
                    <template slot-scope="scope">
                        {{ ['-','球币','球票'][scope.row.type] }}
                    </template>
                </el-table-column>
                <el-table-column label="价格（CNY）" prop="amount" align="center" />
                <el-table-column label="支付方式" prop="pay_channel" align="center" />
                <el-table-column label="创建时间" prop="create_time" align="center" />
                <table-one-empty label="到账时间(回调时间)" prop="notify_time" align="center" />
                <el-table-column label="交易状态" prop="status" align="center">
                    <template slot-scope="scope">
                        <span :style="{color: ['-', '#67C23A', '#F56C6C', '#E6A23C'][scope.row.status]}">
                            {{ ['-', '成功', '失败', '处理中'][scope.row.status] }}
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagin">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div>
    </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput.vue';
import Pagination from '@/pages/Layout/Pagination.vue';
import TableOneEmpty from "@/components/common/tableOneEmpty.vue";
import { getPayRecordList } from '@/api/topUp'
import { statusCode } from '@/util/statusCode'
import dayjs from 'dayjs'
export default {
    components: {
        SearchInput,
        Pagination,
        TableOneEmpty
    },
    data () {
        return {
            order_no: '',
            search: '',
            pay_channel: '',
            status: '',
            dateRange: '',
            type: '',
            loading: false,
            tableData: [],
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            formLabelWidth: '100px',
            form: {},
            dialogFormVisible: false
        }
    },
    watch: {
        dateRange () {
            this.init()
        }
    },
    computed: {
        apiParams () {
            return {
                pageNum: this.pagination.currentPage,
                pageSize: this.pagination.pageSize,
                order_no: this.order_no,
                search: this.search,
                pay_channel: this.pay_channel,
                status: this.status,
                start_date: this.dateRange && this.dateRange[0] ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : '',
                end_date: this.dateRange && this.dateRange[1] ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : ''
            }
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        async init () {
            this.loading = true
            try {
                const { data } = await getPayRecordList(this.apiParams)
                if (data.code === statusCode.success) {
                    this.pagination.total = data.total
                    this.tableData = data.rows
                } else {
                    this.$message({message: data.msg, type: 'warning'})
                }
            } catch(e) {
                console.log('出现错误')
            } finally {
                this.loading = false
            }
        },
        searchData () {
            this.pagination.pageSize = 20
            this.pagination.currentPage = 1
            this.init()
        }
    }
}
</script>

<style lang="scss" scoped>
#top-up-record {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .search {
        margin-bottom: 20px;

        .date {
            margin-right: 15px;
        }
        .select {
            margin-right: 15px;
        }
        .addBtn {
            margin-left: 15px;
        }
    }

    .table {
        height: calc(100% - 100px);
    }

    .pagin {
        margin-top: 10px;
    }
}

// 表格
/deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
}


/deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}


//弹窗
/deep/ .el-dialog {
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
</style>