<template>
  <el-dialog
      :close-on-click-modal="false"
      :visible="value"
    destroy-on-close
    append-to-body
    close-on-press-escape
    custom-class="open-live-dialog"
    title="开播"
    @close="close"
  >
    <div class="live-cast p-b-30">
      <div class="content info font-16 font-regular text-333">
        <div class="row-outer league-type-row flex align-center p-l-30 m-t-15">
            <span class="label">
                主播
            </span>
            <div class="content">
                <el-select
                    v-model="form.anchor.value"
                    filterable
                    remote
                    reserve-keyword
                    clearable
                    @clear="getAnchors"
                    placeholder="请输入主播ID/主播名称"
                    :remote-method="getAnchors">
                    <el-option
                        v-for="item in anchors"
                        :key="item.member_id"
                        :label="item.anchor_name"
                        :value="item.member_id">
                    </el-option>
                </el-select>
            </div>
        </div>
        <input-with-error
            class=" m-t-15"
            showLabel
            :label="form.weChat.label"
            :error-info="errorInfo.weChat"
            :row-info.sync="form.weChat"
            @validate="validateRow"
            :key="form.weChat.updateKey"
        />
        <input-with-error
            class=" m-t-15"
            showLabel
            :label="form.qq.label"
            :error-info="errorInfo.qq"
            :row-info.sync="form.qq"
            @validate="validateRow"
            :key="form.qq.updateKey"
        />
        <div class="row-outer flex align-center p-l-30 m-t-15 ">
            <span class="label">
                二维码
            </span>
            <div class="content">
                <CommonUpload :icon.sync="QRcode" type="live-qrcode" />
            </div>
        </div>
        <div class="row-outer flex align-center p-l-30 m-t-15 ">
            <span class="label">
                直播分类
            </span>
          <div class="content">
            <el-radio-group v-model="category" @change="changeCategory">
              <el-radio
                  v-for="item in categoryOptions"
                  :key="item.id"
                  :label="item.id"
                  :value="item.key"
              >{{item.text}}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="row-outer league-type-row flex align-center p-l-30 m-t-15 " v-if="![3,4].includes(category)">
            <span class="label">
                联赛
            </span>
          <div class="content">
            <el-radio-group v-model="liveType" @change="changeLeagueType" >
              <el-radio
                  v-for="type in leagueTypeOptions"
                  :key="type.id"
                  :label="type.id">{{ type.label}}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="row-outer flex align-center p-l-30 m-t-15 ">
                <span class="label">
                    比赛时间
                </span>
          <div class="content flex-1">
            <el-date-picker
                class="time-picker w-100"
                v-model="showTime"
                type="date"
                @change="selectTime"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <SelectWithError
            v-if="needValidateMatch"
            class=" m-t-15"
            showLabel
            :label="form.match.label"
            :error-info="errorInfo.match"
            :row-info.sync="form.match"
            @validate="validateRow"
            :key="form.match.updateKey"
            :options="competitionOptions"
        />
        <input-with-error
            class=" m-t-15"
            showLabel
            :label="form.title.label"
            :error-info="errorInfo.title"
            :row-info.sync="form.title"
            @validate="validateRow"
            :key="form.title.updateKey"
        />
        <input-with-error
            :maxLength="2000"
            class=" m-t-15"
            showLabel
            :label="form.videoUrl.label"
            :error-info="errorInfo.videoUrl"
            :row-info.sync="form.videoUrl"
            @validate="validateRow"
            :key="form.videoUrl.updateKey"
        />

        <!--
        <textarea-with-error
        class=" m-t-15"
        showLabel
        :label="form.announcement.label"
        :error-info="errorInfo.announcement"
        :row-info.sync="form.announcement"
        @validate="validateRow"
        :key="form.announcement.updateKey"
        />
        -->
        <UploadWithError
            class="m-b-20 m-t-15"
            :max-size="300"
            showLabel
            :label="form.liveCover.label"
            :row-info.sync="form.liveCover"
            @changeFile="changeFile"
            :show-error="coverError"
        />

        <div class="row-outer flex flex-end p-l-30 m-b-10">
          <div class="save-button font-medium font-16">
            <span class="confirm text-center w-100 h-100 d-inline-block" @click="beforeSubmit">{{buttonString}}</span>
          </div>
          <el-button class="reset-form-btn" type="primary" @click="resetForm">重置</el-button>
<!--          <span class="confirm text-center w-100 h-100 d-inline-block" @click="resetForm">重置</span>-->

        </div>
      </div>
    </div>
  </el-dialog>

</template>

<script>
import InputWithError from '@/components/Form/InputWithError'
import TextareaWithError from '@/components/Form/TextareaWithError'
import SelectWithError from '@/components/Form/SelectWithError'
import UploadWithError from '@/components/Form/UploadWithError'
import { isRequire, Copy } from '@/util/validator'
import { isEmpty, omit } from '@/util/lodashUtil'
import { getAnchorList } from "@/api/control";
import { startLive, getOBSAddress, closeLive, getLeagues, getMatchesByDay, getOpenAnchorList } from '@/api/Host/Host'
import { Message } from 'element-ui'
import { statusCode } from '@/util/statusCode'
import dayjs from 'dayjs'
import { StorageFn } from "@/util/cookie";
import CommonUpload from '@/components/common/CommonUpload'


export default {
  name: 'openLive',
  components: {
    TextareaWithError,
    InputWithError,
    SelectWithError,
    UploadWithError,
    CommonUpload
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      form: {
        anchor: {
          label: '主播',
          value: null,
          key: 'anchor',
          validators: [isRequire('主播')],
          validateLabel: ['isRequire'],
          updateKey: 'anchor-false'
        },
        title: {
          label: '直播标题',
          value: '',
          key: 'title',
          validators: [isRequire('直播标题')],
          validateLabel: ['isRequire'],
          updateKey: 'title-false'
        },
        videoUrl: {
          label: '视频地址',
          value: '',
          key: 'videoUrl',
          validators: [isRequire('视频地址')],
          validateLabel: ['isRequire'],
          updateKey: 'videoUrl-false'
        },
        match: {
          label: '直播比赛',
          value: null,
          key: 'match',
          validators: [],
          validateLabel: ['isRequire'],
          updateKey: 'match-false'
        },
        // announcement: {
        //   label: '直播公告',
        //   value: null,
        //   key: 'announcement',
        //   validators: [],
        //   validateLabel: [],
        //   updateKey: 'announcement-false'
        // },
        liveCover: {
          label: '直播封面',
          value: '',
          key: 'liveCover',
          validators: [],
          validateLabel: ['isRequire'],
          updateKey: 'liveCover-false'
        },
        weChat: {
          label: '微信',
          value: '',
          key: 'weChat',
          validators: [],
          validateLabel: ['isRequire'],
          updateKey: 'weChat-false'
        },
        qq: {
          label: 'QQ',
          value: '',
          key: 'qq',
          validators: [],
          validateLabel: ['isRequire'],
          updateKey: 'qq-false'
        },
      },
      QRcode: '',
      liveType: 'all',
      leagueTypeOptions: [
        {
          id: 'all',
          label: '全部',
          value: 'all'
        }
      ],
      category: 1,
      errorInfo: {
        anchor: {},
        title: {},
        match: {},
        videoUrl: {}
      },
      coverError: false,
      competitionOptions: [],
      obs: null,
      showTime: dayjs().format('YYYY-MM-DD'),
      broadcastTypes: [
        {
          id: 5, // 未定
          title: '正在热播',
          key: 'living'
        },
        {
          id: 1,
          title: '足球热播',
          text: '足球',
          key: 'football'
        },
        {
          id: 2,
          title: '篮球热播',
          text: '篮球',
          key: 'basketball'
        },
        {
          id: 3,
          title: '电竞热播',
          text: '电竞',
          key: 'eSports'
        },
        {
          id: 4,
          title: '其他',
          text: '其他',
          key: 'other'
        }
      ],
      anchors: []
    }
  },
  computed: {
    needValidateMatch () {
      return [1, 2].includes(this.category)
    },
    buttonString () {
      return '立即开播'
    },
    categoryOptions () {
      return this.broadcastTypes.filter(x => {
        return !(x.id === 5) // 过滤掉 正在热播
      })
    },
    pickerOptions () {
      return {
        disabledDate: date => {
          return dayjs(date).isBefore(dayjs(), 'day')
        }
      }
    }
  },
  watch: {
    value: {
      handler() {
        if(this.value) {
          this.init()
        }
      },
      immediate: true
    }
  },
  created() {
    this.getAnchors()
  },
  methods: {
    async getAnchors(name) {
      console.log('执行')
      console.log(name)
      const jsonData = {
        pageNum: 1,
        pageSize: 2000,
        searchKey: name
      }
      const { data }  = await getOpenAnchorList(jsonData)
      if (data.code === statusCode.success) {
        this.anchors = data.rows
      } else {
        this.$message({message: data.msg, type: 'warning'})
      }
    },
    handleCategory () {
      this.form.title.value = ''
      this.form.match.value = ''
      this.errorInfo.match = {}
      this.getLeagues()
    },
    beforeSubmit () {
      this.submit()
    },
    async closeLive () {
      try {
        const { code, msg } = await closeLive()
        if (code === statusCode.success) {
          Message.success(msg)
          this.reload()
        }
      } catch (e) {
        console.log('出错了')
      }
    },
    selectTime () {
      this.getLeagues()
      this.fetchData()
    },
    async fetchData (isFirst) {
      const loadingBox = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      try {
        this.competitionOptions = []
        const { data: { data, code, msg } } = await getMatchesByDay({
          leagueType: this.category,
          leagueId: this.liveType === 'all' ? null : this.liveType,
          day: this.showTime
        })
        if (code === statusCode.success) {
          this.competitionOptions = data && data.reduce((all, item) => {
            const showLabel = `${item.leagueChsShort} ${item.homeChs} ${item.matchId ? 'VS' : ''} ${item.awayChs}`
            all.push({
              ...item,
              id: item.matchId,
              value: item.matchId,
              label: showLabel
            })
            return all
          }, [])
          if (isFirst) {
            this.init(isFirst)
          }
        } else {
          Message.error(msg)
        }
      } catch (e) {
        console.log('出错了')
      } finally {
        loadingBox.close()
      }
    },
    async init () {
      this.getLastData()
      this.handleCategory()
      this.fetchData()
    },
    resetForm() {
      this.form.anchor.value = ''
      this.form.title.value = ''
      this.form.match.value = ''
      this.form.liveCover.value = ''
      this.form.weChat.value = ''
      this.form.qq.value = ''
      this.QRcode = ''
      this.category = 1
    //   this.form.announcement.value = ''
      this.form.videoUrl.value = ''
      this.liveType = 'all'
      StorageFn.removeLocal('obs' )
      this.init()
    },
    getLastData() {
      // 获取上一次的开播信息， 本来是需要从后台查的
      const res = StorageFn.getCanExpireLocal('obs')
      if(res) {
        // 有值
        this.initForm(res)
      }

    },
    initForm(res) {
        this.form.anchor.value = res.memberId
        this.form.title.value = res.title
        this.form.match.value = res.matchId
        this.form.liveCover.value = res.liveCover
        this.form.weChat.value = res.wechat
        this.form.qq.value = res.qq
        this.QRcode = res.qrcode
        this.category = res.category
        // this.form.announcement.value = res.placard
        this.form.videoUrl.value = res.videoUrl
        this.liveType = res.liveType
    },
    async submit () {
      const isValidate = this.validate()
      this.changeFile()
      const isCoverValidate = !!this.form.liveCover.value
      if (!isValidate || !isCoverValidate) return
      const params = {
        memberId: this.form.anchor.value,
        title: this.form.title.value,
        liveCover: this.form.liveCover.value,
        wechat: this.form.weChat.value,
        qq: this.form.qq.value,
        qrcode: this.QRcode,
        category: this.category,
        // placard: this.form.announcement.value,
        videoUrl: this.form.videoUrl.value,
      }
      if (this.needValidateMatch) {
        params.matchId =  this.form.match.value
      }
      const { data: { code, msg } } = await startLive(params)
      // 存在本地
      StorageFn.setCanExpireLocal('obs', { ...params, liveType: this.liveType}, 1)
      if (code === statusCode.success) {
        Message.success('开播成功')
        this.$emit('refresh')
        this.close()

      } else {
        Message.error(msg)
      }
    },
    changeFile () {
      this.coverError = !this.form.liveCover.value
    },
    validate () {
      const res = []
      Object.keys(this.form).forEach(key => {
        res.push(this.validateRow(key))
      })
      return res.every(x => x)
    },
    validateRow (key) {
      this.form[key].validators.forEach(validator => {
        const { message, type } = validator(this.form[key].value)
        if (message) {
          this.errorInfo[key][type] = message
        } else {
          this.errorInfo[key] = omit(this.errorInfo[key], type, message)
        }
      })
      this.changeKey(key)
      return isEmpty(this.errorInfo[key])
    },
    changeKey (key) {
      const flag = JSON.parse(this.form[key].updateKey.split('-')[1])
      this.form[key].updateKey = `${key}-${!flag}`
    },
    changeCategory () {
      this.liveType = 'all'
      if (this.needValidateMatch) {
        this.fetchData()
      } else {
        this.competitionOptions = []
      }
      this.handleCategory()
    },
    changeLeagueType () {
      this.form.match.value = ''
      this.fetchData()
    },
    async getLeagues () {
      try {
        this.leagueTypeOptions = [
          {
            id: 'all',
            label: '全部',
            value: 'all'
          }
        ]
        const time = dayjs(this.showTime).format('YYYY-MM-DD')
        const { data: { data, code} } = await getLeagues(this.category, time, 4000)
        if (code === statusCode.success) {
          data && data.forEach(item => {
            this.leagueTypeOptions.push({
              ...item,
              id: item.leagueId,
              value: item.leagueId,
              label: item.nameChsShort
            })
          })
        }
      } catch (e) {
        console.log('出错了')
      }
    },
    close() {
      this.reset()
      this.$emit('update:value', false)
      this.$emit('refresh')
    },
    reset() {
      Object.keys(this.form).forEach(key => {
        this.form[key].value = null
      })
      Object.keys(this.errorInfo).forEach(key => {
        this.errorInfo[key]  = { }
      })
      this.showTime =  dayjs().format('YYYY-MM-DD')
      this.liveType = 'all'
      this.category = 1
      this.coverError = false
    }
  }
}
</script>

<style lang="scss" scoped>
.row-outer {
  width: 570px;
  .label {
    width: 80px;
    margin-right: 45px;
  }
  .content {
    line-height: 40px;
  }
}
.league-type-row {
  width: 100% !important;
  .content {
    width: calc(100% - 150px);
  }
}
.obs {
  line-height: 25px;
}
.save-button {
  width: 138px;
  height: 47px;
  .confirm {
    background: linear-gradient(90deg, #3B5FFF, #A2B3FF);
    line-height: 47px;
    border-radius: 3px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
  }
}
.reset-form-btn {
  margin-left: 50px;
  padding: 0 20px;
}
::v-deep {
  .row-outer {
    .el-input__inner {
      line-height: 30px;
    }
    .save-button {
      width: 145px;
      font-size: 16px;
      font-family: PingFang-SC-Medium;
    }
    .el-radio {
      line-height: 30px;
      width: 70px;
      display: inline-block;
    }
  }
  .info {
    .row-inner {
      max-width: 570px;
      padding-left: 30px!important;
      .label {
        width: 80px;
        line-height: 30px;
        margin-right: 45px;

      }
      .input-section {
        background-color: transparent!important;
        border: 1px solid #E2E1E1;
        border-radius: 2px;
      }
      .el-input__icon {
        line-height: 30px;
      }
      .error{
        left: 157px;
      }
    }
  }
  .time-picker{
    width: 100% !important;
    .el-input__inner {
      line-height: 35px;
      height: 35px;
    }
  }
  .el-dialog__header {
    padding: 10px 20px!important;
  }
  .el-dialog__body {
    padding: 15px 20px!important;
    height: 37vw;
    overflow-y: auto;
  }
  .el-dialog__headerbtn {
    top: 15px;
  }
  .el-select{
    width: 445px;
    .el-input__inner{
        height: 36px !important;
        line-height: 36px;
        border-radius: 0 !important;
    }
  }
}
</style>
