<template>
    <div class="h-100">
        <div style="display: flex;">
            <el-input style="width: 200px;font-weight: bold" v-model="content.title"></el-input>
            <el-button :loading="loading" type="primary" style="height: 40px;margin-left: 10px" @click="editContent">确认提交</el-button>
        </div>
        <editor-bar
            class="editor"
            style="min-height: 200px;background-color: #f6f6f6;margin-top:30px;"
            v-model="content.content" @change="change">
        </editor-bar>
    </div>
</template>

<script>
    import EditorBar from "@components/common/editoritem";
    import {getContent, editContent} from "@/api/content"

    export default {
        components: {EditorBar},
        name: "content1",
        data() {
            return {
                loading:false,
                content: {},
            }
        },
        created() {
            this.getContent()
        },
        methods: {
            async getContent() {
                let id = this.$route.name.split('_')[1]
                let {data} = await getContent(id)
                if (data.code == 200) {
                    this.content = data.data
                    delete this.content.update_time
                    delete this.content.is_show
                    delete this.content.jump_url
                }
                this.loading = false
            },
            async editContent() {
                this.loading = true
                let {data} = await editContent({...this.content})
                if (data.code === 200) {
                    await this.getContent(data.id)
                    this.$message({
                        message: data.msg,
                        type: 'success',
                        duration: 1000
                    })
                }
            },
            change() {

            },
        }
    }
</script>

<style lang="scss" scoped>
.editor{
  height: calc(100% - 110px);
}
</style>
