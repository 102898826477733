
export const globalMixin = {
    data () {
        return {
            logoUrl: require('../assets/images/common/logo.png'),
            emptyLogo: require('../assets/images/common/logo-no-text.png')
        }
    },

}
