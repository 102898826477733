<template>
    <div>
        <el-dialog :title="popupType==='add'?'新增':'编辑'" :visible="value" :close-on-click-modal="false" @close="close" close-on-press-escape
            append-to-body destroy-on-close custom-class="open-live-dialog">
            <div class="formContent">
                <input-with-error
                showLabel
                :label="form.author.label"
                :error-info="errorInfo.author"
                :row-info.sync="form.author"
                @validate="validateRow"
                :key="form.author.updateKey"
                class="m-t-15" />
                <input-with-error
                showLabel
                :label="form.title.label"
                :error-info="errorInfo.title"
                :row-info.sync="form.title"
                @validate="validateRow"
                :key="form.title.updateKey"
                class="m-t-15" />
                <UploadWithError
                    class="m-b-20 m-t-15"
                    :max-size="300"
                    showLabel
                    :label="form.cover.label"
                    :row-info.sync="form.cover"
                    @changeFile="changeFile"
                    :show-error="coverError"
                >
                    <template slot="size">推荐图片封面尺寸为 800 * 600</template>
                </UploadWithError>
                <input-with-error
                showLabel
                :label="form.sort.label"
                :error-info="errorInfo.sort"
                :row-info.sync="form.sort"
                @validate="validateRow"
                :key="form.sort.updateKey"
                class="m-t-15" />
                <SelectWithError
                    class="m-b-10 m-t-10 flex-1 "
                    showLabel
                    :label="form.type.label"
                    :error-info="errorInfo.type"
                    :row-info.sync="form.type"
                    @validate="validateRow"
                    :options="typeOptions"
                    :key="form.type.updateKey"
                />
                <div class="row-outer flex align-center p-l-30 m-t-15 ">
                    <span class="label">
                        跳转方式
                    </span>
                    <div class="content">
                        <el-radio-group v-model="form.jump_type.value">
                            <el-radio :label="1">内跳</el-radio>
                            <el-radio :label="2">外跳</el-radio>
                            <el-radio label="">无跳转</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <input-with-error
                showLabel
                :label="form.jump_url.label"
                :error-info="errorInfo.jump_url"
                :row-info.sync="form.jump_url"
                @validate="validateRow"
                :key="form.jump_url.updateKey"
                class="m-t-15" />

                <div class="flex align-center p-l-30 m-t-15 editor-box ">
                    <span class="label">
                        正文内容
                    </span>
                    <div>
                        <editor-bar
                            class="editor"
                            style="min-height: 500px;width:750px;background-color: #f6f6f6;"
                            v-model="form.content.value">
                        </editor-bar>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit">提交</el-button>
                <el-button type="success" @click="reset">重置</el-button>
                <el-button @click="close">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import InputWithError from '@/components/Form/InputWithError'
import UploadWithError from '@/components/Form/UploadWithError'
import SelectWithError from '@/components/Form/SelectWithError'
import EditorBar from "@components/common/editoritem";
import { isRequire } from '@/util/validator'
import {isEmpty,omit} from '@/util/lodashUtil'
import {addInformation,editInformation} from "@/api/control"
import { statusCode } from '@/util/statusCode';
export default {
    components: {
        InputWithError,
        UploadWithError,
        SelectWithError,
        EditorBar,
    },
    props: {
        value: { // 弹窗显示/隐藏
            type: Boolean,
            default: false
        },
        popupType: { // 新增/编辑
            type: String,
            default: 'add'
        },
        info:{ // 编辑数据
            type: Object,
            default:{}
        },
        informationTypeData:{ //资讯类型
            type:Array,
            default:[]
        }
    },
    data() {
        return {
            form: {
                author: {
                    label: '昵称',
                    value: null,
                    key: 'author',
                    validators: [isRequire('昵称')],
                    validateLabel: ['isRequire'],
                    updateKey: 'author-false'
                },
                title: {
                    label: '资讯标题',
                    value: null,
                    key: 'title',
                    validators: [isRequire('资讯标题')],
                    validateLabel: ['isRequire'],
                    updateKey: 'title-false'
                },
                cover: {
                    label: '封面',
                    value: null,
                    key: 'cover',
                    validators: [isRequire('封面')],
                    validateLabel: ['isRequire'],
                    updateKey: 'cover-false'
                },
                sort: {
                    label: '排序',
                    value: null,
                    key: 'sort',
                    validators: [isRequire('排序')],
                    validateLabel: ['isRequire'],
                    updateKey: 'sort-false'
                },
                type: {
                    label: '资讯类型',
                    value: null,
                    key: 'type',
                    validators: [isRequire('资讯类型')],
                    validateLabel: ['isRequire'],
                    updateKey: 'type-false'
                },
                jump_type: {
                    label: '跳转方式',
                    value: null,
                    key: 'jump_type',
                    // validators: [isRequire('跳转方式')],
                    // validateLabel: ['isRequire'],
                    updateKey: 'jump_type-false'
                },
                jump_url: {
                    label: '链接地址',
                    value: null,
                    key: 'jump_url',
                    validators: [isRequire('链接地址')],
                    validateLabel: ['isRequire'],
                    updateKey: 'jump_url-false'
                },
                content: {
                    label: '正文内容',
                    value: null,
                    key: 'content',
                    validators: [isRequire('链接地址')],
                    validateLabel: ['isRequire'],
                    updateKey: 'content-false'
                },
            },
            errorInfo: {
                author: {},
                title: {},
                cover:{},
                sort: {},
                type:{},
                jump_type:{},
                jump_url:{}
            },
            coverError: false,
        }
    },
    computed: {
        // select组件需要
        typeOptions() {
            return this.informationTypeData.map((value,index) => {
                return {
                    id: index,
                    value: value,  //index: 数字，value: 文字，
                    label: value,
                }
            }).filter((item) => {
                return item.value !== '全部'
            })
        }
    },
    watch: {
        value: {
            handler() {
                if (this.value) {
                    this.initForm()
                }
            },
            // immediate: true
        }
    },
    methods: {
        initForm() {
            if (!isEmpty(this.info)) {
                this.form.title.value = this.info.title
                this.form.author.value = this.info.author
                this.form.content.value = this.info.content
                this.form.cover.value = this.info.cover
                this.form.type.value = this.info.type
                this.form.sort.value = this.info.sort
                this.form.jump_url.value = this.info.jump_url
                this.form.jump_type.value = this.info.jump_type
                // this.form.status.value = this.info.status
            }
        },
        async submit() {
            const isValidate = this.validate()
            console.log(isValidate)
            if (isValidate) {
                let dataJson = {
                    title: this.form.title.value,
                    author: this.form.author.value,
                    content: this.form.content.value,
                    cover: this.form.cover.value,
                    type: this.form.type.value,
                    sort: this.form.sort.value * 1,
                    jump_url: this.form.jump_url.value,
                    jump_type: this.form.jump_type.value,
                    status: 2   // 1开启  2关闭,添加时默认关闭
                }
                let request
                if (this.popupType === 'add') { // 添加
                    request = addInformation
                } else { // 编辑
                    request = editInformation
                    dataJson.id = this.info.id
                    dataJson.status = this.info.status
                }
                const {data} = await request(dataJson)
                if (data.code === statusCode.success) {
                    this.$message({
                        message: '成功',
                        type: 'success',
                        duration: 2000
                    })
                    this.close()
                }
            }
        },
        /**
         * 失焦验证
         * 下列方法配套公共form组件
         */
        validateRow(key) {
            this.form[key].validators.forEach(validator => {
                const { message, type } = validator(this.form[key].value)
                console.log(message, type, '----', validator, this.form[key].value)
                if (message) {
                this.errorInfo[key][type] = message
                } else {
                this.errorInfo[key] = omit(this.errorInfo[key], type, message)
                }
            })
            this.changeKey(key)
            return isEmpty(this.errorInfo[key])
        },
        changeKey (key) {
            const flag = JSON.parse(this.form[key].updateKey.split('-')[1])
            this.form[key].updateKey = `${key}-${!flag}`
        },
        validate () {
            const res = []
            Object.keys(this.form).forEach(key => {
                res.push(this.validateRow(key))
            })
            return res.every(x => x)
        },
        close() {
            this.reset()
            this.$emit('update:value', false)
            this.$emit('refresh')
        },
        reset() {
            Object.keys(this.form).forEach(key => {
                this.form[key].value = null
            })
            Object.keys(this.errorInfo).forEach(key => {
                this.errorInfo[key]  = { }
            })
        },
        // 上传组件需要
        changeFile () {
            this.coverError = !this.form.cover.value
        },
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

/deep/ .el-dialog__header {
    background: #0E3F6A;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
}

/deep/ .el-dialog__title {
    color: #fff;
}
// form样式
/deep/ .formContent {
    .row-inner {
        max-width: 570px;
        padding-left: 30px !important;

        .label {
            width: 80px;
            line-height: 30px;
            margin-right: 45px;

        }

        .input-section {
            background-color: transparent !important;
            border: 1px solid #E2E1E1;
            border-radius: 2px;
        }

        .el-input__icon {
            line-height: 30px;
        }

        .error {
            left: 157px;
        }
    }
}
// radio样式
.row-outer {
  width: 570px;
  .label {
    width: 80px;
    margin-right: 45px;
  }
  .content {
    line-height: 40px;
  }
}
.label{
    width: 80px;
    margin-right: 45px;
}
// 富文本
/deep/ .w-e-text{
    min-height:500px !important;
}
/deep/ .el-dialog__body{
    max-height:600px !important;
    overflow: auto;
}
</style>