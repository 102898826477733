import request from "../request";
import url from "./url";

export function home(t) {   //首页数据
    return request({
        method: "post",
        url: url.home,
        params: {
            t,
        }
    })
}

export function getAdminList(pageNum, pageSize) { //管理员列表
    return request({
        method: 'post',
        url: url.adminList,
        data: {
            pageNum, pageSize
        }
    })
}

export function addAdmin(data) { //新增管理员
    return request({
        method: 'post',
        url: url.addAdmin,
        data
    })
}

export function updatePwd(data) { //修改管理员密码
    return request({
        method: 'post',
        url: url.updatePwd,
        data
    })
}

export function updateAdmin(data) { //管理员状态管理
    return request({
        method: 'post',
        url: url.updateAdmin,
        data
    })
}

export function getAnchorList(data) { //主播列表
    return request({
        method: 'post',
        url: url.anchorList,
        data
    })
}

export function addAnchor(member_id) { //新增主播
    return request({
        method: 'post',
        url: url.addAnchor,
        data: {
            member_id
        }
    })
}

export function updateAnchor(data) { //编辑主播
    return request({
        method: 'post',
        url: url.updateAnchor,
        data
    })
}

export function getMemberList(data) { //会员列表
    return request({
        method: 'post',
        url: url.memberList,
        data
    })
}

export function addMember(data) { //会员列表
    return request({
        method: 'post',
        url: url.addMember,
        data
    })
}

export function updateMember(data) { //编辑会员
    return request({
        method: 'post',
        url: url.updateMember,
        data
    })
}

export function deleteMember(data) { //删除会员
    return request({
        method: 'post',
        url: url.deleteMember,
        data
    })
}

export function getLeaguesList(data) { //获取联赛列表
    return request({
        method: 'post',
        url: url.leaguesList,
        data
    })
}

export function getScheduleList(data) { //足球赛事列表
    return request({
        method: 'post',
        url: url.scheduleList,
        data
    })
}
export function scheduleListB(data) { //篮球赛事列表
    return request({
        method: 'post',
        url: url.scheduleListB,
        data
    })
}

export function leaguesListB(data) { //获取篮球联赛列表
    return request({
        method: 'post',
        url: url.leaguesListB,
        data
    })
}

export function editLeaguesB(data) { //编辑篮球联赛
    return request({
        method: 'post',
        url: url.editLeaguesB,
        data
    })
}

export function editLeaguesList(data) { //赛事列表
    return request({
        method: 'post',
        url: url.editLeagues,
        data
    })
}

export function getLiveList(data) { //直播列表
    return request({
        method: 'post',
        url: url.liveList,
        data
    })
}

export function getLiveOrder(data) { //直播记录
    return request({
        method: 'post',
        url: url.liveOrder,
        data
    })
}

export function cancelReverse(data) { //取消直播
    return request({
        method: 'post',
        url: url.cancel,
        data
    })
}

export function getLiveRecord(data) { //直播记录
    return request({
        method: 'post',
        url: url.liveRecord,
        data
    })
}

export function editLive(data) { //编辑直播间
    return request({
        method: 'post',
        url: url.editLive,
        data
    })
}

export function forbidLive(data) { //禁播
    return request({
        method: 'post',
        url: url.editLiveStatus,
        data
    })
}

export function getContentList() { //内容列表
    return request({
        method: 'post',
        url: url.contentList,
        params: {}
    })
}

export function getAdvList(data) {  //广告列表
    return request({
        method: 'post',
        url: url.advList,
        data
    })
}

export function addAdv(data) {  //新增广告
    return request({
        method: 'post',
        url: url.addAdv,
        data
    })
}

export function editAdv(data) {  //编辑广告
    return request({
        method: 'post',
        url: url.editAdv,
        data
    })
}

export function deleteAdv(data) {  //删除广告
    return request({
        method: 'post',
        url: url.deleteAdv,
        data
    })
}

export function getAnnounce(data) {  //公告列表
    return request({
        method: 'post',
        url: url.announceManage,
        data
    })
}

export function editAnnounce(data) {  //编辑公告
    return request({
        method: 'post',
        url: url.editAnnouncement,
        data
    })
}

export function announceEditStatus(data) {  //编辑状态管理
    return request({
        method: 'post',
        url: url.announceEditStatus,
        data
    })
}

export function addAnnounce(data) {  //新增公告
    return request({
        method: 'post',
        url: url.addAnnouncement,
        data
    })
}

export function getAnnounceRoom(data) {  //公告列表-room
    return request({
        method: 'post',
        url: url.systemMessageLists,
        data
    })
}

export function addAnnounceRoom(data) {  //新增公告-room
    return request({
        method: 'post',
        url: url.systemMessageAdd,
        data
    })
}

export function announceDelete(data) {  //编辑状态管理-room
    return request({
        method: 'post',
        url: url.systemMessageDelete,
        data
    })
}

export function editAnnounceRoom(data) {  //编辑公告-room
    return request({
        method: 'post',
        url: url.systemMessageEdit,
        data
    })
}

export function getFeedback() {  //用户反馈管理
    return request({
        method: 'post',
        url: url.feedbackManage,
    })
}

export function getWebSet() {  //获取全局网站开关配置
    return request({
        method: 'post',
        url: url.webSet,
    })
}

export function muteRoomList(data) {  //禁言列表
    return request({
        method: 'post',
        url: url.muteRoomList,
        data
    })
}

export function cancelMute(data) {  //取消禁言
    return request({
        method: 'post',
        url: url.cancelMute,
        data
    })
}

export function siteManage(data) {  //设为房管
    return request({
        method: 'post',
        url: url.siteManage,
        data
    })
}

export function cancelManage(data) {  //设为房管
    return request({
        method: 'post',
        url: url.cancelManage,
        data
    })
}

export function appDownloadLists(data) {  //app下载列表
    return request({
        method: 'post',
        url: url.appDownloadLists,
        data
    })
}

export function appDownloadAdd(data) {  //app下载列表
    return request({
        method: 'post',
        url: url.appDownloadAdd,
        data
    })
}

export function appDownloadEdit(data) {  //app下载列表
    return request({
        method: 'post',
        url: url.appDownloadEdit,
        data
    })
}
export function checkMode(data) {  //审核模式
    return request({
        method: 'post',
        url: url.checkMode,
        data
    })
}
export function chatroomMasterSwitch(data) {  //审核模式
    return request({
        method: 'post',
        url: url.chatroomMasterSwitch,
        data
    })
}
export function statisticalIndex(data) {  //渠道管理
    return request({
        method: 'post',
        url: url.statisticalIndex,
        data
    })
}
export function statisticalMonth(data) {  //渠道月统计
    return request({
        method: 'post',
        url: url.statisticalMonth,
        data
    })
}
export function repairF(data) {  //足球赛事修复
    return request({
        method: 'post',
        url: url.repairF,
        data
    })
}
export function repairB(data) {  //篮球赛事修复
    return request({
        method: 'post',
        url: url.repairB,
        data
    })
}
export function blackIpList(data) {  //IP黑名单-列表
    return request({
        method: 'post',
        url: url.blackIpList,
        data
    })
}
export function blackIp(data) {  //IP黑名单-添加
    return request({
        method: 'post',
        url: url.blackIp,
        data
    })
}
export function blackIpDel(data) {  //IP黑名单-删除
    return request({
        method: 'post',
        url: url.blackIpDel,
        data
    })
}
export function versionLists(params) {  //版本管理
    return request({
        method: 'get',
        url: url.versionLists,
        params
    })
}
export function versionAdd(data) {  //版本添加
    return request({
        method: 'post',
        url: url.versionAdd,
        data
    })
}
export function versionUpdate(data) {  //版本修改
    return request({
        method: 'post',
        url: url.versionUpdate,
        data
    })
}

export function versionDelete(data) {  //版本修改
    return request({
        method: 'post',
        url: url.versionDelete,
        data
    })
}


// 全局开关
export function getAuditList(data) {
    return request({
        method: 'get',
        url: url.getAuditList,
        params: data
    })
}

export function addAudit(data) {
    return request({
        method: 'post',
        url: url.addAudit,
        data
    })
}


export function editAudit(data) {
    return request({
        method: 'post',
        url: url.editAudit,
        data
    })
}
export function removeAudit(id) {
    return request({
        method: 'post',
        url: url.removeAudit,
        data: {
            id
        }
    })
}

// 白名单
export function getWhiteList(data) {
    return request({
        method: 'get',
        url: url.getWhiteList,
        params: data
    })
}
export function addWhiteList(data) {
    return request({
        method: 'post',
        url: url.addWhiteList,
        data
    })
}


export function editWhiteList(data) {
    return request({
        method: 'post',
        url: url.editWhiteList,
        data
    })
}
export function removeWhiteList(id) {
    return request({
        method: 'post',
        url: url.removeWhiteList,
        data: {
            id
        }
    })
}
export function applyList(params) {
    return request({
        method: 'get',
        url: url.applyList,
        params
    })
}
export function realNameList(params) {  //实名认证列表
    return request({
        method: 'get',
        url: url.realNameList,
        params
    })
}
export function realnameEdit(data) {  //实名认证审核
    return request({
        method: 'post',
        url: url.realnameEdit,
        data
    })
}
export function realnameInfo(params) {  //实名认证详情
    return request({
        method: 'get',
        url: url.realnameInfo,
        params
    })
}
export function identityList(params) {  //身份验证列表
    return request({
        method: 'get',
        url: url.applyList,
        params
    })
}
export function applyInfo(params) {  //查询身份验证详情
    return request({
        method: 'get',
        url: url.applyInfo,
        params
    })
}
export function applyEdit(data) {  //编辑身份申请状态
    return request({
        method: 'post',
        url: url.applyEdit,
        data
    })
}


export function informationList(params){ //资讯列表
    return request({
        method: 'get',
        url: url.informationList,
        params
    })
}
export function addInformation(data){ //添加咨询
    return request({
        method: 'post',
        url: url.addInformation,
        data
    })
}
export function editInformation(data){ //编辑资讯
    return request({
        method: 'post',
        url: url.editInformation,
        data
    })
}
export function delInformation(data){ //删除资讯
    return request({
        method: 'post',
        url: url.delInformation,
        data
    })
}
export function informationType(params) { //资讯类型
    return request({
        method: 'get',
        url: url.informationType,
        params
    })
}


export function getCsLink() {
    return request({
        method: 'get',
        url: url.getCsLink
    })
}
export function setCsLink(link) {
    return request({
        method: 'post',
        url: url.setCsLink,
        data: {
            link
        }
    })
}

export function editTestFlag(data) {
    return request({
        method: 'post',
        url: url.editTestFlag,
        data
    })
}


