<template>
    <div id="cash-audit">
        <div class="search flex">
            <search-input :search.sync="order_no" placeholder="订单号" @search="searchData" />
            <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" class="date">
            </el-date-picker>
            <div>
                <el-select v-model="status" placeholder="状态" @change="init">
                    <el-option label="全部" value="0">全部</el-option>
                    <el-option label="审核中" value="3">审核中</el-option>
                    <el-option label="提现成功" value="1">提现成功</el-option>
                    <el-option label="提现失败" value="2">提现失败</el-option>
                </el-select>
            </div>
        </div>
        <div class="table">
            <el-table v-loading="loading" :data="tableData" height="100%" style="width:100%" :row-style="{ height: '40px' }"
                :header-row-style="{ height: '40px' }" border>
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column label="订单号" prop="order_no" align="center" width="180" />
                <el-table-column label="收款银行" prop="bank" align="center" />
                <el-table-column label="收款人姓名" prop="name" align="center" />
                <el-table-column label="收款人银行卡号" prop="card_number" align="center" width="160" />
                <el-table-column label="提现金额" prop="ball_coin" align="center" />
                <el-table-column label="申请人账号" prop="account" align="center" />
                <el-table-column label="申请时间" prop="create_time" align="center" width="160" />
                <table-one-empty label="审核时间" prop="verify_time" align="center" width="160" />
                <el-table-column label="状态" prop="status" align="center">
                    <template slot-scope="scope">
                        <span :style="{color: ['-','#67C23A', '#F56C6C', '#E6A23C'][scope.row.status]}">
                            {{ ['-','成功','失败','审核中'][scope.row.status] }}
                        </span>
                    </template>
                </el-table-column>
                <table-one-empty label="OA单号" prop="oa_no" align="center" />
                <table-one-empty label="操作人" prop="edit_by" align="center" />
                <el-table-column label="操作" prop="" align="center" width="160">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="handlerEdit(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagin">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div>
        <el-dialog :close-on-click-modal="false" title="编号/拒绝原因" :visible.sync="dialogFormVisible"
        width="35%" :before-close="handlerCancel">
            <el-form :model="form" :rules="rules" ref="formName">
                <el-form-item label="收款银行" :label-width="formLabelWidth">
                    <el-input v-model="form.bank" disabled />
                </el-form-item>
                <el-form-item label="收款人姓名" :label-width="formLabelWidth">
                    <el-input v-model="form.name" disabled />
                </el-form-item>
                <el-form-item label="收款卡号" :label-width="formLabelWidth" >
                    <el-input v-model="form.card_number" disabled />
                </el-form-item>
                <el-form-item label="提现球币数量" :label-width="formLabelWidth">
                    <el-input v-model="form.ball_coin" disabled />
                </el-form-item>
                <el-form-item label="申请人ID" :label-width="formLabelWidth">
                    <el-input v-model="form.member_id" disabled />
                </el-form-item>
                <el-form-item label="状态" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.status" :disabled="hidden">
                        <el-radio :label="1">同意</el-radio>
                        <el-radio :label="2">拒绝</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="OA单号" :label-width="formLabelWidth" prop="oa_no" v-if="form.status === 1">
                    <el-input v-model="form.oa_no" :disabled="hidden" />
                </el-form-item>
                <el-form-item label="拒绝理由" :label-width="formLabelWidth" v-else-if="form.status === 2">
                    <el-input type="textarea" :rows="2" v-model="form.reason" :disabled="hidden" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="formValidate" v-show="!hidden">提交</el-button>
                <el-button @click="handlerCancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput.vue';
import Pagination from '@/pages/Layout/Pagination.vue';
import { getWithdraw, editWithdraw } from '@/api/topUp'
import dayjs from 'dayjs';
import { statusCode } from '@/util/statusCode'
import TableOneEmpty from '@/components/common/tableOneEmpty.vue';
export default {
    components: {
        SearchInput,
        Pagination,
        TableOneEmpty
    },
    data () {
        return {
            order_no: '',
            status: '',
            dateRange: '',
            type: '',
            loading: false,
            tableData: [],
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            formLabelWidth: '100px',
            form: {},
            hidden: false,
            dialogFormVisible: false,
            rules: {
                oa_no: [
                    {required: true, message: '请输入OA单号', trigger: 'blur'}
                ],
            }
        }
    },
    computed: {
        apiParams () {
            return {
                pageNum: this.pagination.currentPage,
                pageSize: this.pagination.pageSize,
                order_no: this.order_no,
                status: this.status,
                start_date: this.dateRange && this.dateRange[0] ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : '',
                end_date: this.dateRange && this.dateRange[1] ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : '',
            }
        },
    },
    mounted () {
        this.init()
    },
    watch: {
        dateRange () {
            this.init()
        },
        'form.status': {
            handler(newVal, oldVal) {
                try {
                    this.$refs['formName'] && this.$refs['formName'].resetFields();
                } catch (e) {
                    console.log('出现错误')
                }
            }
        }
    },
    methods: {
        async init () {
            this.loading = true
            try {
                const { data } = await getWithdraw(this.apiParams)
                if (data.code === statusCode.success) {
                    this.pagination.total = data.total
                    this.tableData = data.rows
                } else {
                    this.$message({message: data.msg, type: 'warning'})
                }
            } catch (e) {
                console.log('出现错误')
            } finally {
                this.loading = false
            }
        },
        searchData () {
            this.pagination.currentPage = 1
            this.pagination.pageSize = 20
            this.init()
        },
        handlerEdit (row) {
            this.dialogFormVisible = true
            this.form = {
                order_no: row.order_no,
                bank: row.bank,
                name: row.name,
                card_number: row.card_number,
                ball_coin: row.ball_coin,
                member_id: row.member_id,
                status: row.status,
                oa_no: row.oa_no,
                reason: row.reason
            }
            this.hidden = row.status === 3 ? false : true
        },
        handlerCancel () {
            this.form = {}
            this.$refs['formName'] && this.$refs['formName'].resetFields();
            this.dialogFormVisible = false
        },
        formValidate () {
            this.$refs['formName'].validate((valid) => {
                if (valid) {
                    this.submit()
                }
            })
        },
        async submit () {
            const jsonData = {
                order_no: this.form.order_no,
                oa_no: this.form.oa_no,
                status: this.form.status,
                reason: this.form.reason
            }
            let message = '操作成功', type = 'success'
            const  { data } = await editWithdraw(jsonData)
            console.log(data)
            if (data.code === statusCode.success) {
                this.dialogFormVisible = false
                this.init()
            } else {
                message = data.msg, type = 'warning'
            }
            this.$message({message, type})
        }
    }
}
</script>

<style lang="scss" scoped>
#cash-audit {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .search {
        margin-bottom: 20px;

        .date {
            margin-right: 15px;
        }

        .addBtn {
            margin-left: 15px;
        }
    }

    .table {
        height: calc(100% - 100px);
    }

    .pagin {
        margin-top: 10px;
    }
}

// 表格
/deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
}


/deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}


//弹窗
/deep/ .el-dialog {
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
</style>