<template>
    <version-control VersionType="2"></version-control>
</template>

<script>
    import VersionControl from "@components/common/VersionControl";

    export default {
        name: 'admin',
        components: {VersionControl},
    }
</script>

