<template>
  <BasicLayout>
    <router-view />
  </BasicLayout>
</template>

<script>

    import BasicLayout from "@/pages/Layout/BasicLayout";
    import { getToken, getItem } from "@/util/cookie";
    export default {
      components: {
        BasicLayout
      },
      data() {
        return {
          token: null,
        }
      },
      created() {
          this.token = getToken()
          const token = getToken()
          const userInfo = sessionStorage.userInfo && JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('userInfo'))))
          this.$store.commit('loginInfo', { token, userInfo})
          document.querySelector("title").innerHTML = '后台管理系统'
      },
    };
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
}
    .el-menu {
        //width: 200px;
        height: 100vh;
        text-align: left;
    }

    .Nav {
        height: 100%;
        display: flex;
    }

    .el-submenu .el-menu-item {
        min-width: 182px;
    }

    .navBottom {
        width: 100%;
    }

    .navTop {
        font-size: 0;
        //width: 200px;
        //background-color: rgb(84, 92, 100);

        .logo {
            width: 40px;
        }
    }

    #app {
        flex: 1;
        //padding: 10px;

        .app_top {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;

            .top_title {
                font-size: 20px;
            }

            .username {
                display: flex;

                .account {
                    color: dodgerblue;
                    line-height: 30px;
                }

                .btn {
                    cursor: pointer;
                    width: 100px;
                    height: 30px;
                    margin: 0 10px;
                    padding: 5px 10px;
                    border-radius: 6px;
                    border: solid 1px #000;
                }
            }
        }


    }
</style>
