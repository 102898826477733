import request from "../request"
import url from "./url"

export function getHaveAuditList(params) { //有料审核列表
    return request({
        method: 'get',
        url: url.haveAuditList,
        params
    })
}

export function editHaveAudit(data) { //有料审核编辑
    return request({
        method: 'post',
        url: url.editHaveAudit,
        data
    })
}

export function haveList(params) { //有料列表
    return request({
        method: 'get',
        url: url.haveList,
        params
    })
}

export function addHaveList(data) { //有料列表添加
    return request({
        method: 'post',
        url: url.addHaveList,
        data
    })
}

export function editHaveList(data) { //有料列表编辑
    return request({
        method: 'post',
        url: url.editHaveList,
        data
    })
}

export function haveSellMoney() { // 有料售卖金额
    return request({
        method: 'get',
        url: url.haveSellMoney
    })

}

export function expertList(params) { //专家列表
    return request({
        method: 'get',
        url: url.expertList,
        params
    })
}

export function addExpertList(data) { //专家列表添加
    return request({
        method: 'post',
        url: url.addExpertList,
        data
    })
}

export function editExpertList(data) { //专家列表编辑
    return request({
        method: 'post',
        url: url.editExpertList,
        data
    })
}

export function switchStatus(data) { //专家列表状态
    return request({
        method: 'post',
        url: url.switchStatus,
        data
    })
}

export function oddsList(params) { //赔率列表
    return request({
        method: 'get',
        url: url.oddsList,
        params
    })
}

export function sellMoney(params) { //有料售卖金额列表
    return request({
        method: 'get',
        url: url.sellMoney,
        // params
    })
}

export function addSellMoney (data) { // 售卖金额添加
    return request({
        method: 'post',
        url: url.addSellMoney,
        data
    })
}

export function editSellMoney (data) { // 售卖金额编辑
    return request({
        method: 'post',
        url: url.editSellMoney,
        data
    })
}

export function delSellMoney (data) { // 售卖金额删除
    return request({
        method: 'post',
        url: url.delSellMoney,
        data
    })
}

export function haveDetail(params) { //有料售卖金额列表
    return request({
        method: 'get',
        url: url.haveDetail,
        params
    })
}

export function getOddsLeagues (type, day, playing, hierarchy) { // 有料联赛列表
    return request({
        method: 'post',
        url: url.getOddsLeagues,
        data: { // 联赛类型 1足球 2篮球 3电竞 4其他
            league_type: type,
            day,
            playing,
            hierarchy
        }
    })
}

export function getOddsSchedule(params) { // 有料赛事列表
    return request({
        method: 'get',
        url: url.getOddsSchedule,
        params
    })
}
