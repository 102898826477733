<template>
    <div class="header">
        <div class="logo">管理系统</div>
        <div class="topMenu">
            <ul v-if="topHisMenu.length" class="topMenuList">
                <li class="menu-item" :class="{active:item.path === currentMenu}" @click="goRouter(item)"
                    :index="item+1" v-for="(item,i) in topHisMenu" :key="i">
                    <i class="el-icon-close" @click="delMenu(item)"></i>
                    {{ item.txt }}
                </li>
            </ul>
        </div>
        <div class="right-user">
            <div class="user">
                <span>{{ userInfo && userInfo.account }}</span>
            </div>
            <div class="logout" @click="logout">
                <i class="el-icon-switch-button"></i>
                <span>退出</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {removeToken} from "@/util/cookie";

    export default {
        name: "Header",
        data() {
            return {
                currentMenu: '',
                activeIndex: '',
            }
        },
        computed: {
            topHisMenu() {
                return this.$store.state.menuList
            },
            userInfo() {
                return this.$store.state.userInfo;
            }
        },
        // mounted() {
        //     this.currentMenu = this.$route.path
        // },
        watch: {
            $route(to, from) {
                this.currentMenu = to.path
            }
        },
        methods: {
            logout() {
                removeToken()
                this.$store.commit('loginInfo', {token: null, userInfo: null})
                this.$router.push({path: '/'})
            },
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
            },
            delMenu(item) {
                this.$store.commit('delMenu', item)
            },
            goRouter(item) {
                if ((item.path).includes(this.$route.path)) return
                this.$router.push({path: item.path})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
        /deep/ .el-menu.el-menu--horizontal {
            background-color: #0E3F6A;
            border-bottom: 1px solid #0E3F6A;
        }

        .topMenu {
            //width: 100%;
            height: 100%;
            font-size: 12px;
            text-align: center;
            flex: 1;

            .topMenuList {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                width: 100%;
                height: 100%;

                .menu-item {
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    margin-top: 10px;
                    z-index: 1;
                    position: relative;
                    font-size: inherit;
                    font-family: inherit;
                    color: #fff;
                    padding: 0.5em 1em;
                    outline: none;
                    border: none;
                    background-color: hsl(236, 32%, 26%);
                    overflow: hidden;
                    transition: color 0.4s ease-in-out;

                    .el-icon-close {
                        display: none;
                        position: absolute;
                        right: 0;
                        top: 0;
                        font-size: 12px;
                        color: #000;
                        cursor: pointer;
                    }
                }

                .menu-item:hover {
                    .el-icon-close {
                        display: block;
                    }
                }

                .active {
                    color: #000;
                    background-color: rgba(166, 255, 242, .8);
                }

                .menu-item::before {
                    content: '';
                    z-index: -1;
                    position: absolute;
                    top: 100%;
                    right: 100%;
                    width: 1em;
                    height: 1em;
                    border-radius: 50%;
                    background-color: rgba(166, 255, 242, .8);
                    transform-origin: center;
                    transform: translate3d(50%, -50%, 0) scale3d(0, 0, 0);
                    transition: transform 0.45s ease-in-out;
                }

                .menu-item:hover {
                    cursor: pointer;
                    color: #000;
                }

                .menu-item:hover::before {
                    transform: translate3d(50%, -50%, 0) scale3d(15, 15, 15);
                }

            }
        }

        .logo {
            width: 180px;
            color: #000;
            text-align: center;
            line-height: 60px;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 2px;

            img {
                height: 32px;
                margin-top: 14px;
                vertical-align: middle;
            }

        }

        .right-user {
            display: flex;
            justify-content: flex-end;
            font-size: 20px;
            color: #999;
            font-weight: 400;
            line-height: 60px;
            margin-right: 50px;

            .user {
                margin-right: 50px;
                display: flex;
                //align-items: center;

                img {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                }
            }

            .logout {
                cursor: pointer;
                display: inline-block;

                i {
                    margin-right: 7px;
                }
            }
        }
    }

</style>
