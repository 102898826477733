<template>
<div>
  <el-button
      size="mini"
      :type="type"
      @click="changeActiveValue"
  >{{ title }}</el-button>

</div>
</template>

<script>
export default {
name: "StatusButton",
  props: {
    value: {// 当前高亮的值
      type: Boolean,
      default: true
    },
    activeLabel: {
      type: String,
      default: '正常'
    },
    inActiveLabel: {
      type: String,
      default: '禁用'
    },
    tips: {
      type: Array,
      default: () => ([])
    },
    target: {
      type: String,
      default: ''
    },
    noEvent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeValue: null
    }
  },
  computed: {
    type() {
        console.log( this.activeLabel)
        let dangerType = this.activeLabel === '强制下播'
      return this.value ? dangerType ? 'danger' : 'success' : 'info'
    },
    title() {
      return this.value ? this.activeLabel : this.inActiveLabel
    }
  },
  created() {
    this.activeValue = this.value
  },
  methods: {
    changeActiveValue() {
      if (this.noEvent) {
        return
      }
      const operation = this.value ? this.tips[1]  : this.tips[0]
      const msg = `确认${operation}${this.target}?`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('change')
      })
    }
  }
}
</script>

<style scoped>

</style>
