<template>
<router-view />
</template>

<script>
export default {
name: "EmptyView"
}
</script>

<style scoped>

</style>
