<template>
    <div id="customerList">
        <div class="filter-box" style="display: flex">
            <SearchInput
                :search.sync="search"
                placeholder="会员ID/手机号/注册来源"
                @search="searchData"
            />
            <SearchInput
                :search.sync="searchNickname"
                placeholder="会员昵称"
                @search="searchData"
            />
            <div>
                <el-select v-model="type" placeholder="请选择" @change="init" clearable>
                    <el-option label="全部" value="">全部</el-option>
                    <el-option label="会员" value="1">会员</el-option>
                    <el-option label="游客" value="2">游客</el-option>
                </el-select>
            </div>
            <div class="m-l-15">
                <el-select v-model="account_type" placeholder="请选择" @change="init" clearable>
                    <el-option label="全部" value="">全部</el-option>
                    <el-option label="超管" :value="1">超管</el-option>
                    <el-option label="礼物账号" :value="2">礼物账号</el-option>
                </el-select>
            </div>
            <el-date-picker
                format="yyyy-MM-dd"
                style="margin-left: 20px"
                v-model="dataRange"
                type="daterange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
            </el-date-picker>
            <CommonButton @click.native="add" class="btnAdd" title="新增"/>
        </div>
        <div class="table">
            <el-table
                :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                v-loading="loading"
                ref="multipleTable"
                tooltip-effect="dark"
                :data="tableData"
                height="100%"
                style="width: 100%">
                <el-table-column type="index" width="80" align="center" label="序号"></el-table-column>
                <table-one-empty prop="account"  align="center" label="账号"></table-one-empty>
                <table-one-empty prop="member_id"    align="center" label="会员ID"></table-one-empty>
                <table-one-empty prop="nickname" show-overflow-tooltip align="center" label="昵称" ></table-one-empty>
                <table-one-empty prop="create_time" show-overflow-tooltip align="center" label="创建时间" ></table-one-empty>
                <table-one-empty prop="last_time" show-overflow-tooltip align="center" label="最后登录时间" ></table-one-empty>
                <table-one-empty prop="last_ip" show-overflow-tooltip align="center" label="上次登录IP" ></table-one-empty>
                <table-one-empty prop="region" show-overflow-tooltip align="center" label="地址" > </table-one-empty>
                <table-one-empty prop="login_times" show-overflow-tooltip align="center" label="登录次数" ></table-one-empty>
                <el-table-column prop="channel" show-overflow-tooltip align="center" label="渠道来源"></el-table-column>
                <!-- 礼物，账号，超管，暂时移动到新增/编辑里面修改 -->
                <!-- <el-table-column prop="channel" align="center" label="礼物账号">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.test_flag"
                            :active-value="1"
                            :inactive-value="0"
                            @change="testFlagSwitch(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态" width="140">
                    <template slot-scope="scope">
                      <status-button
                          :tips="['启用', '禁用']"
                          target="该会员"
                          :value="scope.row.status == 1"
                          @change="changeSwitch(scope.row)"
                      />
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="设为超管" width="120">
                    <template slot-scope="scope">
                      <status-button
                          v-if="type != 2"
                          active-label="是"
                          in-active-label="否"
                          :tips="['设为', '取消']"
                          target="超管"
                          :value="scope.row.super_manage == 2"
                          @change="setSuper(scope.row)"
                      />
                        <div v-else>-</div>
                    </template>
                </el-table-column> -->
                <el-table-column prop="opt" align="center" label="操作" max-width="160">
                    <template slot-scope="scope">
                      <el-button v-if="type != 2" @click="handleEdit(scope.$index, scope.row)" type="primary"
                      >编辑</el-button>
                        <div v-else>-</div>
                        <el-button v-if="isAdmin" @click="handleDel(scope.row)" type="danger">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination block">
            <pagination
                :current-page.sync="pagination.currentPage"
                :page-size.sync="pagination.pageSize"
                :total="pagination.total"
                @loadData="init"
            />
        </div>
        <el-dialog :close-on-click-modal="false" title="新增/编辑" :visible.sync="dialogFormVisible" width="35%">
            <el-form :model="form">
                <el-form-item label="账号" :label-width="formLabelWidth">
                    <el-input :disabled="popupType === 'edit'" v-model="form.account"
                              autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="昵称" :label-width="formLabelWidth">
                    <el-input v-model="form.nickname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item v-if="popupType === 'add'" label="登录密码" :label-width="formLabelWidth">
                    <el-input v-model="form.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item v-else label="密码" :label-width="formLabelWidth">
                    <el-input placeholder="不填写则默认当前密码" v-model="form.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="状态" :label-width="formLabelWidth">
                    <el-switch
                        v-model="form.status"
                        :active-value="1"
                        :inactive-value="2">
                    </el-switch>
                </el-form-item>
                <el-form-item label="超管" :label-width="formLabelWidth">
                    <el-switch
                        v-model="form.super_manage"
                        :active-value="2"
                        :inactive-value="1">
                    </el-switch>
                </el-form-item>
                <el-form-item label="礼物账号" :label-width="formLabelWidth">
                    <el-switch
                        v-model="form.test_flag"
                        :active-value="1"
                        :inactive-value="0">
                    </el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit()">提交</el-button>
                <el-button @click="dialogFormVisible = false">取消</el-button>
            </div>
        </el-dialog>
    </div>

</template>
<script>
    import {getMemberList, addMember, updateMember, deleteMember, siteManage, cancelManage, editTestFlag} from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import InpSearch from "@components/common/inpSearch";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";
    import CommonButton from "@components/common/CommonButton";
    import TableOneEmpty from "@/components/common/tableOneEmpty.vue";

    export default {
        components: {
            InpSearch,
            SearchInput,
            Pagination,
            CommonButton,
            TableOneEmpty
        },
        data() {
            return {
                type: '', //1游客 2会员
                account_type: '', //1超管 2礼物账号
                search: '',
                searchNickname: '',
                total: 0,
                currentId: '',
                popupType: 'add',    //add || edit
                loading: true,
                selectList: [],
                dialogFormVisible: false,
                form: {
                    account: '',
                    nickname: '',
                    password: "",
                    status:1
                },
                formLabelWidth: '100px',
                tableData: [],
                searchCon: [
                    {
                        key: "searchKey",
                        val: '',
                        pla: '请输入会员ID/手机号/昵称',
                        label: '请输入会员ID/手机号',
                        labelFlag: false
                    },
                ],
                pagination: {
                    currentPage: 1,
                    pageSize: 20,
                    total: 0
                },
                dataRange: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
            }
        },
        watch: {
            dialogFormVisible(newVal, oldVal) {
                if (!newVal && this.popupType != 'add') { //新增窗口 &&  弹窗关闭状态
                    this.form = {account: "", nickname: "", password: "",}
                }
            },
            dataRange(newVal, oldVal) {
                this.pagination.pageSize = 20
                this.pagination.currentPage = 1
                this.init()
            }
        },
        computed: {
            apiParams() {
                return {
                    type: this.type,
                    pageSize: this.pagination.pageSize,
                    pageNum: this.pagination.currentPage,
                    nickname: this.searchNickname,
                    searchKey: this.search,
                    acct_type: this.account_type,
                    startTime: this.dataRange && this.dataRange[0] ? this.dataRange[0] : '',
                    endTime: this.dataRange && this.dataRange[1] ? this.dataRange[1] : '',
                }
            },
            isAdmin() {
                return this.$store.state.userInfo.account === 'admin'
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            async init() {
                this.loading = true
                try {
                    let {data} = await getMemberList(this.apiParams)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        if (data.rows && data.rows.length) {
                            this.tableData = JSON.parse(JSON.stringify(data.rows))
                        } else {
                            this.tableData = []
                        }
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            add() {
                this.dialogFormVisible = true
                this.popupType = "add"
                this.form.status = 1
            },
            handleEdit(index, row) {
                this.currentId = row.member_id
                this.dialogFormVisible = true
                this.popupType = "edit"
                this.form = {
                    account: row.account,
                    nickname: row.nickname,
                    password: row.password,
                    status: row.status,
                    super_manage: row.super_manage,
                    test_flag: row.test_flag
                }
            },
            handleDel(row) {
                this.$confirm(`此操作将永久删除会员【${row.account?row.account:row.nickname}】, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    let {data} = await deleteMember({member_id: row.member_id})
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialogFormVisible = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                })
            },
            async submit() {
                if (this.popupType === 'add') {
                    let dataJson = {
                        account: this.form.account,
                        nickname: this.form.nickname,
                        password: this.form.password,
                        status: this.form.status,
                        super_manage: this.form.super_manage,
                        test_flag: this.form.test_flag
                    }
                    let {data} = await addMember(dataJson)
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialogFormVisible = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                } else {
                    let dataJson = {
                        member_id: this.currentId,
                        nickname: this.form.nickname,
                        password: this.form.password,
                        status: this.form.status,
                        super_manage: this.form.super_manage,
                        test_flag: this.form.test_flag
                    }
                    let {data} = await updateMember(dataJson)
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialogFormVisible = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            async changeSwitch(item) {
                let msg = ''
                let type = 'success'
                let {data} = await updateMember({member_id: item.member_id, status: item.status == 1 ? 2 : 1})
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.addDialog = false
                    await this.init()
                } else {
                    msg = data.msg
                    type = 'warning'
                }
            },
            async setSuper(item) {
                if (item.super_manage == 1) {
                    await siteManage({member_id: item.member_id}).then(res => {
                        if (res.data.code == 200) {
                            this.init()
                        }
                    })
                } else {
                    await cancelManage({member_id: item.member_id}).then(res => {
                        if (res.data.code == 200) {
                            this.init()
                        }
                    })
                }
            },
            searchData() {
                this.pagination.pageSize = 20
                this.pagination.currentPage = 1
                this.init()
            },
            async testFlagSwitch (row) {
                const { data } = await editTestFlag({member_id: row.member_id, test_flag: row.test_flag})
                if (data.code === statusCode.success) {
                    this.$message({message: '操作成功', type: 'success'})
                    this.init()
                } else {
                    this.$message({message: data.msg, type: 'warning'})
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    #customerList {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .filter-box {
            margin-bottom: 20px;
        }

        .pagination {
            margin-top: 10px;
        }

        .table {
            height: calc(100% - 100px);
        }
    }

    .btnAdd {
        margin-left: 15px;
    }

    /deep/ .el-table .descending .sort-caret.descending {
        border-top-color: #189e90;
    }

    /deep/ .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #189e90;
    }


    /deep/ .el-table tr {
        cellspacing: 10px;
        border: 1px solid #CCCCCC;
        padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        //border-spacing: 0 10px;
        background-color: #f6f6f6;
    }


    /deep/ .el-table th.el-table__cell > .cell {
        color: #6d6d6e;
        font-size: 14px;
    }


    /deep/ .el-dialog { //弹窗
        width: 600px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__header {
        background-color: #0E3F6A;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-form-item__label {
        color: #000;
        font-size: 14px;
    }

    /deep/ .el-input__inner {
        border-radius: 5px;
        border: 1px solid #B5B5B5;
    }
</style>
