import {Message} from "element-ui";

export function isRequire(label) {
    return (value) => {
        const message = value ? null : label + errorMessage.isRequire
        return {
            type: 'isRequire',
            message
        }
    }

}

export function isIp(value) {
    const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    const message = reg.test(value) ? null : 'ip格式错误'
    console.log(value, 'value', message)

    return {
        type: 'isIp',
        message
    }
}


export function Copy(copyValue) {  //复制
    const domUrl = document.createElement('input')
    domUrl.value = copyValue
    domUrl.id = 'creatDom'
    document.body.appendChild(domUrl)
    domUrl.select() // 选择对象
    document.execCommand('Copy') // 执行浏览器复制命令
    const creatDom = document.getElementById('creatDom')
    creatDom.parentNode.removeChild(creatDom)
    Message({
        type: 'success',
        message: '复制成功',
        duration: 1000
    })
}

export const errorMessage = {
    isRequire: '不能为空',
}
