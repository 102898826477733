<template>
    <div id="customerList">
        <div class="filter-box" style="display: flex">
            <SearchInput
                :search.sync="search"
                placeholder="渠道编号"
                @search="searchData"
            />
            <el-date-picker
                format="yyyy-MM-dd"
                v-model="dataRange"
                type="daterange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
            </el-date-picker>
            <div style="padding: 10px">默认展示今日数据</div>
        </div>
        <div class="table">
            <el-table
                :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                v-loading="loading"
                ref="multipleTable"
                tooltip-effect="dark"
                :data="tableData"
                height="100%"
                style="width: 100%">
                <el-table-column type="index" width="80" align="center" label="序号"></el-table-column>
                <el-table-column prop="channel" show-overflow-tooltip align="center" label="渠道编号"></el-table-column>
                <el-table-column prop="channelNum" show-overflow-tooltip align="center" label="安装量"></el-table-column>
                <el-table-column prop="regMember" show-overflow-tooltip align="center" label="注册量"></el-table-column>
                <el-table-column prop="1day" show-overflow-tooltip align="center" label="1日后留存数（启动完第二天再次启动的）"></el-table-column>
                <el-table-column prop="7day" show-overflow-tooltip align="center" label="7日后留存数（大于＞7小于＜30）"></el-table-column>
                <el-table-column prop="30day" show-overflow-tooltip align="center" label="30日后留存数（大于＞30日启动的）"></el-table-column>
                <el-table-column prop="memberNum" show-overflow-tooltip align="center" label="累计用户数"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import {statisticalIndex, addMember, updateMember, deleteMember, siteManage, cancelManage} from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import InpSearch from "@components/common/inpSearch";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";
    import CommonButton from "@components/common/CommonButton";

    export default {
        components: {
            InpSearch,
            SearchInput,
            Pagination,
            CommonButton
        },
        data() {
            return {
                search: '',
                total: 0,
                currentId: '',
                popupType: 'add',    //add || edit
                loading: true,
                selectList: [],
                dialogFormVisible: false,
                form: {
                    account: '',
                    nickname: '',
                    password: "",
                },
                formLabelWidth: '100px',
                tableData: [],
                searchCon: [],
                pagination: {
                    currentPage: 1,
                    pageSize: 200,
                    total: 0
                },
                dataRange: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
            }
        },
        watch: {
            dialogFormVisible(newVal, oldVal) {
                if (!newVal && this.popupType != 'add') { //新增窗口 &&  弹窗关闭状态
                    this.form = {account: "", nickname: "", password: "",}
                }
            },
            dataRange(newVal, oldVal) {
                this.pagination.pageSize = 20
                this.pagination.currentPage = 1
                this.init()
            }
        },
        computed: {
            apiParams() {
                return {
                    pageSize: this.pagination.pageSize,
                    pageNum: this.pagination.currentPage,
                    searchKey: this.search,
                    startTime: this.dataRange[0] ? this.dataRange[0] : '',
                    endTime: this.dataRange[1] ? this.dataRange[1] : '',
                }
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            async init() {
                this.loading = true
                try {
                    let {data} = await statisticalIndex(this.apiParams)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        if (data.data && data.data.length) {
                            this.tableData = JSON.parse(JSON.stringify(data.data))
                        }else{
                            this.tableData = []
                        }
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            add() {
                this.dialogFormVisible = true
                this.popupType = "add"
            },
            handleEdit(index, row) {
                this.currentId = row.member_id
                this.dialogFormVisible = true
                this.popupType = "edit"
                this.form = {
                    account: row.account,
                    nickname: row.nickname,
                    password: row.password,
                }
            },
            handleDelete(index, row) {
                this.$confirm(`此操作将永久删除会员【${row.account}】, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    let {data} = await deleteMember({member_id: row.member_id})
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialogFormVisible = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                })
            },
            async submit() {
                if (this.popupType === 'add') {
                    let dataJson = {
                        account: this.form.account,
                        nickname: this.form.nickname,
                        password: this.form.password
                    }
                    let {data} = await addMember(dataJson)
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialogFormVisible = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                } else {
                    let dataJson = {
                        member_id: this.currentId,
                        nickname: this.form.nickname,
                        password: this.form.password
                    }
                    let {data} = await updateMember(dataJson)
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialogFormVisible = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            async changeSwitch(item) {
                let msg = ''
                let type = 'success'
                let {data} = await updateMember({member_id: item.member_id, status: item.status == 1 ? 2 : 1})
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.addDialog = false
                    await this.init()
                } else {
                    msg = data.msg
                    type = 'warning'
                }
            },
            async setSuper(item) {
                if (item.super_manage == 1) {
                    await siteManage({member_id: item.member_id}).then(res => {
                        if (res.data.code == 200) {
                            this.init()
                        }
                    })
                } else {
                    await cancelManage({member_id: item.member_id}).then(res => {
                        if (res.data.code == 200) {
                            this.init()
                        }
                    })
                }
            },
            searchData() {
                this.pagination.pageSize = 200
                this.pagination.currentPage = 1
                this.init()
            },
        }
    }
</script>

<style scoped lang="scss">
    #customerList {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .filter-box {
            margin-bottom: 20px;
        }

        .pagination {
            margin-top: 10px;
        }

        .table {
            height: calc(100% - 100px);
        }
    }

    .btnAdd {
        margin-left: 15px;
    }

    /deep/ .el-table .descending .sort-caret.descending {
        border-top-color: #189e90;
    }

    /deep/ .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #189e90;
    }


    /deep/ .el-table tr {
        cellspacing: 10px;
        border: 1px solid #CCCCCC;
        padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        //border-spacing: 0 10px;
        background-color: #f6f6f6;
    }


    /deep/ .el-table th.el-table__cell > .cell {
        color: #6d6d6e;
        font-size: 14px;
    }


    /deep/ .el-dialog { //弹窗
        width: 600px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__header {
        background-color: #0E3F6A;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-form-item__label {
        color: #000;
        font-size: 14px;
    }

    /deep/ .el-input__inner {
        border: 0;
        border-radius: 5px;
        border: 1px solid #B5B5B5;
    }
</style>
