<template>
    <div class="navBox" v-if="$route.path !== '/'">
        <div class="logo p-t-20 p-b-20 p-l-15 p-r-15 flex flex-column align-center justify-center">
            <img :src="logoUrl" alt="">
            <span class="title  font-12 ">播球·管理系统</span>
            <div class="divider m-t-30 w-100"></div>
        </div>
        <div class="navBottom p-l-10 p-r-10">
            <el-menu
                    unique-opened
                router
                :collapse="isCollapse"
                :default-active="active"
                class="el-menu-vertical-demo nav-menu w-100"
                text-color="#fff"
                active-text-color="#ffd04b"
                @open="handleOpen"
                @close="handleClose"
            >
              <template v-for="(item,i) in menu">
                <el-submenu v-if="item.route.length" :index="String(i + 1)" :key="i">
                  <template slot="title">
                    <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                  </template>
                  <template v-if="item.route.length">
                    <el-menu-item v-for="(tem,n) in item.route" :index="tem.path" :key="n">
                      <div @click="getMenu(tem.txt)">{{ tem.txt }}</div>
                    </el-menu-item>
                  </template>
                </el-submenu>
                <el-menu-item v-else :index="item.path" :key="i" >
                  <div @click="firstMenu(item)">
                    <i :class="item.icon"></i>
                    <span  slot="title">{{ item.title }}</span>
                  </div>
                </el-menu-item>
              </template>

            </el-menu>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NavBar",
        data() {
            return {
                menu: [
                    {
                        title: "首页",
                        icon: 'el-icon-s-data',
                        name: 'Home',
                        path: '/home', txt: '首页',
                        route: []
                    },
                    {
                        title: "账户管理",
                        icon: 'el-icon-user-solid',
                        route: [
                            {path: '/memberList', txt: '会员管理'},
                            {path: '/anchorList', txt: '主播管理'},
                            {path: '/whiteList', txt: '白名单'},
                            {path: '/realNameList', txt: '实名验证'},
                            {path: '/authentication', txt: '身份验证'},
                        ]
                    },
                    {
                        title: "直播管理",
                        icon: 'el-icon-video-camera-solid',
                        route: [
                            {path: '/livingList', txt: '直播列表'},
                            {path: '/liveOrder', txt: '预约直播'},
                            {path: '/liveRecord', txt: '直播记录'},
                            {path: '/forbid', txt: '禁言列表'},
                        ]
                    },
                    {
                        title: "赛事管理",
                        icon: 'el-icon-s-grid',
                        route: [
                            {path: '/leagueList', txt: '足球联赛'},
                            {path: '/matchList', txt: '足球赛事'},
                            {path: '/leagueListB', txt: '篮球联赛'},
                            {path: '/matchListB', txt: '篮球赛事'},
                        ]
                    },
                    // {
                    //     title: "内容管理",
                    //     icon: 'el-icon-s-order',
                    //     route: [
                    //         {path: '/content_1', txt: '关于我们'},
                    //         {path: '/content_2', txt: '用户协议'},
                    //         {path: '/content_3', txt: '隐私政策'},
                    //         {path: '/content_4', txt: '联系我们'},
                    //         {path: '/content_5', txt: '开播教程'},
                    //         {path: '/content_6', txt: '直播规范'},
                    //         {path: '/content_7', txt: '开播工具'},
                    //         {path: '/content_8', txt: '直播协议'},
                    //         {path: '/content_9', txt: '注销协议'},
                    //     ]
                    // },
                    {
                      title: "内容管理", // 原：协议管理
                      icon: 'el-icon-s-order',
                      route: [
                        {path: '/contentEdit/list', txt: '协议管理'}, //原：公告管理
                        {path: '/code', txt: '验证码管理'},
                      ]
                    },
                    {
                      title: "快捷发言",
                      icon: 'el-icon-s-grid',
                      route: [
                        {path: '/quick-speech/common', txt: '普通用户'},
                        {path: '/quick-speech/super', txt: '超级管理员'}
                      ]
                    },
                    {
                      title: "有料管理",
                      icon: 'el-icon-s-help',
                      route: [
                        {path: '/expertList', txt: '专家列表'},
                        {path: '/haveList', txt: '有料列表'},
                        {path: '/haveAudit', txt: '有料审核'},
                        {path: '/haveSetting', txt: '有料设置'}
                      ]
                    },
                    {
                      title: "支付管理", // 原：充值管理
                      icon: 'el-icon-s-claim',
                      route: [
                        {path: '/topUpRecord', txt: '充值记录'},
                        {path: '/consumerRecord', txt: '礼物消费记录'}, // 原：消费记录
                        {path: '/haveDetails', txt: '有料消费记录'}, // 原：有料管理-》有料明细
                        {path: '/sifangPayOrder', txt: '四方订单'}, // 原：四方支付订单
                        {path: '/paymentChannel', txt: '支付渠道'},
                        {path: '/cashAudit', txt: '提现审核'}, // 原：有料管理-》提现审核
                        {path: '/topUpSetting', txt: '充值设置'},
                        // {path: '/manualRechar', txt: '人工充值'},  //只对admin开放
                        {path: '/billing', txt: '用户流水'}
                      ]
                    },
                    {
                      title: "礼物管理",
                      icon: 'el-icon-s-goods',
                      route: [
                        {path: '/giftList', txt: '礼物列表'}
                      ]
                    },
                    {
                        title: "渠道管理",
                        icon: 'el-icon-s-marketing',
                        route: [
                            {path: '/channel', txt: '渠道统计'},
                            {path: '/channelMonth', txt: '渠道月数据'},
                        ]
                    },
                    {
                        title: "系统设置",
                        icon: 'el-icon-s-tools',
                        route: [
                            {path: '/appSet', txt: 'App配置'},
                            {path: '/webSet', txt: '审核开关'},
                            {path: '/andVersion', txt: '安卓版本'},
                            {path: '/iosVersion', txt: 'IOS版本'},
                            {path: '/dangerManageList', txt: '风控管理'},
                            {path: '/advList', txt: '广告管理'},
                            {path: '/announceManage', txt: '公告管理'},
                            {path: '/announceManageByRoom', txt: '直播公告管理'},
                            {path: '/information', txt: '资讯管理'},
                            // {path: '/marquee',txt: '跑马灯公告'}
                            // {path: '/downloadSet', txt: '下载管理'},
                            // {path: '/greenBroadList', txt: '绿播开关'},
                        ]
                    },
                ],
                active: '/home',
                isCollapse: false,
                logoUrl: require('../../assets/images/common/bq-logo.png')
            }
        },
      watch: {
          '$route': {
              handler() {
                this.active = this.$route.path
              },
            immediate: true,
            deep: true
          }
      },
        created() {
            if(this.userInfo && this.userInfo.account == 'admin'){
                this.menu.map(item => {
                    if (item.title === '账户管理') {
                        item.route.unshift({path: '/admin', txt: '管理员'})
                    }
                    if (item.title === '支付管理') {
                        item.route.push( {path: '/manualRechar', txt: '人工充值'}  )
                    }
                })
            }
        },
        computed: {
            // 菜单栏
            userInfo() {
                return this.$store.state.userInfo
            },
        },
        methods: {
          firstMenu(item) {
            if (item.name !== 'Home') {
              // 首页 不管
              this.$store.commit('menuList', item)
            }
          },
            getMenu(txt) {
                let single = null
                this.menu.forEach((item, i) => {
                    //  未处理 一级菜单的情况
                    item.route.forEach((tem, n) => {
                        if (tem.txt === txt) {
                            single = tem
                        }
                    })
                })
                this.$store.commit('menuList', single)
            },
            handleOpen(key, keyPath) {
                // console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                // console.log(key, keyPath);
            }
        }
    }
</script>

<style lang="scss" scoped>
  .logo {
    img {
      width: 75px;
      height: 75px
    }
    .title {
      line-height: 19px;
      color:#fff;
    }
    .divider {
      height: 1px;
      background-color: rgba(0,0,0,0.2);
    }
  }
    .navBox {
      width: 210px;
      background: linear-gradient(180deg, #6F4E5E 0%, #161147 100%);
    }
    .navBox, .navBottom {
        height: 100%;
    }
    .navBottom {
      height:calc(100% - 165px);
    }

    .navBottom {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .navBottom::-webkit-scrollbar {
        width: 4px;
    }
    ::v-deep {
      .navBottom {
        .el-menu {
          border-right: 0px!important;
        }
      }
    }
</style>
