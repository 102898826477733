<template>
    <div class="page-box">
        <div class="filter-box" style="display: flex">
            <CommonButton @click.native="addAdmin" class="btnAdd" title="新增广告+"/>
        </div>
        <div class="table">
            <el-table :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                      v-loading="loading" ref="multipleTable" tooltip-effect="dark" :data="tableData" border
                      height="100%"
                      style="width: 100%">
                <el-table-column align="center" type="index" label="序号" width="60"></el-table-column>
                <el-table-column align="center" prop="title" label="广告标题"></el-table-column>
                <el-table-column align="center" prop="pic_url" label="广告图片">
                    <template slot-scope="scope">
                        <el-image
                            style="max-width: 32px;max-height: 32px"
                            :src="scope.row.pic_url"
                            :preview-src-list="[scope.row.pic,scope.row.pic_url]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="device" label="设备"></el-table-column>
                <el-table-column align="center" prop="sort" label="排序"></el-table-column>
                <el-table-column align="center" prop="adv_type" label="广告类型"></el-table-column>
                <!--跳转方式 1无  2内跳 3外跳  -->
                <el-table-column align="center" prop="linkType" label="跳转方式">
                    <template slot-scope="scope">
                        <div>{{ ['', '无', '内跳', '外跳'][scope.row.type] }}</div>
                    </template>
                </el-table-column>
                <table-one-empty align="center" show-overflow-tooltip prop="links" label="跳转地址"></table-one-empty>
                <table-one-empty align="center" prop="create_by" label="操作人"></table-one-empty>
                <table-one-empty align="center" prop="remark" label="备注"></table-one-empty>
                <el-table-column align="center" prop="status" label="状态" width="140">
                    <template slot-scope="scope">
                      <status-button
                          :tips="['启用', '禁用']"
                          target="该广告"
                          :value="scope.row.status == 1"
                          @change="changeSwitch(scope.row)"
                      />
                    </template>
                </el-table-column>
                <el-table-column align="center" width="160" label="操作">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.$index, scope.row)" type="primary"
                                   >编辑</el-button>
                        <el-button type="danger" @click="handleDelete(scope.$index, scope.row)"
                                   >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="pagination block">
            <pagination
                :current-page.sync="pagination.currentPage"
                :page-size.sync="pagination.pageSize"
                :total="pagination.total"
                @loadData="init"
            />
        </div>
        <el-dialog :close-on-click-modal="false" title="新增/编辑" :visible.sync="dialog">
            <el-form ref="ruleForm" :model="formItem" label-width="80px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="设备">
                            <el-checkbox-group v-model="device">
                                <el-checkbox label="PC">PC</el-checkbox>
                                <el-checkbox label="H5">H5</el-checkbox>
                                <el-checkbox label="Android">Android</el-checkbox>
                                <el-checkbox label="IOS">IOS</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-col :span="16">
                            <el-form-item label="广告位置">
                                <el-input v-model="formItem.adv_type"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="16">
                            <el-form-item label="广告标题">
                                <el-input v-model="formItem.title"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col>
                        <el-col :span="16">
                            <el-form-item label="备注">
                                <el-input type="textarea" v-model="formItem.remark"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="链接地址">
                            <el-input v-model="formItem.links"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col>
                        <el-col :span="8">
                            <el-form-item label="排序">
                                <el-input v-model="formItem.sort"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="跳转方式">
                            <el-radio-group v-model="formItem.type">
                                <el-radio :label="2">内跳</el-radio>
                                <el-radio :label="3">外跳</el-radio>
                                <el-radio :label="1">无跳转</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="广告图片">
                            <el-upload
                                class="avatar-uploader"
                                :action="apiUrl + '/common/upload/uploadImg'"
                                :show-file-list="false"
                                :data="{type: 'banner'}"
                                :on-success="handleAvatarSuccess">
                                <img v-if="formItem.pic_url" :src="formItem.pic_url" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <img class="upload_img_adv" :src="formItem.pic" alt="">
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">提交</el-button>
                            <el-button @click="dialog = false">取消</el-button>
                            <el-button @click="resetForm()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {getAdvList, editAdv, deleteAdv, addAdv} from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";
    import CommonButton from  "@components/common/CommonButton";
    import TableOneEmpty from "@components/common/tableOneEmpty"
    export default {
        components: {SearchInput, Pagination,CommonButton,TableOneEmpty},
        data() {
            return {
                pageSize: 10,
                pageNum: 1,
                total: 0,
                apiUrl,
                pic_url: "",
                type: 'edit',  //edit add
                loading: true,
                tableData: [],
                isEditTxt: "详情/编辑",
                currentId: '',
                dialog: false,
                formItem: {
                    title: '',
                    pic: '',
                    equipment: '',
                    linkType: '',
                    linkAddress: '',
                    remark: '',
                    status: '',
                    sort: '',
                    pic_url: '',
                    device: '',
                    adv_type: '',
                    links: '',
                    type: null
                },
                device: [],
                pagination: {
                    currentPage: 1,
                    pageSize: 20,
                    total: 0
                },
            }
        },
        watch: {
            type(newVal, old) {
                if (newVal === 'add') this.resetForm()
            }
        },
        computed: {
            apiParams() {
                return {
                    pageSize: this.pagination.pageSize,
                    pageNum: this.pagination.currentPage,
                }
            }
        },
        created() {
            this.init()
        },
        methods: {
            async init() {
                this.loading = true
                try {
                    let {data} = await getAdvList(this.apiParams)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        this.tableData = JSON.parse(JSON.stringify(data.rows))
                        this.loading = false
                    }
                } catch (e) {
                    console.log(e)
                }
            },
            handleAvatarSuccess(res) {
                this.pic_url = res.data.url;
                this.formItem.pic_url = this.pic_url
            },
            addAdmin() {
                this.dialog = true
                this.type = 'add'
            },
            setSellStyle({row, column, rowIndex, columnIndex}) {
                if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
                if (columnIndex == 7) return "borderRadius: 0  10px 10px 0"
            },
            handleEdit(index, row) {
                this.device = row.device && row.device.split(',')
                this.type = 'edit'
                this.dialog = true
                this.currentId = this.tableData[index].id
                for (let k in row) {
                    for (let key in this.formItem) {
                        if (k === key) {
                            if (this.type === 'edit') this.formItem[key] = row[k]
                        }
                    }
                }
            },
            handleDelete(index, row) {
                this.$confirm(`此操作将永久删除广告【${row.title}】, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteAdv({id: row.id}).then(res => {
                        if (res.data.code === statusCode.success) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.init()
                        }
                    })
                })
            },
            async onSubmit() {
                this.formItem.device = this.device && this.device.join(',')
                this.formItem.status = this.formItem.status ? 1 : 2
                if (this.type === 'edit') {
                    let {data} = await editAdv(Object.assign(this.formItem, {id: this.currentId}))
                    if (data.code == statusCode.success) {
                        this.dialog = false
                        await this.init()
                    }
                    this.$message({
                        type: data.code == statusCode.success ? "success" : "warning",
                        message: data.msg,
                    })
                } else {
                    let {data} = await addAdv(Object.assign(this.formItem))
                    if (data.code == statusCode.success) {
                        this.dialog = false
                        this.formItem = {
                            title: '',
                            pic: '',
                            equipment: '',
                            linkType: '',
                            linkAddress: '',
                            remark: '',
                            status: '',
                            sort: '',
                            pic_url: '',
                            device: '',
                            adv_type: '',
                            links: '',
                            type: null
                        }
                        this.device = []
                        await this.init()
                    }
                    this.$message({
                        type: data.code == statusCode.success ? "success" : "warning",
                        message: data.msg,
                    })
                }
            },
            resetForm() {
                this.device = []
                for (let key in this.formItem) {
                    if (typeof this.formItem[key] !== "boolean") this.formItem[key] = ''
                }
                this.formItem.type = 1
            },
            async changeSwitch(item) {
                await editAdv({id: item.id, status: item.status == 1 ? 2 : 1}).then(res => {
                    if (res.data.code == statusCode.success) this.init()
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .page-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .filter-box {
            margin-bottom: 20px;
        }

        .pagination {
            margin-top: 10px;
        }

        .table {
            height: calc(100% - 100px);
        }
    }

    .editBtn {
        color: #1e82d2;
    }

    .popImg {
        width: 50%;

        img {
            width: 100%;
        }
    }

    .popImgSee {
        width: 100%;
    }

    .upload_img_adv {
        width: 200px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    /deep/ .el-table tr {
        //cellspacing: 10px;
        border: 1px solid #cccccc;
        //padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        //border-spacing: 0 10px;
        background-color: #f6f6f6;
    }

    /deep/ .el-dialog {
        //弹窗
        border-radius: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-dialog__header {
        background-color: #189e90;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }
</style>
