<template>
    <div class="search">
        <div class="inp" v-for="(item,i) in arr" style="display:flex">
            <div class="title" v-if="item.labelFlag">{{ item.label }}:</div>
            <el-input
                style="width: auto"
                class="inp"
                v-model="item.val"
                :placeholder="item.pla" :key="i"></el-input>
        </div>
        <button class="btnSearch" @click="search">搜索</button>
    </div>
</template>

<script>
    export default {
        props: {
            inpList: {
                type: [Array, Object],
                default: function () {
                    return []
                }
            }
        },
        data() {
            return {
                arr: null,
            }
        },
        created() {
            this.arr = this.inpList
        },
        methods: {
            search() {
                let dataJson = {}
                this.arr.forEach((item)=>{
                    let key = item.key
                    dataJson[key] = item.val
                })
                this.$emit('search',dataJson)
            }
        }
    }
</script>

<style scoped lang="scss">
    .search {
        display: flex;
    }

    .inp {
        margin: 0 10px 2px 0;
        .title{
           line-height: 40px;
            font-size: 14px;
            padding-right: 5px;
        }
    }
</style>
