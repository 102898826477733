<template>
    <div class="page-box">
        <div class="filter-box" style="display: flex">
            <CommonButton @click.native="handleEdit(null, null, 'add')" class="btnAdd" title="新增公告+" />
        </div>
        <div class="table">
            <el-table :row-style="{ height: '40px' }" :header-row-style="{ height: '40px' }" border v-loading="loading"
                ref="multipleTable" tooltip-effect="dark" :data="tableData" height="100%" style="width: 100%">
                <el-table-column align="center" type="index" label="序号" width="60"></el-table-column>
                <el-table-column align="center" prop="title" label="标题"></el-table-column>
                <el-table-column align="center" prop="jump_type" label="跳转方式">
                    <template slot-scope="scope">
                        {{ scope.row.jump_type === 1 ? '内跳': '外跳'}}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="links" label="链接地址"></el-table-column>
                <el-table-column align="center" prop="type" label="类型" width="80">
                    <template slot-scope="scope">
                        {{ scope.row.type === 1 ? '全局' : '直播间' }}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="PC" label="PC" width="80">
                    <template slot-scope="scope">
                        <status-button :tips="['启用', '禁用']" :value="scope.row.PC == 1"
                            @change="changeSwitch(scope.row,'PC')" />
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="H5" label="H5" width="80">
                    <template slot-scope="scope">
                        <status-button :tips="['启用', '禁用']" :value="scope.row.H5 == 1"
                            @change="changeSwitch(scope.row,'H5')" />
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="IOS" label="IOS" width="80">
                    <template slot-scope="scope">
                        <status-button :tips="['启用', '禁用']" :value="scope.row.IOS == 1"
                            @change="changeSwitch(scope.row,'IOS')" />
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Android" label="Android" width="80">
                    <template slot-scope="scope">
                        <status-button :tips="['启用', '禁用']" :value="scope.row.Android == 1"
                            @change="changeSwitch(scope.row,'Android')" />
                    </template>
                </el-table-column>
                <table-one-empty align="center" prop="remark" label="备注"></table-one-empty>
                <el-table-column align="center" prop="update_by" label="操作人" width="120"></el-table-column>
                <el-table-column align="center" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.$index, scope.row, 'edit')" type="primary">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="pagination block">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div>
        <el-dialog :close-on-click-modal="false" title="新增/编辑 公告" :visible.sync="dialog" width="40%">
            <el-form ref="ruleForm" :model="formItem" label-width="80px">
                <el-row>
                    <el-col :span="13">
                        <el-form-item label="标题">
                            <el-input v-model="formItem.title"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="跳转方式">
                            <el-radio-group v-model="formItem.jump_type">
                                <el-radio :label="1">内跳</el-radio>
                                <el-radio :label="2">外跳</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="13">
                        <el-form-item label="类型">
                            <el-radio-group v-model="formItem.type">
                                <el-radio :label="1">全局</el-radio>
                                <el-radio :label="2">直播间</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="13">
                        <el-form-item label="链接地址">
                            <el-input v-model="formItem.links"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="设备">
                            <el-checkbox v-model="formItem.PC" label="PC"></el-checkbox>
                            <el-checkbox v-model="formItem.H5" label="H5"></el-checkbox>
                            <el-checkbox v-model="formItem.IOS" label="IOS"></el-checkbox>
                            <el-checkbox v-model="formItem.Android" label="Android"></el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="说明">
                            <el-input v-model="formItem.remark"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="公告内容">
                            <el-input type="textarea" v-model="formItem.content"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">提交</el-button>
                            <el-button @click="dialog = false">取消</el-button>
                            <el-button @click="resetForm()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { getAnnounce, editAnnounce, addAnnounce, announceEditStatus, } from "@/api/control";
import { statusCode } from "@/util/statusCode";
import SearchInput from "@components/common/SearchInput";
import Pagination from "@/pages/Layout/Pagination";
import CommonButton from "@components/common/CommonButton";
import TableOneEmpty from "@components/common/tableOneEmpty";
export default {
    components: { SearchInput, Pagination, CommonButton, TableOneEmpty },
    props: {
        adminInfo: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {
            pageSize: 10,
            pageNum: 1,
            total: 0,
            loading: true,
            tableData: [],
            isEditTxt: "详情/编辑",
            type: "add", //add edit
            currentId: '',
            dialog: false,
            formItem: {
                id:"",
                title: '',
                type: '',
                remark: '',
                content: '',
                jump_type: '',
                links: "",
                PC:false, // 1 正常/ 2禁用
                H5:false,
                IOS:false,
                Android:false
            },
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
        }
    },
    computed: {
        apiParams() {
            return {
                pageSize: this.pagination.pageSize,
                pageNum: this.pagination.currentPage,
            }
        }
    },
    created() {
        this.init()
    },
    methods: {
        async init() {
            this.loading = true
            try {
                let { data } = await getAnnounce(this.apiParams)
                if (data.code === statusCode.success) {
                    this.total = data.total
                    this.pagination.total = data.total
                    this.tableData = JSON.parse(JSON.stringify(data.rows))
                    this.loading = false
                }
            } catch (e) {
                console.log('error--error')
            }
        },
        handleEdit(index, row, type) {  //1 新增   2 编辑
            this.type = type
            if (type === 'edit') {
                this.formItem.id = row.id
                this.formItem.title = row.title
                this.formItem.jump_type = row.jump_type
                this.formItem.type = row.type
                this.formItem.links = row.links
                this.formItem.PC = row.PC === 1 ? true: false
                this.formItem.H5 = row.H5 === 1 ? true: false
                this.formItem.IOS = row.IOS === 1 ? true: false
                this.formItem.Android = row.Android === 1 ? true: false
                this.formItem.remark = row.remark,
                this.formItem.content = row.content
            } else if(type === 'add') {
                for (let key in this.formItem) {
                    this.formItem[key] = ""
                }
            }
            this.dialog = true
        },
        handleDelete(index, row) {
            this.$confirm(`此操作将永久删除公告【${row.title}】, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
            })
        },
        async onSubmit() {
            this.formItem.PC = this.formItem.PC ? 1 : 2
            this.formItem.H5 = this.formItem.H5 ? 1 : 2
            this.formItem.IOS = this.formItem.IOS ? 1 : 2
            this.formItem.Android = this.formItem.Android ? 1 : 2
            if (this.type === 'add') {
                let { data } = await addAnnounce(this.formItem)
                let msg = ''
                let type = 'success'
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.dialog = false
                    await this.init()
                } else {
                    msg = data.msg
                    type = 'warning'
                }
                this.$message({
                    message: msg,
                    type,
                    duration: 2000
                });
            } else {
                let { data } = await editAnnounce(this.formItem)
                let msg = ''
                let type = 'success'
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.dialog = false
                    await this.init()
                } else {
                    msg = data.msg
                    type = 'warning'
                }
                this.$message({
                    message: msg,
                    type,
                    duration: 2000
                });
            }
        },
        resetForm() {
            for (let key in this.formItem) {
                if (typeof this.formItem[key] !== "boolean") {
                    this.formItem[key] = ''
                } else {
                    this.formItem[key] = false
                }
            }
        },
        async changeSwitch(item,deviceType) {
            let dataJson = {
                PC:item.PC,
                H5:item.H5,
                IOS:item.IOS,
                Android:item.Android,
                id:item.id
            }
            dataJson[deviceType] === 1?dataJson[deviceType] = 2:dataJson[deviceType]=1
            let { data } = await editAnnounce({...dataJson})
            let msg = ''
            let type = 'success'
            if (data.code === statusCode.success) {
                msg = '操作成功'
                this.dialog = false
                await this.init()
            } else {
                msg = data.msg
                type = 'warning'
            }
            this.$message({
                message: msg,
                type,
                duration: 2000
            });
        }
    }
}
</script>

<style scoped lang="scss">
.page-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .filter-box {
        margin-bottom: 20px;
    }

    .pagination {
        margin-top: 10px;
    }

    .table {
        height: calc(100% - 100px);
    }
}

.editBtn {
    color: #1e82d2;
}

.popImg {
    width: 50%;

    img {
        width: 100%;
    }
}

.popImgSee {
    width: 100%;
}

/deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    //border: 0;
}

/deep/ .el-table tr {
    //cellspacing: 10px;
    border: 1px solid #cccccc;
    //padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}

/deep/ .el-dialog {
    //弹窗
    border-radius: 20px;
}

/deep/ .el-dialog__title {
    color: #fff;
}

/deep/ .el-dialog__header {
    background-color: #189e90;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
}

/deep/ .el-input__inner {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
}
</style>
