// import axios from "axios"
//
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
//
// // 请求拦截器
// axios.interceptors.request.use(function (config) {
//     return config;
// }, function (error) {
//     return Promise.reject(error);
// })
// // 响应拦截器
// axios.interceptors.response.use(function (response) {
//     return response;
// }, function (error) {
//     return Promise.reject(error);
// })
//
// // 封装axios的post请求
// export function fetch(url, params) {
//     return new Promise((resolve, reject) => {
//         axios.post(url, params)
//             .then(response => {
//                 resolve(response.data);
//             })
//             .catch((error) => {
//                 reject(error);
//             })
//     })
// }
import {Message} from "element-ui";
import router from '../router'
import axios from 'axios'
import {getToken, removeToken} from "../util/cookie"
import Store from '../vux/store'
const instance = axios.create({
    timeout: 10000,
    baseURL: apiUrl
})

const errorHandle = (error) => {
    console.log(error, '出错了')
    if (error.request.readyState === 4 && error.request.status === 0) { // 超时提示
        Message({
            message: 'api响应超时',
            type: 'error'
        })
    }
    return Promise.reject(error)
}

instance.interceptors.request.use(config => {
    const token = getToken()
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    // 请求拦截器
    return config
}, errorHandle)

instance.interceptors.request.use(config => {
    // 请求拦截器
    return config
}, errorHandle)

instance.interceptors.response.use(response => {
    // 响应拦截器
    if (response.data.code == 407) {
        Message(response.data.msg)
        removeToken()
        Store.commit('loginInfo', {token: null, userInfo: null})
        let timer = setTimeout(() => {
            clearTimeout(timer)
            router.push({
                name: 'Login'
            })
        }, 1000)
    }
    return response
}, errorHandle)

export default instance

