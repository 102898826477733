<template>
    <div id="common-upload flex">
        <template v-if="type !== 'gift'">
            <el-upload
            class="avatar-uploader"
            :action="apiUrl+uploadImage"
            :show-file-list="false"
            :data="{type: type}"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span slot="tip" class="tip">只能上传jpg/png格式，且不超过2MB</span>
            </el-upload>
        </template>
        <template v-else-if="type === 'gift'">
            <el-upload
            class="flex"
            :action="apiUrl+uploadImage"
            :show-file-list="false"
            :data="{type: type}"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            >
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传svga格式，且不超过2MB</div>
            </el-upload>
            <div style="width: 85px;" class="p-t-5">
                <CommonSvga v-if="svgaUrl" :url="svgaUrl" />
            </div>
        </template>
    </div>
</template>

<script>
import url from '@/api/Host/url'
import {statusCode} from '@/util/statusCode'
import CommonSvga from './CommonSvga.vue';
export default {
    components: {
        CommonSvga
    },
    props: {
        icon: { // url
            type: String,
            default: ''
        },
        type: { // 上传图片需要传的图片的类型, 需要展示svga必传gift,
            type: String,
            default: 'avatar'
        }
    },
    data() {
        return {
            imageUrl: '', // 显示的图片
            apiUrl,
            uploadImage: url.uploadImage,
            isSvga: false,
            svgaUrl: ''
        };
    },
    watch: {
        icon: {
            handler(newVal, oldVal) {
                console.log('newVal:',newVal)
                this.isSvga = newVal && newVal.split('.').reverse()[0] === 'svga'
                if (this.isSvga) {
                    this.svgaUrl = newVal // svga图片展示
                } else {
                    this.imageUrl = newVal // 普通图片展示
                }
            },
            immediate: true,
            // deep: true
        }
    },
    methods: {
        handleAvatarSuccess(res, file) {
            if (res.code === statusCode.success){
                this.imageUrl = URL.createObjectURL(file.raw);
                this.$emit('update:icon',res.data.url)
                this.svgaUrl = res.data.url
                this.$message.success(res.msg)
            } else {
                this.$message.error(res.msg)
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            this.isSvga = file.name.split('.').reverse()[0] === 'svga';
            const isLt2M = file.size / 1024 / 1024 < 2;   // 限制文件大小
            if (this.type !=='gift' && !isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
            }
            if (this.type === 'gift' && !this.isSvga) {
                this.$message.error('上传图片只能是 SVGA 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return (this.type === 'gift' ? this.isSvga : isJPG) && isLt2M;
        },
        submit() {
            console.log('执行')
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep{
    .avatar-uploader{
        display: flex;
    }
    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 85px;
    height: 85px;
    line-height: 85px;
    text-align: center;
  }
  .avatar {
    width: 85px;
    height: 85px;
    display: block;
  }
  .tip{
    font-size: 12px;
    padding-top: 25px;
    padding-left: 5px;
    color:#B5B5B5;
  }
  .el-upload__tip{
    margin-top: 0;
    padding-left: 5px;
    font-size: 12px;
    color:#B5B5B5;
  }
}
</style>