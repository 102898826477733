<template>
    <div class="admin-box">
        <div class="filter-box">
<!--            <button class="btnAdd" @click="handleEdit">新增管理员</button>-->
            <CommonButton @click.native="handleEdit" class="btnAdd" title="新增管理员"/>
        </div>
        <div class="table">
          <el-table :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                    v-loading="loading" ref="multipleTable" tooltip-effect="dark" :data="tableData"
                    height="100%"
                    align="center"
                    style="width: 100%;">
            <el-table-column type="index" width="80" align="center" label="序号"></el-table-column>
            <table-one-empty prop="account" align="center" show-overflow-tooltip label="管理员"></table-one-empty>
            <el-table-column prop="create_time" align="center" label="创建时间"></el-table-column>
            <table-one-empty prop="last_time" align="center" label="登录时间"></table-one-empty>
            <table-one-empty prop="last_ip" align="center" label="登录ip"></table-one-empty>
            <el-table-column prop="status" align="center" label="状态">
              <template slot-scope="scope">
                <status-button
                  :disabled="userInfo && userInfo.account !== 'admin'"
                  :tips="['启用', '禁用']"
                  target="管理员"
                  :value="scope.row.status == 1"
                  @change="changeSwitch(scope.row)"
                />
              </template>
            </el-table-column>
            <table-one-empty prop="last_ip" align="center" label="上一次登录IP"></table-one-empty>
            <table-one-empty prop="remark" align="center" label="备注"></table-one-empty>
            <table-one-empty prop="update_by" align="center" label="操作人"></table-one-empty>
            <el-table-column prop="username" align="center" label="操作">
              <template slot-scope="scope">
                <el-button :disabled="userInfo && userInfo.account !== 'admin'"
                           @click="handleEdit(scope.$index, scope.row)" type="primary"
                           >编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <pagination
              :current-page.sync="pagination.currentPage"
              :page-size.sync="pagination.pageSize"
              :total="pagination.total"
              @loadData="init"
          />
        </div>
        <el-dialog :close-on-click-modal="false" title="编辑/新增" :visible.sync="addDialog" width="20%">
            <el-form :model="form">
                <el-form-item label="账号">
                    <el-input :disabled="type ==='edit'?true :false" v-model="form.account"
                              placeholder="管理员账号"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="form.password" placeholder="管理员密码"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.remark" placeholder="备注"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit()">提交</el-button>
                <el-button @click="addDialog = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import {getAdminList, addAdmin, updatePwd, updateAdmin} from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import Pagination from "@/pages/Layout/Pagination";
    import CommonButton from "@components/common/CommonButton";
    import TableOneEmpty from "@/components/common/tableOneEmpty.vue";

    export default {
        name: 'admin',
      components: {
        Pagination,CommonButton,TableOneEmpty
      },
        data() {
            return {
                type: 'edit',   // edit add
                currentId: '',
                loading: true,
                value: true,
                tableData: [],
                isEditTxt: "详情/编辑",
                dialogFormVisible: false,
                isComAdminPop: false,
                addDialog: false,
                form: {
                    account: '',
                    password: '',
                    remark: '',
                },
                pagination: {
                  currentPage: 1,
                  pageSize: 20,
                  total: 0
                }
            }
        },
        created() {
            this.init()
        },
        computed: {
            ...mapState({
                userInfo: state => state.userInfo
            }),
        },

        methods: {
            async init() {
                this.loading = true
                try {
                    let {data} = await getAdminList(this.pagination.currentPage, this.pagination.pageSize)
                    if (data.code === statusCode.success) {
                        this.tableData = JSON.parse(JSON.stringify(data.rows))
                        this.loading = false


                        this.pagination.total = data.total
                        this.pagination.currentPage = data.current_page
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            setSellStyle({row, column, rowIndex, columnIndex}) {
                if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
                if (columnIndex == 7) return "borderRadius: 0  10px 10px 0"
            },
            handleEdit(index, row) {
                this.addDialog = true
                if (row) {
                    this.type = 'edit'
                    this.currentId = row.id
                    this.form.account = row.account
                    this.form.remark = row.remark
                } else {
                    this.type = 'add'
                    this.form = {}
                }

            },
            handleDelete(index, row) {
                this.$confirm(`此操作将永久删除管理员【${row.username}】, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                })
            },
            async submit() {
                if (this.type === 'add') {
                    let {data} = await addAdmin(this.form)
                    let msg = '操作成功',type = 'success'
                    if (data.code === statusCode.success) {
                        this.addDialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                } else {
                    let {data} = await updateAdmin(Object.assign({id: this.currentId}, this.form))
                    let msg = '操作成功',type = 'success'
                    if (data.code === statusCode.success) {
                        this.addDialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            async changeSwitch(item) {
                let msg = '操作成功',type = 'success'
                let sendJson = {
                    id: item.id,
                    status: item.status == 1 ? 2 : 1
                }
                let {data} = await updateAdmin(sendJson)
                if (data.code === statusCode.success) {
                    this.addDialog = false
                    await this.init()
                } else {
                    msg = data.msg
                    type = 'warning'
                }
                this.$message({
                    message: msg,
                    type,
                    duration: 2000
                });
            }
        }
    }
</script>

<style scoped lang="scss">
.admin-box {
  height: 100%;
  box-sizing: border-box;
  .filter-box {
    margin-bottom: 20px;
  }
  .pagination {
    margin-top: 10px;
  }
  .table {
    height: calc(100% - 100px);
  }
}
    .editBtn {
        color: #1e82d2;
    }

    .popImg {
        width: 50%;

        img {
            width: 100%;
        }
    }

    .popImgSee {
        width: 100%;
    }

    /deep/ .el-table tr {
        cellspacing: 10px;
        border: 1px solid #cccccc;
    }

    /deep/ .el-dialog {
        //弹窗
        width: 400px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-dialog__header {
        background-color: #0E3F6A;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

    /deep/ .el-input__inner {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #ccc;
    }
</style>
