import Vue from 'vue'
import store from "./vux/store"
import AppAdmin from "./App";
import router from "./router"
import "./mock"
import './style/spacing.scss'
import "./style/index.scss"

import { globalMixin } from '@/mixins/globalMixin'

import axios from "axios"
import VueAxios from "vue-axios";
import JsonExcel from 'vue-json-excel'
// import StatusButtonGroup from "@components/common/StatusButtonGroup";
import StatusButton from "@components/common/StatusButton";
Vue.component('downloadExcel', JsonExcel)
Vue.component('StatusButton', StatusButton)

Vue.use(VueAxios, axios)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI, { size: 'small' });  //全局按钮大小
ElementUI.TableColumn.props.showOverflowTooltip ={type:Boolean, default: true} //表格内容过长显示省略号
// ElementUI.TableColumn.style['header-cell-style']={background:'#409EFF'} //表格头部背景色


import './util/directives'

Vue.mixin(globalMixin)

Vue.use(ElementUI)
Vue.config.productionTip = false


window.vm =  new Vue({
    router,
    store,
    render: h => {
        return h(AppAdmin)
    }
}).$mount('#app')
