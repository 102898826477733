<template>
    <div class="admin-box">
        <div class="filter-box">
            <CommonButton @click.native="handleEdit" class="btnAdd" title="+添加"/>
            <!--            <SearchInput-->
            <!--                :search.sync="search"-->
            <!--                placeholder="标题"-->
            <!--                @search="searchData"-->
            <!--            />-->
            <el-select v-model="value" clearable placeholder="状态" @change="init">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="table">
            <el-table :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                      v-loading="loading" ref="multipleTable" tooltip-effect="dark" :data="tableData"
                      height="100%"
                      align="center"
                      style="width: 100%;">
                <el-table-column type="index" width="80" align="center" label="ID"></el-table-column>
<!--                <el-table-column prop="title" align="center" show-overflow-tooltip label="标题"></el-table-column>-->
                <el-table-column prop="version" align="center" label="版本号"></el-table-column>
                <el-table-column prop="name" align="center" label="版本名"></el-table-column>
                <el-table-column prop="link" align="center" label="下载地址"></el-table-column>
                <el-table-column prop="renew" align="center" label="文字"></el-table-column>
                <el-table-column prop="create_time" align="center" label="创建时间"></el-table-column>
                <el-table-column prop="is_forcibly" align="center" label="是否强更">
                    <template slot-scope="scope">
                        <status-button
                            active-label="是"
                            in-active-label="否"
                            :tips="['开启', '关闭']"
                            target="强更"
                            :value="scope.row.is_forcibly == 1"
                            @change="changeSwitch(scope.row,'is_forcibly',scope.row.is_forcibly == 1 ? 2 : 1)"
                        />
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="scope">
                        <status-button
                            :tips="['启用', '禁用']"
                            target="版本号"
                            :value="scope.row.status == 1"
                            @change="changeSwitch(scope.row,'status',scope.row.status == 1 ? 2 : 1)"
                        />
                    </template>
                </el-table-column>
                <el-table-column prop="username" min-width="120" align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button
                            @click="handleEdit(scope.$index, scope.row)" type="primary"
                            >编辑</el-button>
                        <el-button type="danger" @click="handleDelete(scope.$index, scope.row)"
                                   >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination block">
            <pagination
                :current-page.sync="pagination.pageNum"
                :page-size.sync="pagination.pageSize"
                :total="pagination.total"
                @loadData="init"
            />
        </div>
        <el-dialog :close-on-click-modal="false" title="编辑/新增" :visible.sync="addDialog">
            <el-form :model="form">
                <el-form-item label="标题">
                    <el-input v-model="form.title"
                              placeholder="标题"></el-input>
                </el-form-item>
                <el-form-item label="版本号">
                    <el-input v-model="form.version" placeholder="版本号"></el-input>
                </el-form-item>
                <el-form-item label="版本名">
                    <el-input v-model="form.name" placeholder="版本名"></el-input>
                </el-form-item>
                <el-form-item label="下载地址">
                    <el-input v-model="form.link" placeholder="下载地址"></el-input>
                </el-form-item>
                <el-form-item>
                  <div>
                    <div>
                      <editor-bar
                          class="editor"
                          v-model="form.renew">
                      </editor-bar>
                    </div>
                  </div>
<!--                    <el-input type="textarea" v-model="form.renew" placeholder="文字"></el-input>-->
                </el-form-item>
                <el-col :span="8">
                    <el-form-item label="是否强更">
                        <el-switch v-model="form.is_forcibly"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="状态">
                        <el-switch v-model="form.status"></el-switch>
                    </el-form-item>
                </el-col>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit('bySubmit')">提交</el-button>
                <el-button @click="addDialog = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import {versionAdd, versionLists, versionUpdate, versionDelete} from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import Pagination from "@/pages/Layout/Pagination";
    import SearchInput from "@components/common/SearchInput";
    import CommonButton from "@components/common/CommonButton";
    import EditorBar from "@components/common/editoritem";

    export default {
        props: {
            VersionType: {
                type: [String, Number],
                default: 3
            }
        },
        name: 'admin',
        components: {
            Pagination,
            SearchInput,
            CommonButton,
            EditorBar
        },
        data() {
            return {
                search: '',
                type: 'edit',   // edit add
                loading: true,
                value: '',
                tableData: [],
                isEditTxt: "详情/编辑",
                addDialog: false,
                form: {
                    id: '',
                    title: '',
                    version: '',
                    name: '',
                    link: '',
                    renew: '',
                    status: '',
                    is_forcibly: '',
                    ver_status: '',
                    remark: '',
                },
                pagination: {
                    pageNum: 1,
                    pageSize: 20,
                    total: 0
                },
                options: [
                    {
                        value: '1',
                        label: '正常'
                    }, {
                        value: '2',
                        label: '禁用'
                    }
                ]
            }
        },
        created() {
            this.init()
        },
        computed: {
            ...mapState({
                userInfo: state => state.userInfo
            }),
            apiParams() {
                return {
                    type: this.VersionType,
                    pageNum: this.pagination.pageNum,
                    pageSize: this.pagination.pageSize,
                    status: this.value,
                    title: this.search
                }
            }
        },

        methods: {
            async init() {
                this.loading = true
                try {
                    let {data} = await versionLists(this.apiParams)
                    if (data.code === statusCode.success) {
                        this.loading = false
                        this.tableData = data.data.data
                        this.pagination.total = data.data.total
                        this.pagination.pageNum = data.data.current_page
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            setSellStyle({row, column, rowIndex, columnIndex}) {
                if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
                if (columnIndex == 7) return "borderRadius: 0  10px 10px 0"
            },
            handleEdit(index, row) {
                this.addDialog = true
                if (row) {
                    this.type = 'edit'
                    for (let key in this.form) {
                        if (row.hasOwnProperty(key)) {
                            this.form[key] = row[key]
                        }
                        if (key === 'is_forcibly') {  // 强更 switch状态
                            this.form[key] = row[key] === 1
                        }
                        if (key === 'status') { // 状态 switch状态
                            this.form[key] = row[key] === 1
                        }
                    }
                } else {
                    this.type = 'add'
                    this.form = {
                        id: '',
                        title: '',
                        version: '',
                        name: '',
                        link: '',
                        renew: '',
                        status: '',
                        is_forcibly: '',
                        ver_status: '',
                        remark: '',
                    }
                }
            },
            handleDelete(index, row) {
                this.$confirm(`此操作将删除【${row.title}】, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const { data } = await versionDelete({ id: row.id})
                    if (data.code === statusCode.success) {
                      this.init()
                    }
                })
            },
            async submit(btn) {
                // this.form.is_forcibly = this.form.is_forcibly ? 1 : 2  //是否强更 1是 2否
                // this.form.status = this.form.is_forcibly ? 1 : 2  //状态 1正常 2禁用 3其他
                if (btn) this.form.is_forcibly = this.form.is_forcibly ? 1 : 2 //通过弹窗按钮提交 单独修改switch状态
                if (btn) this.form.status = this.form.status ? 1 : 2 //通过弹窗按钮提交 单独修改switch状态
                if (this.type === 'add') {
                    let {data} = await versionAdd(Object.assign(this.form, {type: this.VersionType}))
                    let msg = '操作成功', type = 'success'
                    if (data.code === statusCode.success) {
                        this.addDialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                } else {
                    let {data} = await versionUpdate(Object.assign(this.form, {type: this.VersionType}))
                    let msg = '操作成功', type = 'success'
                    if (data.code === statusCode.success) {
                        this.addDialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            async changeSwitch(row, attributes, value) {
                this.type = 'edit'
                for (let key in this.form) {
                    if (row.hasOwnProperty(key)) {
                        this.form[key] = row[key]
                    }
                }
                this.form[attributes] = value

                await this.submit()
            },
            searchData() {
                this.pagination.pageNum = 1
                this.pagination.pageSize = 20
                this.init()
            },
        }
    }
</script>

<style scoped lang="scss">
    .admin-box {
        height: 100%;
        box-sizing: border-box;

        .filter-box {
            display: flex;
        }

        .pagination {
            margin-top: 10px;
        }

        .table {
            height: calc(100% - 100px);
        }
    }

    .editBtn {
        color: #1e82d2;
    }

    .popImg {
        width: 50%;

        img {
            width: 100%;
        }
    }

    .popImgSee {
        width: 100%;
    }

    .editor{
      background: transparent;
    }

    /deep/ .el-table tr {
        cellspacing: 10px;
        border: 1px solid #cccccc;
    }

    /deep/ .el-dialog {
        //弹窗
        width: 600px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-dialog__header {
        background-color: #0E3F6A;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

    /deep/ .el-input__inner {
        height: 37px;
        margin-left: 5px;
        border-radius: 5px;
        border-bottom: 1px solid #ccc;
    }

    /deep/ .el-input, .el-input--suffix {
        height: 37px;
    }
</style>
