<template>
  <div id="customerList">
    <div class="filter-box flex">
      <SearchInput :search.sync="search" placeholder="专家账号/名称/ID" @search="searchData" />
      <el-date-picker format="yyyy-MM-dd" v-model="dataRange" type="daterange" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期" align="right" />
      <CommonButton @click.native="add" class="btnAdd" title="新增专家" />
    </div>
    <div class="table">
      <el-table :row-style="{ height: '40px' }" :header-row-style="{ height: '40px' }" border v-loading="loading"
        ref="multipleTable" tooltip-effect="dark" :data="tableData" height="100%" style="width: 100%">
        <el-table-column type="index" width="80" align="center" label="序号" />
        <el-table-column prop="account" align="center" label="账号" />
        <el-table-column prop="nickname" align="center" label="专家名称" />
        <el-table-column prop="member_id" align="center" label="专家ID" />
        <el-table-column prop="" align="center" label="头像">
          <template slot-scope="scope">
            <el-image style="max-width: 32px;max-height: 32px" :src="scope.row.avatar"
              :preview-src-list="[scope.row.avatar, scope.row.avatar]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="profession" align="center" label="职业" />
        <el-table-column prop="create_time" align="center" label="创建时间" />
        <el-table-column prop="last_time" align="center" label="最后登录时间" />
        <el-table-column prop="last_ip" align="center" label="登录IP" />
        <!-- <el-table-column prop="" align="center" label="登录次数" /> -->
        <el-table-column prop="" align="center" label="状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2" @change="changeSwitch(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination block">
      <Pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
        :total="pagination.total" @loadData="init" />
    </div>
    <el-dialog :close-on-click-modal="false" :title="popupType === 'add' ? '新增' : '编辑'" :visible.sync="dialogFormVisible"
      width="35%">
      <el-form :model="form">
        <el-form-item label="会员ID" :label-width="formLabelWidth">
          <el-input v-model="form.member_id" autocomplete="off" :disabled="popupType === 'edit'" />
        </el-form-item>
        <el-form-item label="职业" :label-width="formLabelWidth">
          <el-input v-model="form.profession" autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit()">提交</el-button>
        <el-button @click="dialogFormVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchInput from "@components/common/SearchInput";
import CommonButton from "@components/common/CommonButton";
import Pagination from "@/pages/Layout/Pagination";
import { statusCode } from "@/util/statusCode";
import { expertList, addExpertList, editExpertList, switchStatus } from "@/api/haveManage"
import dayjs from "dayjs"
export default {
  components: {
    SearchInput,
    CommonButton,
    Pagination
  },
  data() {
    return {
      search: '',
      dataRange: '',
      tableData: [],
      loading: false,
      pagination: {
        currentPage: 1,
        pageSize: 20,
        total: 0
      },
      form: {},
      popupType: 'add',
      dialogFormVisible: false,
      formLabelWidth: '100px'
    }
  },
  watch: {
    dataRange(newVal,oldVal) {
      this.pagination.pageSize = 20
      this.pagination.currentPage = 1
      this.init()
    }
  },
  computed: {
    apiParams() {
      return {
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
        search: this.search,
        start_date: this.dataRange && this.dataRange[0] ? dayjs(this.dataRange[0]).format('YYYY-MM-DD') : '',
        end_date: this.dataRange && this.dataRange[1] ? dayjs(this.dataRange[1]).format('YYYY-MM-DD') : '',
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      try {
        let { data } = await expertList(this.apiParams)
        if (data.code === statusCode.success) {
          this.total = data.total
          this.pagination.total = data.total
          if (data.rows && data.rows.length) {
            this.tableData = JSON.parse(JSON.stringify(data.rows))
          } else {
            this.tableData = []
          }
          this.loading = false
        }
      } catch (e) {
        console.log('error--error')
      }
    },
    add() {
      this.form = {}
      this.dialogFormVisible = true
      this.popupType = "add"
    },
    handleEdit(index, row) {
      this.dialogFormVisible = true
      this.popupType = "edit"
      this.form = {
        id:row.id,
        member_id:row.member_id,
        profession: row.profession
      }
    },
    searchData() {
      this.pagination.pageSize = 20
      this.pagination.currentPage = 1
      this.init()
    },
    async changeSwitch(row) {
      let dataJson = {
        member_id: row.member_id,
        status: row.status
      }
      let {data} = await switchStatus(dataJson)
      let msg = ''
      let type = 'success'
      if (data.code === statusCode.success) {
        msg = '操作成功'
        this.dialogFormVisible = false
        await this.init()
      } else {
        msg = data.msg
        type = 'warning'
      }
      this.$message({message: msg,type,duration: 2000})
    },
    async submit() {
      if (this.popupType === 'add') {
        let dataJson = {
          member_id: this.form.member_id,
          profession: this.form.profession
        }
        let {data} = await addExpertList(dataJson)
        let msg = ''
        let type = 'success'
        if (data.code === statusCode.success) {
          msg = '操作成功'
          this.dialogFormVisible = false
          await this.init()
        } else {
          msg = data.msg
          type = 'warning'
        }
        this.$message({
          message: msg,
          type,
          duration: 2000
        })
      } else {
        let dataJson = {
          id: this.form.id,
          member_id: this.form.member_id,
          profession: this.form.profession
        }
        let {data} = await editExpertList(dataJson)
        let msg = ''
        let type = 'success'
        if (data.code === statusCode.success) {
          msg = '操作成功'
          this.dialogFormVisible = false
          await this.init()
        } else {
          msg = data.msg
          type = 'warning'
        }
        this.$message({
          message: msg,
          type,
          duration: 2000
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
#customerList {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .filter-box {
    margin-bottom: 20px;
  }

  .pagination {
    margin-top: 10px;
  }

  .table {
    height: calc(100% - 100px);
  }
}

.btnAdd {
  margin-left: 15px;
}

/deep/ .el-table .descending .sort-caret.descending {
  border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
  border-bottom-color: #189e90;
}


/deep/ .el-table tr {
  cellspacing: 10px;
  border: 1px solid #CCCCCC;
  padding: 15px 0;
}

/deep/ .el-table__body {
  border-collapse: separate;
  //border-spacing: 0 10px;
  background-color: #f6f6f6;
}


/deep/ .el-table th.el-table__cell>.cell {
  color: #6d6d6e;
  font-size: 14px;
}


/deep/ .el-dialog {
  //弹窗
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
</style>
