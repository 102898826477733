const url = {
    content: "/admin/content/info",
    editContent:"/admin/content/edit",
    addContent: '/admin/content/add',
    getContentList: '/admin/content/list',
    getSpeechList: '/admin/speech/list',
    addSpeech: '/admin/speech/add',
    editSpeech: '/admin/speech/edit',
    deleteSpeech: '/admin/speech/del',
    getCodeHistory: '/admin/sms/history'
}

export default url
