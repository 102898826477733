const url = {
    home: '/api/v1/control/home',
    adminList: '/admin/admin/lists',  //管理员列表
    addAdmin: '/admin/admin/addAdmin',  //新增管理员
    updatePwd: '/admin/admin/updatePwd',  //修改管理员密码
    updateAdmin: '/admin/admin/updateAdmin',  //管理员状态管理

    anchorList: '/admin/anchor/lists', //
    addAnchor: '/admin/anchor/addAnchor',//
    updateAnchor: '/admin/anchor/updateAnchor',//

    memberList: '/admin/member/lists',//
    addMember: '/admin/member/addMember',//
    updateMember: '/admin/member/updateMember',//
    deleteMember: '/admin/member/deleteMember',//

    leaguesList: '/admin/leagues/lists',//联赛管理
    editLeagues: '/admin/leagues/edit',//联赛编辑
    leaguesListB: '/admin/basketballLeagues/lists',//联赛管理
    editLeaguesB: '/admin/basketballLeagues/edit',//联赛编辑

    scheduleList: '/admin/schedule/lists',//足球赛程管理
    scheduleListB: '/admin/basketballSchedule/lists',//篮球赛程管理

    liveList: '/admin/liveRoom/lists',//直播间列表
    liveOrder: '/admin/preordain/lists',//预约列表
    cancel: '/admin/preordain/delete', //取消预约
    liveRecord: '/admin/liveRoom/liveHistory',//直播记录
    editLive: '/admin/liveRoom/edit',//编辑间列表
    editLiveStatus: '/admin/liveRoom/editStatus',//禁播

    advList: '/admin/advertisement/lists',  //广告列表
    addAdv: '/admin/advertisement/add',  //新增广告
    editAdv: '/admin/advertisement/edit',  //编辑广告
    deleteAdv: '/admin/advertisement/delete',  //删除广告

    announceManage: '/admin/announcement/lists',//公告列表
    editAnnouncement: '/admin/announcement/edit',//编辑公告
    addAnnouncement: '/admin/announcement/add',//新增编辑
    announceEditStatus: '/admin/announcement/editStatus',//新增编辑

    systemMessageLists: '/admin/systemMessage/lists',//公告列表-直播间
    systemMessageAdd: '/admin/systemMessage/add',//增加公告-直播间
    systemMessageDelete: '/admin/systemMessage/delete',//删除-直播间
    systemMessageEdit: '/admin/systemMessage/edit',//编辑-直播间

    feedbackManage: '/api/v1/control/feedbackManage',
    webSet: '/api/v1/control/webSet',
    contentList: '/api/v1/control/contentList',


    muteRoomList: '/admin/muteRoom/lists',  //禁言列表
    cancelMute: '/admin/muteRoom/cancelMute',  //取消禁言
    siteManage: '/admin/member/siteManage',  //设为超管
    cancelManage: '/admin/member/cancelManage',  //取消超管

    appDownloadLists: '/admin/appDownload/lists',//app下载列表
    appDownloadAdd: '/admin/appDownload/add', //app下载列表
    appDownloadEdit: '/admin/appDownload/edit', //app下载列表
    appDownloadDelete: '/admin/appDownload/delete', //app下载列表


    checkMode: '/admin/system/checkMode', //审核模式
    chatroomMasterSwitch: '/admin/system/chatroomMasterSwitch', //绿包开关


    statisticalIndex: '/admin/statistical/Index', //渠道管理
    statisticalMonth: '/admin/statistical/month', //渠道月统计

    repairF: '/admin/schedule/repair', //足球赛事修复
    repairB: '/admin/basketballSchedule/repair', //足球赛事修复

    blackIpList: '/admin/system/blackIpList', //IP黑名单-列表
    blackIp: '/admin/system/blackIp', //IP黑名单-添加
    blackIpDel: '/admin/system/blackIpDel', //IP黑名单-删除

    versionLists : '/admin/version/lists', //版本管理
    versionAdd : '/admin/version/add', //版本添加
    versionUpdate: '/admin/version/update', //版本修改
    versionDelete: '/admin/version/delete', //版本删除

    // 全局开关
    getAuditList: '/admin/audit/list',
    addAudit: '/admin/audit/add',
    editAudit: '/admin/audit/edit',
    removeAudit: '/admin/audit/remove',

    // 白名单
    getWhiteList: '/admin/whitelist/list',
    addWhiteList: '/admin/whitelist/add',
    editWhiteList: '/admin/whitelist/edit',
    removeWhiteList: '/admin/whitelist/remove',

    // 实名验证
    realNameList:"/admin/realname/list",
    realnameEdit:"/admin/realname/edit",
    realnameInfo:"/admin/realname/info",  //实名认证详情

    //身份验证管理
    applyList:"/admin/apply/list",  //身份验证列表
    applyInfo:"/admin/apply/info",  //查询身份验证详情
    applyEdit:"/admin/apply/edit",  //编辑身份申请状态

    // 资讯管理
    informationList: '/admin/news/list', //资讯列表
    addInformation: '/admin/news/add', //添加资讯
    editInformation: '/admin/news/edit', //编辑资讯
    delInformation: '/admin/news/remove', //删除资讯
    informationType: '/admin/news/type', //资讯类型

    // 客服链接
    getCsLink: '/admin/app/cslink',
    setCsLink: '/admin/app/cslinkEdit',

    // 礼物账号
    editTestFlag: '/admin/member/setMemberTest'
}

export default url
