const url = {
    // 充值设置
    topUpConfig: '/admin/payConfig/list', // 充值设置列表
    addTopUpConfig: '/admin/payConfig/add', // 充值设置新增
    editTopUpConfig: '/admin/payConfig/edit', // 充值设置编辑
    delTopUpConfig: '/admin/payConfig/remove', // 充值设置删除
    getRatio: '/admin/payConfig/ratio', // 获取球币比例
    setRatio: '/admin/payConfig/ratioSetting', // 设置球币比例
    // 礼物列表
    getGiftList: '/admin/gifts/lists', // 礼物列表
    addGift: '/admin/gifts/add', // 添加礼物
    editGift: '/admin/gifts/edit', // 编辑礼物
    delGift: '/admin/gifts/delete', // 删除礼物
    // 充值记录
    getPayRecordList: '/admin/pay/record', // 充值记录
    // 礼物消费记录
    getGiftsConsumeList: '/admin/gifts/purchaseList', // 礼物消费记录
    // 四方订单
    getSifangOrder: '/admin/pay/recordFour', // 四方订单
    // 支付渠道
    getPayChannelList: '/admin/payChannel/list', // 支付渠道列表
    addPayChannel: '/admin/payChannel/add', // 新增渠道
    editPayChannel: '/admin/payChannel/edit', // 编辑渠道
    delPayChannel: '/admin/payChannel/remove', // 删除渠道
    // 提现审核
    getWithdraw: '/admin/withdraw/list', // 取款列表
    editWithdraw: '/admin/withdraw/edit', // 取款编辑
    // 上下分
    getManualList: '/admin/pay/manualList', // 上下分列表
    addManualList: '/admin/pay/manualOperation', // 新增上下分
    getMemberList:'/admin/member/searchMember', // 获取上下分会员列表
    // 流水
    getBillingList: '/admin/txns/list' // 流水列表
}
export default url