<template>
    <div class="page-box">
        <div class="filter-box" style="display: flex">
            <SearchInput
                :search.sync="search"
                placeholder="联赛名"
                @search="searchData"
            />
            <SearchInput
                :search.sync="searchId"
                placeholder="比赛id"
                @search="searchData"
            />
            <download-excel
                style="line-height: 34px"
                class = "export-excel-wrapper"
                :data = "tableData"
                name = "篮球赛事">
                <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                <!-- <el-button type="primary" size="small">导出EXCEL</el-button> -->
            </download-excel>
        </div>
        <div class="table">
            <el-table :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                      v-loading="loading" ref="multipleTable" tooltip-effect="dark" :data="tableData" border
                      height="100%"
                      style="width: 100%">
                <el-table-column align="center" type="index" label="序号" width="60"></el-table-column>
                <el-table-column align="center" prop="leagueChsShort" label="联赛名"></el-table-column>
                <el-table-column align="center" prop="matchId" label="比赛ID"></el-table-column>
                <el-table-column align="center" prop="homeChs" label="主队名"></el-table-column>
                <el-table-column align="center" prop="homeId" label="主队ID"></el-table-column>
                <el-table-column align="center" prop="awayChs" label="客队名"></el-table-column>
                <el-table-column align="center" prop="awayId" label="客队ID"></el-table-column>
                <el-table-column align="center" prop="state" label="比赛状态">
                    <template slot-scope="scope">
                        <div>{{ state[scope.row.state] }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="matchTime" label="比赛时间" width="180"></el-table-column>
                <el-table-column align="center" prop="follow" label="预约人数"></el-table-column>
                <!--                <el-table-column prop="hierarchy" label="赛事等级"></el-table-column>-->
                <el-table-column align="center" prop="logo" label="是否推荐">
                    <template slot-scope="scope">
                        <div>{{ scope.row.isPosition == 2 ? '是' : '否' }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="index" label="修复赛事">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="handleEdit(scope.row)">修复</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination block">
            <pagination
                :current-page.sync="pagination.currentPage"
                :page-size.sync="pagination.pageSize"
                :total="pagination.total"
                @loadData="init"
            />
        </div>
    </div>
</template>

<script>
    import {repairB, scheduleListB} from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";

    export default {
        components: {SearchInput, Pagination},
        data() {
            return {
                total: 0,
                currentType: '',
                usernameQuery: '',
                loading: true,
                tableData: [],
                dialog: false,
                groupList: [
                    {id: 1, name: '全部'},
                    {id: 2, name: '足球'},
                    {id: 3, name: '篮球'},
                    {id: 4, name: '电竞'},
                    {id: 5, name: '其他'},
                ],
                // 0:未开赛 1:一节 2:二节 3:三节 4:四节  5:1OT  6:2OT  7:3OT  50:中场  -1:完场  -2:待定  -3:中断  -4:取消  -5:推迟（1OT是第一次加时赛）
                state: {
                    0: '未开赛',
                    1: '一节',
                    2: '二节',
                    3: '三节',
                    4: '四节',
                    5: '1OT',
                    6: '2OT',
                    7: '3OT',
                    '50': '中场',
                    '-1': '完场',
                    '-2': '待定',
                    '-3': '中断',
                    '-4': '取消',
                    '-5': '推迟',
                },
                searchCon: [
                    {key: "leagueChsShort", val: '', pla: '联赛名', label: '联赛关键字', labelFlag: false},
                ],
                pagination: {
                    currentPage: 1,
                    pageSize: 20,
                    total: 0
                },
                search: '',
                searchId: '',
            }
        },
        computed: {
            apiParams() {
                return {
                    pageSize: this.pagination.pageSize,
                    pageNum: this.pagination.currentPage,
                    leagueChsShort: this.search,
                    // searchKey: this.search,
                    matchId: this.searchId? this.searchId: undefined,
                }
            }
        },
        created() {
            this.init()
        },
        methods: {
            async init() {
                this.loading = true
                try {
                    let {data} = await scheduleListB(this.apiParams)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        this.tableData = JSON.parse(JSON.stringify(data.rows))
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            addAdmin() {
                this.dialog = true
            },
            setSellStyle({row, column, rowIndex, columnIndex}) {
                if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
                if (columnIndex == 7) return "borderRadius: 0  10px 10px 0"
            },
            switchChange(row) {
                console.log(row)
            },
            async handleEdit(index, row) {
                console.log(index, row)
                let dataJ = {
                    matchId: index.matchId,
                    leagueId: index.leagueId,
                    date: index.matchTime,
                }
                await repairB(dataJ).then(res => {
                    this.$message({
                        message: res.data.msg,
                        type: res.data.msg === 'success' ? 'success' : 'warning'
                    });
                })
            },
            handleDelete(index, row) {
                this.$confirm(`删除此项联赛【${row.nameChs}】, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                })
            },
            searchData() {
                this.pagination.pageSize = 20
                this.pagination.currentPage = 1
                this.init()
            },
        }
    }
</script>
<style scoped lang="scss">
    .page-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .filter-box {
            margin-bottom: 20px;
        }

        .pagination {
            margin-top: 10px;
        }

        .table {
            height: calc(100% - 100px);
        }
    }

    .searchTop {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    .editBtn {
        color: #1e82d2;
    }

    .popImg {
        width: 50%;

        img {
            width: 100%;
        }
    }

    .popImgSee {
        width: 100%;
    }

    /deep/ .el-table--border .el-table__cell,
    .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
        //border: 0;
    }

    /deep/ .el-table tr {
        //cellspacing: 10px;
        border: 1px solid #cccccc;
        //padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        //border-spacing: 0 10px;
        background-color: #f6f6f6;
    }

    /deep/ .el-dialog {
        //弹窗
        width: 400px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-dialog__header {
        background-color: #189e90;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }
</style>
