<template>
    <div class="page-box">
        <!--        <div class="topBtnList">-->
        <!--            <button class="btnAdd" @click="handleEdit(null, null,'add')">新增下载地址+</button>-->
        <!--        </div>-->
        <div class="table">
          <el-table :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                    v-loading="loading" ref="multipleTable" tooltip-effect="dark" :data="tableData"
                    height="100%"
                    style="width: 100%">
            <el-table-column align="center" type="index" label="序号" width="60"></el-table-column>
            <el-table-column align="center" prop="android_link" show-overflow-tooltip width="200" label="android下载链接"></el-table-column>
            <el-table-column align="center" prop="ios_link" show-overflow-tooltip width="200" label="iOS下载链接"></el-table-column>
            <el-table-column align="center" prop="status" label="状态">
              <template slot-scope="scope">
                <el-select v-model="scope.row.ad_status" placeholder="请选择"
                           @change="switchChange(scope.row,1,$event)">
                  <el-option
                      v-for="(item,i) in ['正常','禁用','其他']"
                      :key="i"
                      :label="item"
                      :value="i+1">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="remark" label="备注"></el-table-column>
            <el-table-column align="center" prop="update_by" label="操作人"></el-table-column>
            <el-table-column align="center" prop="create_time" label="创建时间"></el-table-column>
            <el-table-column align="center" prop="update_time" label="操作时间"></el-table-column>
            <el-table-column align="center" prop="create_by" label="创建者"></el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button @click="handleEdit(scope.$index, scope.row,'edit')" type="primary" icon="el-icon-edit"
                           circle></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination block">
          <pagination
              :current-page.sync="pagination.currentPage"
              :page-size.sync="pagination.pageSize"
              :total="pagination.total"
              @loadData="init"
          />
        </div>
        <el-dialog :close-on-click-modal="false" title="新增/编辑" :visible.sync="dialog" width="40%">
            <el-form ref="ruleForm" :model="formItem" label-width="80px">
                <el-row>
                    <el-col :span="13">
                        <el-form-item label="Android下载地址">
                            <el-input v-model="formItem.android_link"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="13">
                        <el-form-item label="iOS下载地址">
                            <el-input v-model="formItem.ios_link"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="说明">
                            <el-input v-model="formItem.remark"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">提交</el-button>
                            <el-button @click="dialog = false">取消</el-button>
                            <el-button @click="resetForm()">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {
        editAnnounce,
        appDownloadLists,
        appDownloadAdd,
        appDownloadEdit, editLeaguesList,
    } from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";
    export default {
        components: {SearchInput, Pagination},
        props: {
            adminInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                pageSize: 10,
                pageNum: 1,
                total: 0,
                loading: true,
                tableData: [],
                isEditTxt: "详情/编辑",
                type: "add", //add edit
                currentId: '',
                dialog: false,
                formItem: {
                    android_link: '',
                    ios_link: '',
                    remark: '',
                },
                pagination: {
                  currentPage: 1,
                  pageSize: 20,
                  total: 0
                },
            }
        },
        computed: {
          apiParams() {
            return {
              pageSize: this.pagination.pageSize,
              pageNum: this.pagination.currentPage,
            }
          }
        },
        created() {
            this.init()
        },
        methods: {
            async init() {
                this.loading = true
                try {
                    let {data} = await appDownloadLists(this.apiParams)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        this.tableData = JSON.parse(JSON.stringify(data.rows))
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            async switchChange(row, num, value) {
                if (num === 1) {
                    let {data} = await appDownloadEdit({ad_status: value,id: row.id})
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.addDialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            changePage(val) {
                this.pageNum = val
                this.init()
            },
            handleEdit(index, row, type) {  //1 新增   2 编辑
                this.type = type
                this.dialog = true
                this.status = row.status
                console.log(row)
                for (let k in row) {
                    for (let key in this.formItem) {
                        if (k === key) {
                            this.formItem[key] = row[k]
                        }
                    }
                }
                if (row) this.formItem.id = row.id
            },
            handleDelete(index, row) {
                // this.$confirm(`此操作将永久删除公告【${row.title}】, 是否继续?`, '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // }).then(() => {
                // })
            },
            async onSubmit() {
                this.formItem.status = this.formItem.status ? 1 : 2
                if (this.type === 'add') {
                    let {data} = await appDownloadAdd(this.formItem)
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                } else {
                    let {data} = await appDownloadEdit(this.formItem)
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialog = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            resetForm() {
                for (let key in this.formItem) {
                    if (typeof this.formItem[key] !== "boolean") this.formItem[key] = ''
                }
            },
            async changeSwitch(item) {
                let msg = ''
                let type = 'success'
                console.log(item)
                let {data} = await editAnnounce({status: item.status == 1 ? 2 : 1, id: item.id})
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.dialog = false
                    await this.init()
                } else {
                    msg = data.msg
                    type = 'warning'
                }
                this.$message({
                    message: msg,
                    type,
                    duration: 2000
                });
            }
        }
    }
</script>

<style scoped lang="scss">
.page-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .filter-box {
    margin-bottom: 20px;
  }
  .pagination {
    margin-top: 10px;
  }
  .table {
    height: calc(100% - 50px);
  }
}
    .editBtn {
        color: #1e82d2;
    }

    .popImg {
        width: 50%;

        img {
            width: 100%;
        }
    }

    .popImgSee {
        width: 100%;
    }

    /deep/ .el-table--border .el-table__cell,
    .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
        //border: 0;
    }

    /deep/ .el-table tr {
        //cellspacing: 10px;
        border: 1px solid #cccccc;
        //padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        //border-spacing: 0 10px;
        background-color: #f6f6f6;
    }

    /deep/ .el-dialog {
        //弹窗
        border-radius: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-dialog__header {
        background-color: #189e90;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

    /deep/ .el-input__inner {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #ccc;
    }
</style>
