<template>
    <div class="page-box">
        <ContentEdit></ContentEdit>
    </div>
</template>

<script>
    import ContentEdit from "@/components/common/contentEdit"

    export default {
        components: {ContentEdit},
        name: "content_4",
    }
</script>

<style lang="scss" scoped>
.page-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
