<template>
  <div class="page-box">
    <div  class="box-item">
      <h4>绿包模块总开关</h4>
      <el-radio @change="handleEditGreen(1)" v-model="switchGreen" :label="1">开启</el-radio>
      <el-radio @change="handleEditGreen(2)"  v-model="switchGreen" :label="2">关闭</el-radio>
    </div>
    <div class="apk-download">
      <p>APK下载链接</p>
      <el-form ref="ruleForm" :model="formItem" label-width="125px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="Android下载地址">
              <el-input v-model="formItem.android_link"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="iOS下载地址">
              <el-input v-model="formItem.ios_link"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="说明">
              <el-input v-model="formItem.remark"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
<!--              <el-button @click="dialog = false">取消</el-button>-->
<!--              <el-button @click="resetForm()">重置</el-button>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="link">
<!--      <p>客服链接</p>-->
      <el-form ref="ruleFormLink" :model="formLink" label-width="125px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="客服链接">
              <el-input v-model="formLink.link"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <el-button type="primary" @click="setLink">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import {appDownloadAdd, appDownloadEdit, appDownloadLists, chatroomMasterSwitch,getCsLink, setCsLink} from "@/api/control";
import {statusCode} from "@/util/statusCode";

export default {
  name: 'appSet',
  data() {
    return {
      formItem: {
        android_link: '',
        ios_link: '',
        remark: '',
      },
      switchGreen: '',
      apkInfo: null,
      formLink: {
        link: ''
      },
    }
  },
  created() {
    this.init()
    this.getGreen()
    this.getLink()
  },
  methods: {
    async init() {
      this.loading = true
      try {
        let {data} = await appDownloadLists()
        if (data.code === statusCode.success) {
          const list = JSON.parse(JSON.stringify(data.rows))
          this.apkInfo = list[0] || []
          this.type = list.length ? 'edit' : 'add'
          this.loading = false
          this.formItem.android_link = this.apkInfo.android_link
          this.formItem.ios_link = this.apkInfo.ios_link
          this.formItem.remark = this.apkInfo.remark
          this.formItem.status = this.apkInfo.status
          this.formItem.id = this.apkInfo.id
        }
      } catch (e) {
        console.log('error--error')
      }
    },
    async getGreen() {
      let {data} = await chatroomMasterSwitch()
      if(data.code === statusCode.success) {
        this.switchGreen = data.data.switch
      }
    },
    async getLink() {
      let {data} = await getCsLink()
      if(data.code === statusCode.success) {
        this.formLink.link = data.data.link || ''
      }
    },
    async setLink() { // d25.cc
      const { data } = await setCsLink(this.formLink.link)
      if (data.code == statusCode.success) {
        console.log(data, 'data')
        this.$message({
          message: '修改成功',
          type: 'success'
        })
      }
    },
    async handleEditGreen(val) {
      let {data} = await chatroomMasterSwitch({switch: val})
      if (data.code == statusCode.success) {
        this.switchGreen = data.data.switch
        this.$message({
          message: '修改成功',
          type: 'success'
        })
      }
    },
    async onSubmit() {
      this.formItem.status = this.formItem.status ? 1 : 2
      if (this.type === 'add') {
        let {data} = await appDownloadAdd(this.formItem)
        let msg = ''
        let type = 'success'
        if (data.code === statusCode.success) {
          msg = '操作成功'
          this.dialog = false
          await this.init()
        } else {
          msg = data.msg
          type = 'warning'
        }
        this.$message({
          message: msg,
          type,
          duration: 2000
        });
      } else {
        let {data} = await appDownloadEdit(this.formItem)
        let msg = ''
        let type = 'success'
        if (data.code === statusCode.success) {
          msg = '操作成功'
          this.dialog = false
          await this.init()
        } else {
          msg = data.msg
          type = 'warning'
        }
        this.$message({
          message: msg,
          type,
          duration: 2000
        });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.page-box{
  width: 100%;
}
.box-item {
  border-radius: 5px;
  display: flex;
  padding: 10px 0;
  align-items: center;
  border-bottom: 1px solid #eee;
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
}

.apk-download, .link{
  width: 600px;
  padding: 10px 0;
  p {
    font-size: 16px;
  }
}

/deep/ .el-input__inner {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
}
</style>
