<template>
  <div class="page-box">
    <div class="filter-box" style="display: flex">
      <SearchInput
          :search.sync="search"
          placeholder="ip"
          @search="searchData"
      />
      <el-date-picker
          format="yyyy-MM-dd"
          v-model="dataRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right">
      </el-date-picker>
      <CommonButton @click.native="handleEdit" class="btnAdd m-l-10" title="新增"/>
    </div>
    <div class="table">
      <el-table :data="tableData"
                :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                height="100%"
                style="width: 100%">
        <el-table-column align="center" type="index" width="80" label="序号"></el-table-column>
        <table-one-empty align="center" prop="id" label="ID"></table-one-empty>
        <table-one-empty align="center" prop="ip" label="IP"></table-one-empty>
        <table-one-empty align="center" prop="create_time" label="添加时间"></table-one-empty>
        <el-table-column :min-width="80" align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <status-button
                :tips="['启用', '禁用']"
                :value="scope.row.status == 1"
                no-event
            />
          </template>
        </el-table-column>
        <table-one-empty align="center" prop="update_time" label="最后编辑时间"></table-one-empty>
        <table-one-empty align="center" prop="remark" label="备注" width="80"></table-one-empty>
        <table-one-empty align="center" prop="edit_by" label="操作人" width="80"></table-one-empty>
        <el-table-column align="center" min-width="120" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" class="m-r-10" @click="editPkg(scope.row)">编辑</el-button>
            <el-button type="danger" @click="deleteRow(scope.row)">删除</el-button>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination block">
      <pagination
          :current-page.sync="pagination.currentPage"
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          @loadData="init"
      />
    </div>
    <EditIp
        :value.sync="showEditDialog"
        :info="info"
        @refresh="init"
    />
  </div>
</template>

<script>
import {checkMode, getWhiteList, removeWhiteList} from "@/api/control";
import {statusCode} from "@/util/statusCode";
import {Format, MillisecondToTime} from "@/util/common"
import SearchInput from "@components/common/SearchInput";
import Pagination from "@/pages/Layout/Pagination";
import CommonButton from "@components/common/CommonButton";
import EditIp from "@/pages/whiteList/EditIp";
import TableOneEmpty from "@components/common/tableOneEmpty";
export default {
  components: {SearchInput, Pagination, CommonButton, EditIp, TableOneEmpty},
  data() {
    return {
      dataRange: '',
      pageSize: 10,
      pageNum: 1,
      total: 0,
      loading: true,
      tableData: [],

      pagination: {
        currentPage: 1,
        pageSize: 20,
        total: 0
      },
      search: '',
      showEditDialog: false,
      info: {}
    }
  },
  computed: {
    apiParams() {
      return {
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
        ip: this.search,
        start_date: this.dataRange && this.dataRange[0] ? this.dataRange[0] : '',
        end_date: this.dataRange && this.dataRange[1] ? this.dataRange[1] : '',
      }
    }
  },
  mounted() {
    this.init()
  },
  watch:{
    dataRange(newVal, oldVal) {
      this.pagination.pageSize = 20
      this.pagination.currentPage = 1
      this.init()
    }
  },
  methods: {
    async init() {
      this.loading = true
      try {
        if(this.apiParams.end_time){
          let end = this.apiParams.end_time
          this.apiParams.end_time = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59)
        }
        let {data} = await getWhiteList(this.apiParams)
        if (data.code === statusCode.success) {
          this.total = data.total
          this.pagination.total = data.total
          this.tableData = JSON.parse(JSON.stringify(data.rows))
          this.tableData.map((item, i) => {
            item.start_time = Format(item.create_time, '-')
            item.end_time = Format(item.update_time, '-')
            item.live_time = MillisecondToTime(item.live_time)
          })
          this.loading = false
        }
      } catch (e) {
        console.log('error--error')
      }
    },
    setSellStyle({row, column, rowIndex, columnIndex}) {
      if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
      if (columnIndex == 7) return "borderRadius: 0  10px 10px 0"
    },

    searchData() {
      this.pagination.pageSize = 20
      this.pagination.currentPage = 1
      this.init()
    },
    async changeSwitch(item) {
      // let msg = '操作成功',type = 'success'
      // let sendJson = {
      //   id: item.id,
      //   status: item.status == 1 ? 2 : 1
      // }
      // let {data} = await updateAdmin(sendJson)
      // if (data.code === statusCode.success) {
      //   this.addDialog = false
      //   await this.init()
      // } else {
      //   msg = data.msg
      //   type = 'warning'
      // }
      // this.$message({
      //   message: msg,
      //   type,
      //   duration: 2000
      // });

      // console.log(this.Android)
      // console.log(val === 2)
      if (type === 1) {
        this.Android = val === 1 ? 2 : 1
      } else if (type === 2) {
        this.IOS = val === 1 ? 2 : 1
      } else if (type === 3) {
        this.huawei = val === 1 ? 2 : 1
      } else if (type === 4) {
        this.vivo = val === 1 ? 2 : 1
      } else if (type === 5) {
        this.yingyongbao = val === 1 ? 2 : 1
      } else if (type === 6) {
        this.youqiubiying = val === 1 ? 2 : 1
      } else if (type === 7) {
        this.kuaishou = val === 1 ? 2 : 1
      } else if (type === 8) {
        this.samsung = val === 1 ? 2 : 1
      } else if (type === 9) {
        this.baidu = val === 1 ? 2 : 1
      } else if (type === 10) {
        this.miqiu = val === 1 ? 2 : 1
      } else if (type === 11) {
        this.caiqiu = val === 1 ? 2 : 1
      } else if (type === 12) {
        this.hbxiaomi = val === 1 ? 2 : 1
      }
      console.log(this.Android)
      let {data} = await checkMode({
        IOS: this.IOS,
        Android: this.Android,
        huawei: this.huawei,
        vivo: this.vivo,
        yingyongbao: this.yingyongbao,
        youqiubiying: this.youqiubiying,
        kuaishou: this.kuaishou,
        samsung: this.samsung,
        baidu: this.baidu,
        miqiu: this.miqiu,
        caiqiu: this.caiqiu,
        hbxiaomi: this.hbxiaomi,
      })
      if (data.code == statusCode.success) {
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        await this.init()
      }
    },
    editPkg(row) {
      this.info = row
      this.showEditDialog = true
    },
    handleEdit() {
      this.info = {}
      this.showEditDialog = true
    },
    async deleteRow(row) {
      this.$confirm('确认要删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data } = await removeWhiteList(row.id)
        if (data.code === statusCode.success) {
          this.$message({
            message: data.data,
            type: 'success',
            duration: 2000
          });
        }
        this.init()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .filter-box {
    margin-bottom: 20px;
  }

  .pagination {
    margin-top: 10px;
  }

  .table {
    height: calc(100% - 100px);
  }
}

.inp {
  width: 200px;
  margin: 10px 0;
}

</style>
