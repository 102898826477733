<template>
    <div id="customerList" class="page-box">
        <div class="filter-box" style="display: flex">
            <SearchInput
                :search.sync="search"
                placeholder="账号/手机号"
                @search="searchData('account')"
            />
            <SearchInput
                :search.sync="search"
                placeholder="请输入会员ID"
                @search="searchData('member_id')"
            />
            <SearchInput
                :search.sync="search"
                placeholder="昵称"
                @search="searchData('nickname')"
            />
        </div>
        <div class="table">
            <el-table
                :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                :cell-style="setSellStyle"
                v-loading="loading"
                ref="multipleTable"
                tooltip-effect="dark"
                :data="tableData"
                height="100%"
                style="width: 100%">
                <el-table-column align="center" type="index" width="80" label="序号"></el-table-column>
                <el-table-column align="center" prop="account" label="账号"></el-table-column>
                <el-table-column align="center" prop="member_id" label="会员ID"></el-table-column>
                <el-table-column align="center" prop="nickname" label="昵称"></el-table-column>
                <el-table-column align="center" prop="update_time" label="操作时间"></el-table-column>
                <el-table-column align="center" prop="status" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="changeSwitch(scope.row)">解除禁言</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination block">
            <pagination
                :current-page.sync="pagination.currentPage"
                :page-size.sync="pagination.pageSize"
                :total="pagination.total"
                @loadData="init"
            />
        </div>
    </div>

</template>

<script>
    import {muteRoomList, cancelMute} from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";

    export default {
        components: {SearchInput, Pagination},
        data() {
            return {
                search: '',
                total: 0,
                currentId: '',
                popupType: 'add',    //add || edit
                loading: true,
                selectList: [],
                dialogFormVisible: false,
                form: {
                    account: '',
                    nickname: '',
                    password: "",
                },
                formLabelWidth: '100px',
                tableData: [],
                pagination: {
                    currentPage: 1,
                    pageSize: 20,
                    total: 0
                },
            }
        },
        computed: {
            apiParams() {
                return {
                    pageSize: this.pagination.pageSize,
                    pageNum: this.pagination.currentPage,
                }
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            async init(key) {
                if (key) this.apiParams[key] = this.search
                this.loading = true
                try {
                    let {data} = await muteRoomList(this.apiParams)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        if (data.rows && data.rows.length) this.tableData = JSON.parse(JSON.stringify(data.rows))
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },

            setSellStyle({row, column, rowIndex, columnIndex}) {
                if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
                if (columnIndex == 16) return "borderRadius: 0  10px 10px 0"
            },
            async changeSwitch(item) {
                let msg = ''
                let type = 'success'
                let {data} = await cancelMute({member_id: item.member_id})
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.addDialog = false
                    await this.init()
                } else {
                    msg = data.msg
                    type = 'warning'
                }
            },
            searchData(key) {
                this.pagination.pageSize = 20
                this.pagination.currentPage = 1
                this.init(key)
            },
        }
    }
</script>

<style scoped lang="scss">
    .page-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .filter-box {
            margin-bottom: 20px;
        }

        .pagination {
            margin-top: 10px;
        }

        .table {
            height: calc(100% - 100px);
        }
    }

    #customerList {
        width: 100%;
    }

    /deep/ .el-table .descending .sort-caret.descending {
        border-top-color: #189e90;
    }

    /deep/ .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #189e90;
    }

    /deep/ .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
        //border: 0;
    }

    /deep/ .el-table tr {
        //cellspacing: 10px;
        border: 1px solid #CCCCCC;
        //padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        //border-spacing: 0 10px;
        background-color: #f6f6f6;
    }


    /deep/ .el-table th.el-table__cell > .cell {
        color: #6d6d6e;
        font-size: 14px;
    }

    /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
        //padding: 0;
    }

    /deep/ .el-dialog { //弹窗
        width: 600px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__header {
        background-color: #189e90;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-form-item__label {
        color: #000;
        font-size: 14px;
    }

    ///deep/ .el-input__inner {
    //    //border: 0;
    //    //border-radius: 0;
    //    border-bottom: 1px solid #ccc;
    //}
</style>
