<template>
    <div id="payment-channel">
        <div class="search flex">
            <search-input :search.sync="nickname" placeholder="ID/昵称" @search="searchData" />
            <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" class="date">
            </el-date-picker>
            <div>
                <el-select v-model="currency" placeholder="请选择" @change="init" clearable>
                    <el-option label="全部" value="">全部</el-option>
                    <el-option label="球币" :value="1">球币</el-option>
                    <el-option label="球票" :value="2">球票</el-option>
                </el-select>
            </div>
            <CommonButton @click.native="handlerAdd" class="btnAdd m-l-10" title="上/下分" />
        </div>
        <div class="table">
            <el-table v-loading="loading" :data="tableData" height="100%" style="width:100%" :row-style="{ height: '40px' }"
                :header-row-style="{ height: '40px' }" border>
                <el-table-column label="序号" type="index" align="center" />
                <el-table-column label="会员ID" prop="member_id" align="center" />
                <el-table-column label="会员昵称" prop="nickname" align="center" />
                <el-table-column label="货币类型" prop="currency" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.currency === 1 ? '球币' : '球票' }}
                    </template>
                </el-table-column>
                <el-table-column label="上/下分" prop="type" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.type == 1 ? '上分' : '下分' }}
                    </template>
                </el-table-column>
                <el-table-column label="数量" prop="amount" align="center" />
                <el-table-column label="上下分时间" prop="create_time" align="center" />
                <el-table-column label="操作人" prop="create_by" align="center" />
            </el-table>
        </div>
        <div class="pagin">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div>
        <el-dialog :close-on-click-modal="false" title="上/下分" :visible.sync="dialogFormVisible"
        width="35%" :before-close="handlerCancel">
            <el-form :model="form" :rules="rules" ref="formName">
                <el-form-item label="会员账号" prop="member_id" :label-width="formLabelWidth">
                    <el-select
                    v-model="form.member_id"
                    filterable
                    remote
                    reserve-keyword
                    clearable
                    placeholder="请输入关键词"
                    :remote-method="searchMember"
                    @focus="searchMember"
                    :loading="loading">
                    <el-option
                        v-for="item in options"
                        :key="item.member_id"
                        :label="item.nickname"
                        :value="item.member_id">
                    </el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="货币" prop="currency" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.currency">
                        <el-radio :label="1">球币</el-radio>
                        <el-radio :label="2">球票</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="上/下分" prop="type" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.type">
                        <el-radio :label="1">上分</el-radio>
                        <el-radio :label="2">下分</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="数量" prop="amount" :label-width="formLabelWidth">
                    <el-input v-model="form.amount" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="formValidate">提交</el-button>
                <el-button @click="handlerCancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput.vue';
import CommonButton from "@components/common/CommonButton";
import CommonUpload from '@/components/common/CommonUpload';
import Pagination from '@/pages/Layout/Pagination.vue';
import TableOneEmpty from "@/components/common/tableOneEmpty.vue";
import { getManualList, addManualList, getMemberList} from '@/api/topUp'
import { statusCode } from '@/util/statusCode'
import dayjs from 'dayjs';
export default {
    components: {
        SearchInput,
        CommonButton,
        CommonUpload,
        Pagination,
        TableOneEmpty
    },
    data () {
        return {
            nickname: '',
            currency: '',
            dateRange: '',
            loading: false,
            tableData: [],
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            formLabelWidth: '100px',
            form: {},
            options: [],
            dialogFormVisible: false,
            popType: 'add', // 'add' || 'edit'
            rules: {
                member_id: [
                    {required: true, message: '请选择会员账号', trigger: 'change'}
                ],
                currency: [
                    {required: true, message: '请选择货币类型', trigger: 'change'}
                ],
                type: [
                    {required: true, message: '请选择上下分', trigger: 'change'}
                ],
                amount: [
                    {required: true, message: '请输入数量', trigger: 'blur'}
                ]
            }
        }
    },
    mounted () {
        this.init()
    },
    watch: {
        dateRange () {
            this.init()
        }
    },
    computed: {
        apiParams () {
            return {
                pageNum: this.pagination.currentPage,
                pageSize: this.pagination.pageSize,
                nickname: this.nickname,
                currency: this.currency,
                start: this.dateRange && this.dateRange[0] ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : '',
                end: this.dateRange && this.dateRange[1] ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : '',
            }
        }
    },
    methods: {
        async init () {
            this.loading = true
            try {
                const { data } = await getManualList(this.apiParams)
                if (data.code === statusCode.success) {
                    this.pagination.total = data.total
                    this.tableData = data.rows
                } else {
                    this.$message({message: data.msg, type: 'warning'})
                }
            } catch (e) {
                console.log('出现错误')
            } finally {
                this.loading = false
            }
        },
        searchData () {
            this.pagination.currentPage = 1
            this.pagination.pageSize = 20
            this.init()
        },
        handlerAdd () {
            this.popType = 'add'
            this.dialogFormVisible = true
        },
        handlerCancel () {
            this.$refs['formName'].resetFields()
            this.dialogFormVisible = false
            this.form = {}
        },
        formValidate () {
            this.$refs['formName'].validate((valid) => {
                if (valid) {
                    this.submit()
                }
            })
        },
        async submit () {
            const jsonData = {
                member_id: this.form.member_id,
                currency: this.form.currency,
                type: this.form.type,
                amount: this.form.amount
            }
            let message = '操作成功', type = 'success'
            const { data } = await addManualList(jsonData)
            if (data.code === statusCode.success) {
                this.dialogFormVisible = false
                this.init()
            } else {
                message = data.msg, type = 'warning'
            }
            this.$message({ message, type })
        },
        async searchMember (value) {
            if (typeof value === 'object') value = ''
            const { data } = await getMemberList({search: value})
            if (data.code === statusCode.success) {
                this.options = data.data || []
            } else {
                this.$message({message: data.msg, type: 'warning'})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#payment-channel {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .search {
        margin-bottom: 20px;

        .date {
            margin-right: 15px;
        }

        .addBtn {
            margin-left: 15px;
        }
    }

    .table {
        height: calc(100% - 100px);
    }

    .pagin {
        margin-top: 10px;
    }
}

// 表格
/deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
}


/deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}


//弹窗
/deep/ .el-dialog {
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
</style>