<template>
    <div class="page-box">
        <div class="filter-box" style="display: flex">
            <SearchInput
                :search.sync="search"
                placeholder="请输入会员ID/手机号"
                @search="searchData"
            />
            <el-date-picker
                format="yyyy-MM-dd"
                v-model="dataRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
            </el-date-picker>
            <div class="statics flex-1 flex align-center flex-end " v-if="staticInfo">
              <span
                  class="font-14 m-r-10"
                  v-for="(label, key) in  staticMap"
                  :key="key"
              >{{ label }}: <span class="font-16 font-600">{{ staticInfo[key]}}</span>
              </span>
            </div>
        </div>
        <div class="table">
            <el-table :data="tableData"
                      :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                      height="100%"
                      style="width: 100%">
                <el-table-column align="center" type="index" width="80" label="序号"></el-table-column>
                <el-table-column align="center" prop="id" label="直播ID"></el-table-column>
                <el-table-column align="center" prop="room_id" label="房间号"></el-table-column>
                <el-table-column align="center" prop="nick" label="主播昵称"></el-table-column>
                <el-table-column align="center" prop="room_title" label="直播间标题"></el-table-column>
                <el-table-column align="center" prop="match_id" label="比赛ID"></el-table-column>
                <el-table-column align="center" prop="league_id" label="联赛ID"></el-table-column>
                <el-table-column align="center" prop="start_time" label="开播时间"></el-table-column>
                <el-table-column align="center" prop="view_num" label="访问人数" width="80"></el-table-column>
                <el-table-column align="center" prop="end_time" label="结束时间"></el-table-column>
                <el-table-column align="center" prop="live_time" label="开播时长"></el-table-column>
            </el-table>
        </div>
        <div class="pagination block">
            <pagination
                :current-page.sync="pagination.currentPage"
                :page-size.sync="pagination.pageSize"
                :total="pagination.total"
                @loadData="init"
            />
        </div>
    </div>
</template>

<script>
    import {getLiveRecord, } from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import {Format, MillisecondToTime} from "@/util/common"
    import SearchInput from "@components/common/SearchInput";
    import Pagination from "@/pages/Layout/Pagination";
    import {getLivingRecordStatics} from "@/api/Host/Host";
    import dayjs from 'dayjs'
    export default {
        components: {SearchInput, Pagination},
        data() {
            return {
                dataRange: '',
                pageSize: 10,
                pageNum: 1,
                total: 0,
                loading: true,
                tableData: [],
                input: '',
                inputLiveId: '',
                // searchCon: [
                //     {key: "member_id", val: '', pla: '直播ID/主播ID', label: '直播ID/主播ID', labelFlag: false},
                // ],
                pagination: {
                    currentPage: 1,
                    pageSize: 20,
                    total: 0
                },
                search: '',
                staticMap: {
                    'sum_view': ' 总访问人数',
                },
                staticInfo: null,
            }
        },
        computed: {
            apiParams() {
                return {
                    pageSize: this.pagination.pageSize,
                    pageNum: this.pagination.currentPage,
                    member_id: this.search,
                    start_time: this.dataRange && this.dataRange[0] ? this.dataRange[0] : '',
                    end_time: this.dataRange && this.dataRange[1] ? this.dataRange[1] : '',
                }
            },
            staticParams() {
                return {
                    startDate: this.dataRange && this.dataRange[0] ? dayjs(this.dataRange[0]).format('YYYY-MM-DD') : '',
                    endDate: this.dataRange && this.dataRange[1] ? dayjs(this.dataRange[1]).format('YYYY-MM-DD') : '',
                }
            }
        },
        mounted() {
            this.init()
            this.initStatics()
        },
        watch:{
            dataRange(newVal, oldVal) {
                this.pagination.pageSize = 20
                this.pagination.currentPage = 1
                this.init()
                this.initStatics()
            }
        },
        methods: {
            async initStatics() {

                try {
                   const { data} = await getLivingRecordStatics(this.staticParams)
                    const { code, data: sumData } = data
                   if(code === 200) {
                       this.staticInfo = sumData
                   }
                } catch (e) {
                    console.log('error--error-- getLivingRecordStatics')
                }
            },
            async init() {
                this.loading = true
                try {
                    if(this.apiParams.end_time){
                        let end = this.apiParams.end_time
                        this.apiParams.end_time = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59)
                    }
                    let {data} = await getLiveRecord(this.apiParams)
                    if (data.code === statusCode.success) {
                        this.total = data.total
                        this.pagination.total = data.total
                        this.tableData = JSON.parse(JSON.stringify(data.rows))
                        this.tableData.map((item, i) => {
                            item.start_time = Format(item.start_time, '-')
                            item.end_time = Format(item.end_time, '-')
                            item.live_time = MillisecondToTime(item.live_time)
                        })
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            setSellStyle({row, column, rowIndex, columnIndex}) {
                if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
                if (columnIndex == 7) return "borderRadius: 0  10px 10px 0"
            },

            searchData() {
                this.pagination.pageSize = 20
                this.pagination.currentPage = 1
                this.init()
            },
        }
    }
</script>

<style scoped lang="scss">
    .page-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .filter-box {
            margin-bottom: 20px;
        }

        .pagination {
            margin-top: 10px;
        }

        .table {
            height: calc(100% - 100px);
        }
    }

    .inp {
        width: 200px;
        margin: 10px 0;
    }

    .inputLiveId {
        width: 200px;
        margin: 10px 10px;
    }
</style>
