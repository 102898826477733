<template>
    <div id="top-up-setting">
        <div class="search flex">
            <CommonButton @click.native="handlerAdd()" class="btnAdd" title="新增" />
        </div>
        <div class="table">
            <el-table v-loading="loading" :data="tableData" height="100%" style="width:100%" :row-style="{ height: '40px' }"
                :header-row-style="{ height: '40px' }" border>
                <el-table-column label="排序" prop="sort" align="center" width="80" />
                <el-table-column label="类别" prop="type" align="center">
                    <template slot-scope="scope">
                        <span :style="{'color':scope.row.type==1?'#67C23A': '#409EFF'}">
                            {{scope.row.type == 1 ?'球币': '球票'}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="金额" prop="amount" align="center" />
                <el-table-column label="创建时间" prop="create_time" align="center" />
                <el-table-column label="最后编辑时间" prop="update_time" align="center" />
                <el-table-column label="状态" prop="status" align="center" width="120"> 
                    <template slot-scope="scope">
                        <el-switch
                        v-model="scope.row.status"
                        :active-value="1"
                        :inactive-value="2"
                        @change="statusSwitch(scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作人" prop="create_by" align="center" width="160" />
                <el-table-column label="操作" prop="" align="center" width="160">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" @click="handlerEdit(scope.row)">编辑</el-button>
                        <el-button size="small" type="danger" @click="handlerDel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- <div class="pagin">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div> -->
        <el-dialog :close-on-click-modal="false" title="新增/编辑" :visible.sync="dialogFormVisible"
        width="35%">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
                    <el-input v-model="form.sort" />
                </el-form-item>
                <el-form-item label="类别" prop="type" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.type">
                        <el-radio :label="1">球币</el-radio>
                        <el-radio :label="2">球票</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="金额" prop="amount" :label-width="formLabelWidth">
                    <el-input v-model="form.amount" />
                </el-form-item>
                <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.status">
                        <el-radio :label="1">开启</el-radio>
                        <el-radio :label="2">禁止</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="formValidated('form')">提交</el-button>
                <el-button @click="dialogFormVisible = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput.vue';
import CommonButton from "@components/common/CommonButton";
import CommonUpload from '@/components/common/CommonUpload';
import Pagination from '@/pages/Layout/Pagination.vue';
import {sellMoney,addSellMoney,editSellMoney,delSellMoney} from '@/api/haveManage'
import {statusCode} from '@/util/statusCode'
export default {
    components: {
        SearchInput,
        CommonButton,
        CommonUpload,
        Pagination
    },
    data () {
        return {
            loading: false,
            tableData: [],
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            formLabelWidth: '100px',
            form: {},
            dialogFormVisible: false,
            popType: 'add', // 'add' || 'edit'
            rules: {
                sort: [
                    {required: true, message: '排序不能为空', trigger: 'blur'}
                ],
                type: [
                    {required: true, message: '类别不能为空', trigger: 'change'}
                ],
                amount: [
                    {required: true, message: '金额不能为空', trigger: 'blur'}
                ],
                status: [
                    {required: true, message: '状态不能为空', trigger: 'change'}
                ],
            }
        }
    },
    computed: {
        apiParams() {
            return {
                pageSize: this.pagination.pageSize,
                pageNum: this.pagination.currentPage,
            }
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        async init () {
            this.loading = true
            try {
                const {data} = await sellMoney(this.apiParams)
                if (data.code === statusCode.success) {
                    if (data.data && data.data.length) {
                        this.tableData = data.data
                    } else {
                        this.tableData = []
                    }
                }
                this.loading = false
            } catch (err) {
                console.log('error')
            }
        },
        // 状态切换
        statusSwitch (row) {
            this.form = {
                id: row.id,
                sort: row.sort,
                type: row.type,
                amount: row.amount,
                status: row.status
            }
            this.popType = 'edit'
            this.submit()
        },
        // 添加弹窗
        handlerAdd (type) {
            this.form = {}
            this.dialogFormVisible = true
            this.popType = 'add'
        },
        // 编辑弹窗
        handlerEdit (row) {
            this.form = {
                id: row.id,
                sort: row.sort,
                type: row.type,
                amount: row.amount,
                status: row.status
            }
            this.dialogFormVisible = true
            this.popType = 'edit'
        },
        // 表单验证
        formValidated (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) { // 验证通过
                    this.submit()
                }
            });
        },
        // 提交
        async submit() {
            const jsonData = {
                sort: this.form.sort,
                type: this.form.type,
                amount: this.form.amount,
                status: this.form.status
            }
            let request = null,message = '操作成功', type = 'success', duration = 2000
            if (this.popType === 'add') {
                request = addSellMoney
            } else {
                request = editSellMoney
                jsonData['id'] = this.form.id
            }
            const { data } = await request(jsonData)
            if (data.code === statusCode.success) {
                this.dialogFormVisible = false
                await this.init()
            } else {
                message = data.msg, type = 'warning'
            }
            this.$message({
                message,
                type,
                duration
            })
        },
        // 删除
        handlerDel (row) {
            this.$confirm('确认删除吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data } = await delSellMoney({id:row.id})
                if (data.code === statusCode.success) {
                this.$message({
                    message: data.data,
                    type: 'success',
                    duration: 2000
                });
                }
                this.init()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
#top-up-setting {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .search {
        margin-bottom: 20px;

        .date {
            margin-right: 15px;
        }

        .addBtn {
            margin-left: 15px;
        }
    }

    .table {
        height: calc(100% - 100px);
    }

    .pagin {
        margin-top: 10px;
    }
}

// 表格
/deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
}


/deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}


//弹窗
/deep/ .el-dialog {
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
</style>