<template>
  <div id="customerList">
    <div class="filter-box" style="display: flex">
      <SearchInput :search.sync="search" placeholder="有料标题/发布人" @search="searchData" />
      <div>
        <el-select v-model="status" placeholder="请选择" @change="init" clearable>
          <el-option label="全部" :value="0">全部</el-option>
          <el-option label="已发售" :value="1">已发售</el-option>
          <el-option label="待审核" :value="2">待审核</el-option>
          <el-option label="未通过" :value="3">未通过</el-option>
        </el-select>
      </div>
      <el-date-picker format="yyyy-MM-dd" style="margin-left: 20px" v-model="dataRange" type="daterange"
        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
      </el-date-picker>
    </div>
    <div class="table">
      <el-table :row-style="{ height: '40px' }" :header-row-style="{ height: '40px' }" border v-loading="loading"
        ref="multipleTable" tooltip-effect="dark" :data="tableData" height="100%" style="width: 100%">
        <el-table-column type="index" width="60" align="center" label="序号" />
        <el-table-column prop="title"  align="center" label="有料标题" />
        <el-table-column prop="type"  align="center" label="类别">
          <template slot-scope="scope">
            <!-- 1 - 足球，2 - 篮球   -->
            <div>{{ scope.row.type == 1 ? '足球' : '篮球' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="coin" align="center" label="方案售价">
          <template slot-scope="scope">
            <div>{{ scope.row.coin == 0 ? "免费" : scope.row.coin }}</div>
          </template>
        </el-table-column>
        <table-one-empty prop="url"  align="center" label="链接地址" />
        <table-one-empty prop="create_time" align="center" label="申请时间" />
        <el-table-column prop="status"  align="center" label="状态">
          <template slot-scope="scope">
            <!-- 1 - 已发售，2 - 待审核，3 - 未通过，4 - 已核实，5 - 待核实   -->
            <div :style="scope.row.status == 1 ? 'color:#67c23a' : scope.row.status == 3 ? 'color:#f56c6c' : 'color:#409eff'">
              {{ scope.row.status == 1 ? '已发售' :
                scope.row.status == 2 ? '待审核' :
                  scope.row.status == 3 ? '未通过' :
                    scope.row.status == 4 ? '已核实' : '待核实'
              }}
            </div>
          </template>
        </el-table-column>
        <table-one-empty prop="review_time"  align="center" label="处理时间" />
        <table-one-empty prop="author"  align="center" label="发布人" />
        <table-one-empty prop="edit_by"  align="center" label="操作人" />
        <el-table-column prop="opt" align="center" label="操作">
          <template slot-scope="scope">
            <el-button v-if="type != 2" @click="handleEdit(scope.$index, scope.row)" type="primary">编辑</el-button>
            <div v-else>-</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination block">
      <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
        :total="pagination.total" @loadData="init" />
    </div>
    <el-dialog :close-on-click-modal="false" title="编辑/修改" :visible.sync="dialogFormVisible" width="35%">
      <el-form :model="form">
        <el-form-item label="发布人" :label-width="formLabelWidth">
          <el-input v-model="form.author" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="有料标题" :label-width="formLabelWidth">
          <el-input v-model="form.title" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="类别" :label-width="formLabelWidth">
          <el-radio v-model="form.type" :label="1" disabled>足球</el-radio>
          <el-radio v-model="form.type" :label="2" disabled>篮球</el-radio>
        </el-form-item>
        <el-form-item label="选择赛事" :label-width="formLabelWidth">
          <el-date-picker v-model="day" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" @change="getMatches" disabled>
          </el-date-picker>
        </el-form-item>
        <div class="flex">
          <el-form-item label="联赛名称" :label-width="formLabelWidth">
            <el-select v-model="form.league_id" placeholder="请选择" disabled>
              <el-option :label="item.nameChsShort" :value="item.leagueId" v-for="item in selectLeaguesData"
                :key="item.leagueId">{{ item.nameChsShort }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="比赛" :label-width="formLabelWidth">
            <el-select v-model="form.match_id" placeholder="请选择" disabled>
              <el-option :label="`${item.homeChs}-${item.awayChs}`" :value="item.matchId" v-for="item in scheduleData"
                :key="item.matchId">{{ `${item.homeChs}-${item.awayChs}` }}</el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="玩法" :label-width="formLabelWidth">
          <el-radio v-model="form.play" :label="form.type == 1 ? 11 : 21" disabled>{{ form.type == 1 ? '胜负平' : '胜负' }}</el-radio>
          <el-radio v-model="form.play" :label="form.type == 1 ? 12 : 22" disabled>{{ form.type == 1 ? '让球' : '让分' }}</el-radio>
          <el-radio v-model="form.play" :label="form.type == 1 ? 13 : 23" disabled>{{ form.type == 1 ? '大小' : '总分' }}</el-radio>
        </el-form-item>
        <!-- 11 - 胜负平，12 - 让球，13 - 大小。篮球玩法：21 -胜负，22 - 让分，23 - 总分   -->
<!-- 选择了玩法 && 选择了比赛 -->
        <!-- 编辑 -->
        <el-form-item v-if="form.play && form.match_id" :label="form.play == 11 ? '胜负平' :
          form.play == 12 ? '让球' :
            form.play == 13 ? '大小' :
              form.play == 21 ? '胜负' :
                form.play == 22 ? '让分' : '总分'" :label-width="formLabelWidth">
          <!-- 足球胜负平 odds_europe-->
          <el-radio-group v-model="form.odds" v-if="form.play == 11">
            <el-radio :label="`主胜${form.odds_europe.init_home_win_odds}`" disabled>主胜{{ form.odds_europe.init_home_win_odds }}</el-radio>
            <el-radio :label="`平${form.odds_europe.init_tie_odds}`" disabled>平{{ form.odds_europe.init_tie_odds }}</el-radio>
            <el-radio :label="`客胜${form.odds_europe.init_away_win_odds}`" disabled>客胜{{ form.odds_europe.init_away_win_odds }}</el-radio>
          </el-radio-group>
          <!-- 足球让球  odds_handicap-->
          <el-radio-group v-model="form.odds" v-if="form.play == 12">
            <el-radio :label="`主胜${form.odds_handicap.init_home_odds}`" disabled>主胜{{ form.odds_handicap.init_home_odds }}</el-radio>
            <el-radio :label="`让${form.odds_handicap.init_odds}`" disabled>让{{ form.odds_handicap.init_odds }}</el-radio>
            <el-radio :label="`客胜${form.odds_handicap.init_away_odds}`" disabled>客胜{{ form.odds_handicap.init_away_odds }}</el-radio>
          </el-radio-group>
          <!-- 足球大小  odds_over_under-->
          <el-radio-group v-model="form.odds" v-if="form.play == 13">
            <el-radio :label="`大${form.odds_over_under.init_over_odds}`" disabled>大{{ form.odds_over_under.init_over_odds }}</el-radio>
            <el-radio :label="form.odds_over_under.init_odds" disabled>{{ form.odds_over_under.init_odds }}</el-radio>
            <el-radio :label="`小${form.odds_over_under.init_under_odds}`" disabled>小{{ form.odds_over_under.init_under_odds }}</el-radio>
          </el-radio-group>
          <!-- 篮球胜负  odds_moneyline-->
          <el-radio-group v-model="form.odds" v-if="form.play == 21">
            <el-radio :label="`主胜${form.odds_moneyline.init_home_win_odds}`" disabled>主胜{{ form.odds_moneyline.init_home_win_odds }}</el-radio>
            <el-radio :label="`客胜${form.odds_moneyline.init_away_win_odds}`" disabled>客胜{{ form.odds_moneyline.init_away_win_odds }}</el-radio>
          </el-radio-group>
          <!-- 篮球让分  odds_spread-->
          <el-radio-group v-model="form.odds" v-if="form.play == 22">
            <el-radio :label="`主胜${form.odds_spread.init_home_odds}`" disabled>主胜{{ form.odds_spread.init_home_odds }}</el-radio>
            <el-radio :label="`让${form.odds_spread.init_odds}`" disabled>让{{ form.odds_spread.init_odds }}</el-radio>
            <el-radio :label="`客胜${form.odds_spread.init_away_odds}`" disabled>客胜{{ form.odds_spread.init_away_odds }}</el-radio>
          </el-radio-group>
          <!-- 篮球大小  odds_total-->
          <el-radio-group v-model="form.odds" v-if="form.play == 23">
            <el-radio :label="`大${form.odds_total.init_over_odds}`" disabled>大{{ form.odds_total.init_over_odds }}</el-radio>
            <el-radio :label="form.odds_total.init_odds" disabled>{{ form.odds_total.init_odds }}</el-radio>
            <el-radio :label="`小${form.odds_total.init_under_odds}`" disabled>小{{ form.odds_total.init_under_odds }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="方案售价" :label-width="formLabelWidth">
          <el-input v-model="form.coin" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="链接地址" :label-width="formLabelWidth">
          <el-input v-model="form.url" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="备注说明" :label-width="formLabelWidth">
          <el-input v-model="form.remark" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="推荐理由" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="form.recommend" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="审核" :label-width="formLabelWidth">
          <!-- 1 - 已发售，2 - 待审核，3 - 未通过，4 - 已核实，5 - 待核实   -->
          <el-radio v-model="form.status" :label="1">通过</el-radio>
          <el-radio v-model="form.status" :label="3">拒绝</el-radio>
        </el-form-item>
        <el-form-item label="拒绝理由" :label-width="formLabelWidth" v-if="form.status == 3">
          <el-input type="textarea" v-model="form.reason"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit()">提交</el-button>
        <el-button @click="dialogFormVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchInput from "@/components/common/SearchInput";
import TableOneEmpty from "@/components/common/tableOneEmpty.vue";
import Pagination from "@/pages/Layout/Pagination";
import { getHaveAuditList, editHaveAudit, oddsList } from "@/api/haveManage";
import { statusCode } from "@/util/statusCode";
import dayjs from 'dayjs'
import { getLeagues, getMatchesByDay } from '@/api/Host/Host'
export default {
  components: {
    SearchInput,
    TableOneEmpty,
    Pagination,
  },
  data() {
    return {
      search: "",
      dataRange: "",
      type: '', // ''全部，1足球，2篮球
      status: 0,  // 0全部，1已发售，2待审核，3未通过
      loading: false,
      tableData: [],
      dialogFormVisible: false,
      formLabelWidth: '100px',
      total: 0,
      pagination: {
        currentPage: 1,
        pageSize: 20,
        total: 0,
      },
      selectLeaguesData: [],
      scheduleData: [],
      day: dayjs().format('YYYY-MM-DD'),
      form: {
        odds_europe: { //足球胜负
          init_home_win_odds: '',
          init_tie_odds: '',
          init_away_win_odds: ''
        },
        odds_handicap: { // 足球让球
          init_home_odds: '',
          init_odds: '',
          init_away_odds: ''
        },
        odds_over_under: { // 足球大小
          init_over_odds: '',
          init_odds: '',
          init_under_odds: ''
        },
        odds_moneyline: { // 篮球胜负
          init_home_win_odds: '',
          init_away_win_odds:''
        },
        odds_spread: { // 篮球让分
          init_home_odds: '',
          init_odds: '',
          init_away_odds: ''
        },
        odds_total: { // 篮球大小
          init_over_odds: '',
          init_odds: '',
          init_under_odds:''
        }
      },
    };
  },
  watch: {
    dataRange(newVal, oldVal) {
      this.pagination.pageSize = 20
      this.pagination.currentPage = 1
      this.init()
    }
  },
  computed: {
    apiParams() {
      return {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        search: this.search,
        status: this.status,
        start_date: this.dataRange && this.dataRange[0] ? dayjs(this.dataRange[0]).format('YYYY-MM-DD') : '',
        end_date: this.dataRange && this.dataRange[1] ? dayjs(this.dataRange[1]).format('YYYY-MM-DD') : '',
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      try {
        let { data } = await getHaveAuditList(this.apiParams)
        if (data.code === statusCode.success) {
          this.total = data.total
          this.pagination.total = data.total
          if (data.rows && data.rows.length) {
            this.tableData = JSON.parse(JSON.stringify(data.rows))
          } else {
            this.tableData = []
          }
          this.loading = false
        }
      } catch (e) {
        console.log('error--error')
      }
    },
    searchData() {
      this.pagination.pageSize = 20
      this.pagination.currentPage = 1
      this.init()
    },
    handleEdit(index, row) {
      this.dialogFormVisible = true
      this.form = {
        id: row.id,
        author: row.author,
        title: row.title,
        type: row.type,
        play: row.play,
        league_id: row.league_id,
        match_id: row.match_id,
        coin: row.coin,
        url: row.url,
        remark: row.remark,
        recommend: row.recommend,
        status: row.status,
        reason: row.reason,
        odds: row.odds,
        odds_europe: row.odds_europe, // 足球：胜负平
        odds_handicap: row.odds_handicap, // 足球：让球
        odds_over_under: row.odds_over_under, // 足球： 大小
        odds_moneyline: row.odds_moneyline, //篮球：胜负
        odds_spread: row.odds_spread, //篮球：让分
        odds_total: row.odds_total // 篮球：大小
      }
      this.day = dayjs(row.matchTime).format('YYYY-MM-DD')
      this.getleagues()
      this.getMatches()
      // this.getOddsList()
    },
    async getleagues() { //联赛获取
      //type： 1足球 2篮球 3电竞 4其他
      let { data } = await getLeagues(this.form.type,dayjs(this.day).format('YYYY-MM-DD'))
      this.selectLeaguesData = data.data
    },
    async getMatches() { //比赛获取
      // leagueType:1足球，2篮球
      let dataJson = {
        day: dayjs(this.day).format('YYYY-MM-DD'),
        leagueId: this.form.league_id,
        leagueType: this.form.type
      }
      let { data } = await getMatchesByDay(dataJson)
      this.scheduleData = data.data
    },
    async getOddsList() { //赔率获取
      let dataJson = {
        match_id: this.form.match_id,
        type: this.form.type
      }
      let { data } = await oddsList(dataJson)
      this.oddsListData = data.data
    },
    async submit() {
      let dataJson = {
        id: this.form.id,
        status: this.form.status,
        reason: this.form.reason
      }
      let { data } = await editHaveAudit(dataJson)
      let msg = ""
      let type = "success"
      if (data.code === statusCode.success) {
        msg = "操作成功"
        this.dialogFormVisible = false
        await this.init()
      } else {
        msg = data.msg
        type = 'warning'
      }
      this.$message({
        message: msg,
        type,
        duration: 2000
      })
    }
  },
};
</script>

<style lang="scss" scoped>
#customerList {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .filter-box {
    margin-bottom: 20px;
  }

  .pagination {
    margin-top: 10px;
  }

  .table {
    height: calc(100% - 100px);
  }
}
</style>

<style lang="scss" scoped>
.btnAdd {
  margin-left: 15px;
}

/deep/ .el-table .descending .sort-caret.descending {
  border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
  border-bottom-color: #189e90;
}

/deep/ .el-table tr {
  cellspacing: 10px;
  border: 1px solid #cccccc;
  padding: 15px 0;
}

/deep/ .el-table__body {
  border-collapse: separate;
  //border-spacing: 0 10px;
  background-color: #f6f6f6;
}

/deep/ .el-table th.el-table__cell>.cell {
  color: #6d6d6e;
  font-size: 14px;
}

/deep/ .el-dialog {
  //弹窗
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0e3f6a;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #b5b5b5;
}
</style>