
const url = {
    //有料审核
    haveAuditList: '/admin/material/verifyList',  //有料审核列表
    editHaveAudit: '/admin/material/verify',  //有料审核编辑
    //有料列表
    haveList: '/admin/material/list', //有料列表
    addHaveList: '/admin/material/add',  //有料列表添加
    editHaveList: '/admin/material/edit', //有料列表编辑
    haveSellMoney: '/admin/expert/payPrice', // 有料售卖金额
    getOddsLeagues: '/admin/odds/leagues', // 有料联赛列表
    getOddsSchedule: '/admin/schedule/getScheduleByDay', // 有料赛事列表
    //专家列表
    expertList: '/admin/expert/list', //专家列表
    addExpertList: '/admin/expert/add', //专家列表添加
    editExpertList: '/admin/expert/edit', //专家列表编辑
    switchStatus: '/admin/expert/switchStatus', //专家列表状态
    //赔率
    oddsList: '/admin/odds/list', //赔率列表
    //有料设置
    sellMoney: '/admin/material/materialConfig', //售卖金额列表
    addSellMoney: '/admin/material/addMaterialConfig', //添加售卖金额
    editSellMoney: '/admin/material/editMaterialConfig', //编辑售卖金额
    delSellMoney: '/admin/material/deleteMaterialConfig', //删除售卖金额
    // 有料消费记录
    haveDetail: '/admin/material/purchaseList' //有料消费记录列表

}

export default url
