<template>
  <el-button class="button">{{ title }}</el-button>
</template>

<script>
export default {
  name: "CommonButton",
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  line-height: 33px;
  height: 33px;
  padding: 0 15px;
  color: #fff;
  background-color: #0E3F6A;
  &:hover, &:focus {
    opacity: 0.7;
    background-color: #0E3F6A;
    color: #fff;
  }
}
</style>
