<template>
    <div id="top-up-setting">
        <div class="search flex">
            <CommonButton @click.native="handlerAdd" class="btnAdd" title="新增" />
            <CommonButton @click.native="handlerRatio" class="btnAdd" :title="`当前比例1:${ratio}`" />
        </div>
        <div class="table">
            <el-table v-loading="loading" :data="tableData" height="100%" style="width:100%" :row-style="{ height: '40px' }"
                :header-row-style="{ height: '40px' }" border>
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column label="名称" prop="name" align="center" />
                <el-table-column label="价格(CNY)" prop="amount" align="center" />
                <el-table-column label="球币" prop="ball_coin" align="center" />
                <el-table-column label="状态" prop="status" align="center">
                    <template slot-scope="scope">
                        <el-switch
                        v-model="scope.row.status"
                        :active-value="1"
                        :inactive-value="2"
                        @change="statusSwitch(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="create_time" align="center" />
                <el-table-column label="更新时间" prop="update_time" align="center" />
                <el-table-column label="操作人" prop="edit_by" align="center" />
                <el-table-column label="操作" prop="" align="center" width="160">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" @click="handlerEdit(scope.row)">编辑</el-button>
                        <el-button size="small" type="danger" @click="handlerDel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- <div class="pagin">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div> -->
        <el-dialog :close-on-click-modal="false" title="新增/编辑" :visible.sync="dialogFormVisible"
        width="35%">
            <el-form :model="form" :rules="rules" ref="formName">
                <el-form-item label="名称" prop="name" :label-width="formLabelWidth">
                    <el-input v-model="form.name" />
                </el-form-item>
                <el-form-item label="价格" prop="amount" :label-width="formLabelWidth">
                    <el-input v-model="form.amount" />
                </el-form-item>
                <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.status">
                        <el-radio :label="1">启用</el-radio>
                        <el-radio :label="2">禁用</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="formValidated('formName')">提交</el-button>
                <el-button @click="dialogFormVisible = false">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" title="比例调整" :visible.sync="ratioDialogFormVisible" width="35%">
            <el-form :model="ratioForm" :rules="ratioRules" ref="ratioForm">
                <el-form-item label="当前比例" :label-width="formLabelWidth">
                    <el-input v-model="ratioForm.ratio" disabled></el-input>
                </el-form-item>
                <el-form-item label="最新比例" prop="newRatio" :label-width="formLabelWidth">
                    <el-input v-model="ratioForm.newRatio" placeholder="请输入最新比例"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="ratioSubmit('ratioForm')">提交</el-button>
                <el-button @click="ratioDialogFormVisible = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput.vue';
import CommonButton from "@components/common/CommonButton";
import CommonUpload from '@/components/common/CommonUpload';
import Pagination from '@/pages/Layout/Pagination.vue';
import {topUpConfig,addTopUpConfig,editTopUpConfig,delTopUpConfig,getRatio,setRatio} from '@/api/topUp'
import {statusCode} from '@/util/statusCode'
export default {
    components: {
        SearchInput,
        CommonButton,
        CommonUpload,
        Pagination
    },
    data () {
        return {
            order: '',
            dateRange: '',
            type: '',
            loading: false,
            tableData: [],
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            formLabelWidth: '100px',
            form: {},
            dialogFormVisible: false,
            popType: 'add', // add || edit
            rules: {
                name: [
                    {required: true, message: '请输入名称', trigger: 'blur'}
                ],
                amount: [
                    {required: true, message: '请输入价格', trigger: 'blur'}
                ],
                status: [
                    {required: true, message: '请选择状态', trigger: 'change'}
                ],
            },
            ratio: null,
            ratioDialogFormVisible: false,
            ratioForm: {
                newRatio: null
            },
            ratioRules: {
                newRatio: [
                    {required: true, message: '请输入最新比例', trigger: 'blur'}
                ]
            }
        }
    },
    mounted () {
        this.init()
        this.getRatioList()
    },
    methods: {
        async init () {
            this.loading = true
            try {
                const {data} = await topUpConfig()
                if (data.code === statusCode.success) {
                    if (data.data && data.data.length) {
                        this.tableData = data.data
                    } else {
                        this.tableData = []
                    }
                }
                this.loading = false
            } catch (err) {
                console.log('出现错误')
            }
        },
        // 获取球币比例
        async getRatioList () {
            const { data } = await getRatio()
            if (data.code === statusCode.success) {
                this.ratio = data.data.ratio
            } else {
                console.log(data.msg)
            }
        },
        // 球币比例
        handlerRatio () {
            this.ratioForm.ratio = this.ratio
            this.ratioDialogFormVisible = true
        },
        // 球币比例提交
        ratioSubmit (formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const jsonData = {
                        ratio: this.ratioForm.newRatio
                    }
                    const { data } = await setRatio(jsonData)
                    if (data.code === statusCode.success) {
                        this.init()
                        this.getRatioList()
                        this.ratioDialogFormVisible = false
                        this.$message({message: '操作成功', type: 'success'})
                    } else {
                        this.$message({message: data.msg, type: 'warning'})
                    }
                }
            })
        },
        // 状态切换
        statusSwitch (row) {
            this.form = {
                id: row.id,
                name: row.name,
                amount: row.amount,
                status: row.status
            }
            this.popType = 'edit'
            this.submit()
        },
        // 新增
        handlerAdd () {
            this.form = {}
            this.popType = 'add'
            this.dialogFormVisible = true
        },
        // 编辑
        handlerEdit (row) {
            this.form = {
                id: row.id,
                name: row.name,
                amount: row.amount,
                status: row.status
            }
            this.popType = 'edit'
            this.dialogFormVisible = true
        },
        // 表单验证
        formValidated (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submit()
                }
            })
        },
        // 提交
        async submit() {
            const jsonData = {
                name: this.form.name,
                amount: this.form.amount,
                status: this.form.status
            }
            let request = null,message = '操作成功', type = 'success'
            if (this.popType === 'add') {
                request = addTopUpConfig
            } else {
                jsonData['id'] = this.form.id
                request = editTopUpConfig
            }
            const { data } = await request(jsonData)
            if (data.code === statusCode.success) {
                this.init()
                this.dialogFormVisible = false
            } else {
                message = data.msg, type = 'warning'
            }
            this.$message({
                message,
                type
            })
        },
        // 删除
        handlerDel (row) {
            this.$confirm('确认删除吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data } = await delTopUpConfig({id:row.id})
                if (data.code === statusCode.success) {
                    this.$message({
                        message: data.data,
                        type: 'success',
                        duration: 2000
                    });
                }
                this.init()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
#top-up-setting {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .search {
        margin-bottom: 20px;

        .date {
            margin-right: 15px;
        }

        .addBtn {
            margin-left: 15px;
        }
    }

    .table {
        height: calc(100% - 100px);
    }

    .pagin {
        margin-top: 10px;
    }
}

// 表格
/deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
}


/deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}


//弹窗
/deep/ .el-dialog {
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
</style>