<template>
  <el-table-column :prop="prop" :align="align" :label="label" :width="width" :show-overflow-tooltip="showOverflowTooltip">
    <template slot-scope="scope">
      <span v-if="scope.row[prop]">{{ scope.row[prop] }}</span>
      <span v-else>-</span>
    </template>
  </el-table-column>
</template>

<script>
  export default {
    name: 'MyTableColumn',
    props: {
      scopedSlot: { // 自定义列的内容
        type: String,
        default: '',
      },
      label: String,  // 列的标题
      columnName: String,  // 列的字段名
      prop: {  // prop 为表格列的字段名
        type: [String, Array, Object, Boolean, Number, Function],
      },
      align: {  // 对齐方式
        type: String,
        default: 'center',
      },

      showOverflowTooltip: {  // 是否显示 tooltip
        type: Boolean,
        default: false,
      },
      width: {
        type: [String, Number],
        default: ''
      }
    },
  };
</script>
