const url = {
    login: '/admin/login/login',
    userInfo: '/api/v1/user/info',


}






export default url
