<template>
    <div id="payment-channel">
        <div class="search flex">
            <search-input :search.sync="search" placeholder="支付ID/支付品牌" @search="searchData" />
            <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" class="date">
            </el-date-picker>
            <CommonButton @click.native="handlerAdd" class="btnAdd" title="新增" />
        </div>
        <div class="table">
            <el-table v-loading="loading" :data="tableData" height="100%" style="width:100%" :row-style="{ height: '40px' }"
                :header-row-style="{ height: '40px' }" border>
                <el-table-column label="支付通道ID" prop="pay_channel_id" align="center" />
                <el-table-column label="支付品牌" prop="pay_brand" align="center" />
                <el-table-column label="支付通道名称" prop="pay_channel_name" align="center" />
                <el-table-column label="支付通道icon" prop="icon" align="center">
                    <template slot-scope="scope">
                        <el-image
                            style="max-width: 32px;max-height: 32px"
                            :src="scope.row.icon"
                            :preview-src-list="[scope.row.icon,scope.row.icon]">
                        </el-image>
                    </template>
                </el-table-column>
                <table-one-empty label="备注" prop="remark" align="center"/>
                <el-table-column label="创建时间" prop="create_time" align="center" />
                <el-table-column label="最后修改时间" prop="update_time" align="center" />
                <el-table-column label="状态" prop="status" align="center">
                    <template slot-scope="scope">
                        <el-switch
                        v-model="scope.row.status"
                        :active-value="1"
                        :inactive-value="2"
                        @change="switchChange(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作人" prop="edit_by" align="center" />
                <el-table-column label="操作" prop="" align="center" width="160">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" @click="handlerEdit(scope.row)">编辑</el-button>
                        <el-button size="small" type="danger" @click="handlerDel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagin">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div>
        <el-dialog :close-on-click-modal="false" title="新增/编辑" :visible.sync="dialogFormVisible"
        width="35%" :before-close="handlerCancel">
            <el-form :model="form" :rules="rules" ref="formName">
                <el-form-item label="支付通道ID" prop="pay_channel_id" :label-width="formLabelWidth">
                    <el-input v-model="form.pay_channel_id" />
                </el-form-item>
                <el-form-item label="支付品牌" prop="pay_brand" :label-width="formLabelWidth">
                    <el-input v-model="form.pay_brand" />
                </el-form-item>
                <el-form-item label="支付通道名" prop="pay_channel_name" :label-width="formLabelWidth">
                    <el-input v-model="form.pay_channel_name" />
                </el-form-item>
                <el-form-item label="支付通道icon" :label-width="formLabelWidth">
                    <CommonUpload :icon.sync="form.icon" type="pay-channel" />
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input v-model="form.remark" type="textarea"  :rows="4" />
                </el-form-item>
                <el-form-item label="状态" :label-width="formLabelWidth">
                    <template>
                        <el-switch
                        v-model="form.status"
                        :active-value="1"
                        :inactive-value="2">
                        </el-switch>
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="formValidate">提交</el-button>
                <el-button @click="handlerCancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput.vue';
import CommonButton from "@components/common/CommonButton";
import CommonUpload from '@/components/common/CommonUpload';
import Pagination from '@/pages/Layout/Pagination.vue';
import TableOneEmpty from "@/components/common/tableOneEmpty.vue";
import { getPayChannelList, addPayChannel, editPayChannel, delPayChannel } from '@/api/topUp'
import { statusCode } from '@/util/statusCode'
import dayjs from 'dayjs';
export default {
    components: {
        SearchInput,
        CommonButton,
        CommonUpload,
        Pagination,
        TableOneEmpty
    },
    data () {
        return {
            search: '',
            dateRange: '',
            type: '',
            loading: false,
            tableData: [],
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            formLabelWidth: '100px',
            form: {},
            dialogFormVisible: false,
            popType: 'add', // 'add' || 'edit'
            rules: {
                pay_channel_id: [
                    {required: true, message: '请输入支付通道ID', trigger: 'blur'}
                ],
                pay_brand: [
                    {required: true, message: '请输入支付品牌', trigger: 'blur'}
                ],
                pay_channel_name: [
                    {required: true, message: '请输入支付品牌名称', trigger: 'blur'}
                ],
            }
        }
    },
    mounted () {
        this.init()
    },
    watch: {
        dateRange () {
            this.init()
        }
    },
    computed: {
        apiParams () {
            return {
                pageNum: this.pagination.currentPage,
                pageSize: this.pagination.pageSize,
                search: this.search,
                start_date: this.dateRange && this.dateRange[0] ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : '',
                end_date: this.dateRange && this.dateRange[1] ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : '',
            }
        }
    },
    methods: {
        async init () {
            this.loading = true
            try {
                const { data } = await getPayChannelList(this.apiParams)
                if (data.code === statusCode.success) {
                    this.pagination.total = data.total
                    this.tableData = data.rows
                } else {
                    this.$message({message: data.msg, type: 'warning'})
                }
            } catch (e) {
                console.log('出现错误')
            } finally {
                this.loading = false
            }
        },
        searchData () {
            this.pagination.currentPage = 1
            this.pagination.pageSize = 20
            this.init()
        },
        handlerAdd () {
            this.popType = 'add'
            this.dialogFormVisible = true
        },
        handlerEdit (row) {
            this.popType = 'edit'
            this.dialogFormVisible = true
            this.$nextTick(() => {
                this.form = {
                    id: row.id,
                    pay_channel_id: row.pay_channel_id,
                    pay_brand: row.pay_brand,
                    pay_channel_name: row.pay_channel_name,
                    icon: row.icon,
                    remark: row.remark,
                    status: row.status
                }
            })
        },
        handlerCancel () {
            this.$refs['formName'].resetFields()
            this.dialogFormVisible = false
            this.form = {}
        },
        switchChange (row) {
            this.popType = 'edit'
            this.form = {
                id: row.id,
                pay_channel_id: row.pay_channel_id,
                pay_brand: row.pay_brand,
                pay_channel_name: row.pay_channel_name,
                icon: row.icon,
                remark: row.remark,
                status: row.status
            }
            this.submit()
        },
        formValidate () {
            this.$refs['formName'].validate((valid) => {
                if (valid) {
                    this.submit()
                }
            })
        },
        async submit () {
            const jsonData = {
                pay_channel_id: this.form.pay_channel_id,
                pay_brand: this.form.pay_brand,
                pay_channel_name: this.form.pay_channel_name,
                icon: this.form.icon,
                remark: this.form.remark,
                status: this.form.status
            }
            let request, message = '操作成功', type = 'success'
            if (this.popType === 'add') {
                request = addPayChannel
            } else {
                request = editPayChannel
                jsonData['id'] = this.form.id
            }
            const { data } = await request(jsonData)
            if (data.code === statusCode.success) {
                this.dialogFormVisible = false
                this.init()
            } else {
                message = data.msg, type = 'warning'
            }
            this.$message({ message, type })
        },
        handlerDel (row) {
            this.$confirm('确认删除吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data } = await delPayChannel({id:row.id})
                if (data.code === statusCode.success) {
                this.$message({
                    message: data.data,
                    type: 'success',
                    duration: 2000
                });
                }
                this.init()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
#payment-channel {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .search {
        margin-bottom: 20px;

        .date {
            margin-right: 15px;
        }

        .addBtn {
            margin-left: 15px;
        }
    }

    .table {
        height: calc(100% - 100px);
    }

    .pagin {
        margin-top: 10px;
    }
}

// 表格
/deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
}


/deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}


//弹窗
/deep/ .el-dialog {
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
</style>