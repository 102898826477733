export default {
    loginInfo(state, info) {
        state.userInfo = info.userInfo
        state.token = info.token
        console.log(state)
    },
    menuList(state, tem) {
        let flag = true
        if (flag) {
            state.menuList.some((item, index) => {
                if (item.path == tem.path) {
                    flag = false
                    return false
                }
            })
        }
        if (flag) {
            state.menuList.push(tem)
            if(state.menuList.length > 20){
                state.menuList.shift()
            }
        }
    },
    delMenu(state, tem) {
        state.menuList.some((item, index) => {
            if (item.path == tem.path) {
                state.menuList.splice(index, 1)
                return true
            }
        })
    }
}
