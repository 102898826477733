<template>
    <div id="customerList">
        <div class="filter-box" style="display: flex">
            <CommonButton @click.native="dialogFormVisible = true" class="btnAdd" title="添加黑名单"/>
        </div>
        <div class="table">
            <el-table
                :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
                :cell-style="setSellStyle"
                v-loading="loading"
                ref="multipleTable"
                tooltip-effect="dark"
                :data="tableData"
                height="100%"
                style="width: 100%">
                <el-table-column type="index" width="80" align="center" label="序号"></el-table-column>
                <el-table-column prop="id" align="center" show-overflow-tooltip label="id"></el-table-column>
                <el-table-column prop="create_time" align="center" show-overflow-tooltip
                                 label="创建时间"></el-table-column>
                <el-table-column prop="ip" align="center" show-overflow-tooltip label="禁用ip"></el-table-column>
                <el-table-column prop="opt" align="center" label="解封" width="200">
                    <template slot-scope="scope">
                        <el-button @click="handleDelete(scope.row)">解封</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="pagination block">
            <pagination
                :current-page.sync="pagination.currentPage"
                :page-size.sync="pagination.pageSize"
                :total="pagination.total"
                @loadData="init"
            />
        </div>
        <el-dialog :close-on-click-modal="false" title="新增" :visible.sync="dialogFormVisible" width="35%">
            <el-form :model="form">
                <el-form-item label="ip" :label-width="formLabelWidth">
                    <el-input v-model="ip" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit(1)">提交</el-button>
                <el-button @click="dialogFormVisible = false">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" title="编辑" :visible.sync="dialogFormVisible1" width="35%">
            <el-form :model="form">
                <el-form-item label="热度值" :label-width="formLabelWidth">
                    <el-input v-model="hert" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit(2)">提交</el-button>
                <el-button @click="dialogFormVisible1 = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getAnchorList, addAnchor, updateAnchor, blackIpList, blackIp, blackIpDel} from "@/api/control";
    import {statusCode} from "@/util/statusCode";
    import SearchInput from "@components/common/SearchInput";
    import CommonButton from "@components/common/CommonButton";
    import Pagination from "@/pages/Layout/Pagination";
    import {Message} from "element-ui";

    export default {
        components: {SearchInput, CommonButton, Pagination},
        data() {
            return {
                search: '',
                hert: '',
                total: 0,
                ip: '',
                popupType: '',
                loading: true,
                selectList: [],
                dialogFormVisible: false,
                dialogFormVisible1: false,
                form: {
                    username: '',
                    nickname: '',
                    password: "",
                    member_id: ""
                },
                formLabelWidth: '100px',
                tableData: [],
                searchCon: [
                    {
                        key: "searchKey",
                        val: '',
                        pla: '请输入主播ID/手机号/昵称',
                        label: '请输入主播ID/手机号/昵称',
                        labelFlag: false
                    },
                ],
                pagination: {
                    currentPage: 1,
                    pageSize: 20,
                    total: 0
                }
            }
        },
        computed: {
            apiParams() {
                return {
                    pageSize: this.pagination.pageSize,
                    pageNum: this.pagination.currentPage,
                    searchKey: this.search
                }
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            async init() {
                this.loading = true
                try {
                    let {code, data} = await blackIpList(this.apiParams)
                    console.log(data)
                    if (data.code == statusCode.success) {
                        this.total = data.data.total
                        this.pagination.total = data.data.total
                        this.tableData = JSON.parse(JSON.stringify(data.data.data))
                        this.loading = false
                    }
                } catch (e) {
                    console.log('error--error')
                }
            },
            setSellStyle({columnIndex}) {
                if (columnIndex == 0) return "borderRadius: 10px  0 0 10px"
                if (columnIndex == 16) return "borderRadius: 0  10px 10px 0"
            },
            async handleDelete(row) {
                let {data} = await blackIpDel({ip: row.ip})
                if (data.code == statusCode.success) {
                    this.$message({
                        message: '解封成功',
                        type: 'success'
                    });
                    this.dialogFormVisible1 = false
                    await this.init()
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'warning'
                    });
                }
            },
            async submit(num) {
                console.log(this.ip)
                if (num === 1) {
                    let {data} = await blackIp({ip: this.ip})
                    let msg = ''
                    let type = 'success'
                    if (data.code === statusCode.success) {
                        msg = '操作成功'
                        this.dialogFormVisible = false
                        await this.init()
                    } else {
                        msg = data.msg
                        type = 'warning'
                    }
                    this.$message({
                        message: msg,
                        type,
                        duration: 2000
                    });
                }
            },
            async changeSwitch(item) {
                let msg = ''
                let type = 'success'
                let {data} = await updateAnchor({id: item.id, status: item.status == 1 ? 2 : 1})
                if (data.code === statusCode.success) {
                    msg = '操作成功'
                    this.dialogFormVisible = false
                    await this.init()
                } else {
                    msg = data.msg
                    type = 'warning'
                }
                Message({
                    message: msg,
                    type,
                    duration: 1000
                });
            },
            searchData() {
                this.pagination.pageSize = 20
                this.pagination.currentPage = 1
                this.init()
            }
        }
    }
</script>

<style scoped lang="scss">
    #customerList {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .filter-box {
            margin-bottom: 20px;
        }

        .pagination {
            margin-top: 10px;
        }

        .table {
            height: calc(100% - 100px);
        }
    }

    .btnAdd {
        margin-left: 15px;
    }

    /deep/ .el-table .descending .sort-caret.descending {
        border-top-color: #189e90;
    }

    /deep/ .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #189e90;
    }

    /deep/ .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
        //border: 0;
    }

    /deep/ .el-table tr {
        border: 1px solid #CCCCCC;
        padding: 15px 0;
    }

    /deep/ .el-table__body {
        border-collapse: separate;
        background-color: #f6f6f6;
    }


    /deep/ .el-table th.el-table__cell > .cell {
        color: #6d6d6e;
        font-size: 14px;
    }

    /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
        //padding: 0;
    }

    /deep/ .el-dialog { //弹窗
        width: 600px !important;
        border-radius: 20px;
    }

    /deep/ .el-dialog__header {
        background-color: #0E3F6A;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 20px;
    }

    /deep/ .el-dialog__title {
        color: #fff;
    }

    /deep/ .el-form-item__label {
        color: #000;
        font-size: 14px;
    }

    /deep/ .el-input__inner {
        border: 0;
        border-radius: 5px;
        border: 1px solid #B5B5B5;
    }
</style>
