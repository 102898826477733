import request from "@/api/request";
import url from "@/api/content/url";

export function getContent(id) {
    return request({
        method: "post",
        url: url.content,
        data: {
            id:id
        }
    })
}

export function editContent(data) {
    return request({
        method: "post",
        url: url.editContent,
        data
    })
}

export function addContent(data) {
    return request({
        method: "post",
        url: url.addContent,
        data
    })
}

export function getContentList() {
    return request({
        method: 'get',
        url: url.getContentList
    })
}

export function getSpeechList({ type, pageSize, pageNum}) {
    return request({
        method: 'post',
        url: url.getSpeechList,
        params: {
            type, pageSize, pageNum
        }
    })
}

export function addSpeech({ content, sort, type, status, jumpUrl, jumpType}) {
    return request({
        method: 'post',
        url: url.addSpeech,
        data: {
            content, sort, type, status,
            jump_url: jumpUrl,
            jump_type: jumpType
        }
    })
}

export function editSpeech({ id, content, sort, type, status, jumpUrl, jumpType}) {
    return request({
        method: 'post',
        url: url.editSpeech,
        data: {
            id,
            content, sort, type, status,
            jump_url: jumpUrl,
            jump_type: jumpType
        }
    })
}


export function deleteSpeech(id) {
    return request({
        method: 'post',
        url: url.deleteSpeech,
        data: {
            id
        }
    })
}


// 验证码历史记录

export function getCodeHistory(data) {
    return request({
        method: 'post',
        url: url.getCodeHistory,
        data
    })
}
