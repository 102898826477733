<template>
    <div id="cash-audit">
        <div class="search flex">
            <search-input :search.sync="member_id" placeholder="ID" @search="searchData" />
            <search-input :search.sync="order_no" placeholder="订单号" @search="searchData" />
            <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" class="date">
            </el-date-picker>
            <div>
                <el-select v-model="txn_type" placeholder="状态" @change="init">
                    <el-option label="全部" value="">全部</el-option>
                    <el-option label="会员充值" value="recharge">会员充值</el-option>
                    <el-option label="购买有料" value="material">购买有料</el-option>
                    <el-option label="赠送礼物" value="gift">赠送礼物</el-option>
                    <el-option label="任务活动" value="task">任务活动</el-option>
                    <el-option label="人工充值" value="manual-recharge">人工充值</el-option>
                    <el-option label="人工扣款" value="manual-deduction">人工扣款</el-option>
                </el-select>
            </div>
        </div>
        <div class="table">
            <el-table v-loading="loading" :data="tableData" height="100%" style="width:100%" :row-style="{ height: '40px' }"
                :header-row-style="{ height: '40px' }" border>
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column label="用户账号" prop="account" align="center" />
                <el-table-column label="用户昵称" prop="nickname" align="center" />
                <el-table-column label="用户ID" prop="member_id" align="center" />
                <el-table-column label="订单号" prop="order_no" align="center" />
                <el-table-column label="账变币种" prop="currency" align="center">
                    <template slot-scope="scope">
                        <span :style="{color: scope.row.currency === 1 ? '#409EFF' : '#67C23A'}">
                            {{ scope.row.currency === 1 ? '球币' : '球票' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="账变金额" prop="amount" align="center" />
                <el-table-column label="账变类型" prop="txn_type" align="center">
                    <template slot-scope="scope">
                        {{
                            scope.row.txn_type === 'recharge' ? '会员充值'
                                :scope.row.txn_type === 'material' ? '购买有料'
                                    :scope.row.txn_type === 'gift' ? '赠送礼物'
                                        :scope.row.txn_type === 'task' ? '任务活动'
                                            :scope.row.txn_type === 'manual-recharge' ? '人工充值'
                                                :scope.row.txn_type === 'manual-deduction' ? '人工扣款'
                                                    : ''
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="账变前余额" prop="balance_before" align="center" />
                <el-table-column label="账变后余额" prop="balance_after" align="center" />
                <table-one-empty label="时间" prop="txn_time" align="center" />
            </el-table>
        </div>
        <div class="pagin">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div>
    </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput.vue';
import Pagination from '@/pages/Layout/Pagination.vue';
import { getBillingList } from '@/api/topUp'
import dayjs from 'dayjs';
import { statusCode } from '@/util/statusCode'
import TableOneEmpty from '@/components/common/tableOneEmpty.vue';
export default {
    components: {
        SearchInput,
        Pagination,
        TableOneEmpty
    },
    data () {
        return {
            member_id: '',
            order_no: '',
            txn_type: '',
            dateRange: '',
            type: '',
            loading: false,
            tableData: [],
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            formLabelWidth: '100px',
            form: {},
            hidden: false,
            dialogFormVisible: false,
            rules: {
                oa_no: [
                    {required: true, message: '请输入OA单号', trigger: 'blur'}
                ],
            }
        }
    },
    computed: {
        apiParams () {
            return {
                pageNum: this.pagination.currentPage,
                pageSize: this.pagination.pageSize,
                member_id: this.member_id,
                order_no: this.order_no,
                txn_type: this.txn_type,
                start_date: this.dateRange && this.dateRange[0] ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : '',
                end_date: this.dateRange && this.dateRange[1] ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : '',
            }
        },
    },
    mounted () {
        this.init()
    },
    watch: {
        dateRange () {
            this.init()
        },
    },
    methods: {
        async init () {
            this.loading = true
            try {
                const { data } = await getBillingList(this.apiParams)
                if (data.code === statusCode.success) {
                    this.pagination.total = data.total
                    this.tableData = data.rows
                } else {
                    this.$message({message: data.msg, type: 'warning'})
                }
            } catch (e) {
                console.log('出现错误')
            } finally {
                this.loading = false
            }
        },
        searchData () {
            this.pagination.currentPage = 1
            this.pagination.pageSize = 20
            this.init()
        }
    }
}
</script>

<style lang="scss" scoped>
#cash-audit {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .search {
        margin-bottom: 20px;

        .date {
            margin-right: 15px;
        }

        .addBtn {
            margin-left: 15px;
        }
    }

    .table {
        height: calc(100% - 100px);
    }

    .pagin {
        margin-top: 10px;
    }
}

// 表格
/deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
}


/deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}


//弹窗
/deep/ .el-dialog {
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
</style>