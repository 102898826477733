<template>
  <div id="customerList">
    <div class="filter-box" style="display: flex">
      <SearchInput
        :search.sync="search"
        placeholder="昵称/ID"
        @search="searchData"
      />
      <div>
        <el-select v-model="status" placeholder="状态" @change="init" clearable>
          <el-option label="审核中" value="3">审核中</el-option>
          <el-option label="审核失败" value="2">审核失败</el-option>
          <el-option label="审核成功" value="1">审核成功</el-option>
        </el-select>
      </div>
      <el-date-picker
        format="yyyy-MM-dd"
        style="margin-left: 20px"
        v-model="dataRange"
        type="daterange"
        :picker-options="pickerOptions"
        range-separator="至"
        start-placeholder="提交时间"
        end-placeholder="结束时间"
        align="right">
      </el-date-picker>
    </div>
    <div class="table">
      <el-table
        :row-style="{height:'40px'}" :header-row-style="{height:'40px'}" border
        v-loading="loading"
        ref="multipleTable"
        tooltip-effect="dark"
        :data="tableData"
        height="100%"
        style="width: 100%">
        <el-table-column type="index" width="80" align="center" label="序号"></el-table-column>
        <table-one-empty prop="member_id"  align="center" label="会员ID"></table-one-empty>
        <table-one-empty prop="nickname"  align="center" label="会员昵称"></table-one-empty>
        <table-one-empty prop="name"  align="center" label="真实姓名"></table-one-empty>
        <table-one-empty prop="id_card"  align="center" label="证件号码"></table-one-empty>
        <table-one-empty prop="create_time" show-overflow-tooltip align="center" label="申请时间"></table-one-empty>
        <table-one-empty prop="update_time" show-overflow-tooltip align="center" label="处理时间"></table-one-empty>
        <el-table-column prop="status" align="center" label="状态" >
          <template slot-scope="scope">
            <div :style="{color: ['','#85ce61','#F56c6c','#66b1ff'][scope.row.status]}">
              {{ ['', '审核成功', '审核失败', '审核中'][scope.row.status] }}
            </div>
          </template>
        </el-table-column>
        <table-one-empty prop="edit_by" show-overflow-tooltip align="center" label="操作人" ></table-one-empty>
        <el-table-column prop="opt" align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.$index, scope.row)" type="primary"
            >编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination block">
      <pagination
        :current-page.sync="pagination.currentPage"
        :page-size.sync="pagination.pageSize"
        :total="pagination.total"
        @loadData="init"
      />
    </div>
    <el-dialog :close-on-click-modal="false" title="认证信息" :visible.sync="dialogFormVisible" width="35%">
      <p class="dialog-item">姓名：{{ currentInfo && currentInfo.name }} </p>
      <p class="dialog-item">身份证号码：{{ currentInfo && currentInfo.id_card }}</p>
      <p class="dialog-item">身份证照片</p>
      <div class="dialog-pic-list dialog-item" v-if="currentInfo">
        <el-image
          class="dialog-pic-item"
          :src="currentInfo.id_card_front_photo"
          :preview-src-list="[currentInfo.id_card_front_photo,currentInfo.id_card_front_photo]">
        </el-image>
        <el-image
          class="dialog-pic-item"
          :src="currentInfo.id_card_back_photo"
          :preview-src-list="[currentInfo.id_card_back_photo,currentInfo.id_card_back_photo]">
        </el-image>
        <el-image
          class="dialog-pic-item"
          :src="currentInfo.hand_held_id_card_photo"
          :preview-src-list="[currentInfo.hand_held_id_card_photo,currentInfo.hand_held_id_card_photo]">
        </el-image>
      </div>
      <el-radio v-model="currentInfo.status" :label="1">同意</el-radio>
      <el-radio v-model="currentInfo.status" :label="2">拒绝</el-radio>
      <el-input v-if="currentInfo.status == 2"  style="padding-top: 10px"  type="textarea" placeholder="拒绝请填写理由" v-model="currentInfo.reason">
      </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit()">提交</el-button>
        <el-button @click="dialogFormVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>

</template>
<script>
  import {realnameEdit, realnameInfo, realNameList} from "@/api/control";
  import {statusCode} from "@/util/statusCode";
  import InpSearch from "@components/common/inpSearch";
  import SearchInput from "@components/common/SearchInput";
  import Pagination from "@/pages/Layout/Pagination";
  import CommonButton from "@components/common/CommonButton";
  import TableOneEmpty from "@/components/common/tableOneEmpty.vue";
  import {FormatDay} from "@/util/common";

  export default {
    components: {
      InpSearch,
      SearchInput,
      Pagination,
      CommonButton,
      TableOneEmpty
    },
    data() {
      return {
        currentInfo: {},  //当前弹窗申请人信息
        radio: 1, //1 2 同意 拒绝
        status: '', //审核状态：1 - 审核成功，2 - 审核失败，3 - 审核中
        search: '',
        total: 0,
        loading: true,
        dialogFormVisible: false,
        tableData: [],
        searchCon: [
          {
            key: "searchKey",
            val: '',
            pla: '请输入会员ID/手机号/昵称',
            label: '请输入会员ID/手机号',
            labelFlag: false
          },
        ],
        pagination: {
          currentPage: 1,
          pageSize: 20,
          total: 0
        },
        dataRange: '',
        reason: '',
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    watch: {
      dataRange(newVal, oldVal) {
        this.pagination.pageSize = 20
        this.pagination.currentPage = 1
        this.init()
      },
      dialogFormVisible(newVal, oldVal) {  //关闭清空数据
          if (!newVal) {
            this.currentInfo = {}
        }
      }
    },
    computed: {
      apiParams() {
        return {
          status: this.status, //审核状态：1 - 审核成功，2 - 审核失败，3 - 审核中
          pageSize: this.pagination.pageSize,
          pageNum: this.pagination.currentPage,
          search: this.search,
          start_date: this.dataRange && this.dataRange[0] ? FormatDay(this.dataRange[0]) : '',
          end_date: this.dataRange && this.dataRange[1] ? FormatDay(this.dataRange[1]) : '',
        }
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      async init() {
        this.loading = true
        try {
          let {data} = await realNameList(this.apiParams)
          if (data.code === statusCode.success) {
            this.total = data.total
            this.pagination.total = data.total
            if (data.rows && data.rows.length) {
              this.tableData = JSON.parse(JSON.stringify(data.rows))
            } else {
              this.tableData = []
            }
            this.loading = false
          }
        } catch (e) {
          console.log('error--error')
        }
      },
      async handleEdit(index, row) {
        this.dialogFormVisible = true
        await realnameInfo({id: row.id}).then(res => {
          if (res.data.code === statusCode.success) this.currentInfo = res.data.data
        })
      },
      async submit() {
        let dataJson = {
          id: this.currentInfo.id,
          status: Number(this.currentInfo.status),
          reason: this.currentInfo.reason
        }
        let {data} = await realnameEdit(dataJson)
        let msg = ''
        let type = 'success'
        if (data.code === statusCode.success) {
          msg = '操作成功'
          this.dialogFormVisible = false
          await this.init()
        } else {
          msg = data.msg
          type = 'warning'
        }
        this.$message({
          message: msg,
          type,
          duration: 2000
        });
      },
      searchData() {
        this.pagination.pageSize = 20
        this.pagination.currentPage = 1
        this.init()
      },
    }
  }
</script>

<style scoped lang="scss">
  #customerList {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .filter-box {
      margin-bottom: 20px;
    }

    .pagination {
      margin-top: 10px;
    }

    .table {
      height: calc(100% - 100px);
    }
  }

  .dialog-pic-list {
    display: flex;
    justify-content: space-around;

    .dialog-pic-item {
      width: 160px;
      height: 100px;
    }
  }

  .btnAdd {
    margin-left: 15px;
  }

  .dialog-item {
    margin-bottom: 8px;
  }

  /deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
  }

  /deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
  }


  /deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
  }

  /deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
  }


  /deep/ .el-table th.el-table__cell > .cell {
    color: #6d6d6e;
    font-size: 14px;
  }


  /deep/ .el-dialog { //弹窗
    width: 600px !important;
    border-radius: 20px;
  }

  /deep/ .el-dialog__header {
    background-color: #0E3F6A;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
  }

  /deep/ .el-dialog__title {
    color: #fff;
  }

  /deep/ .el-form-item__label {
    color: #000;
    font-size: 14px;
  }

  /deep/ .el-input__inner {
    border-radius: 5px;
    border: 1px solid #B5B5B5;
  }
</style>
