<template>
  <div id="customerList">
    <div class="filter-box" style="display: flex">
      <SearchInput :search.sync="search" placeholder="有料标题/发布人" @search="searchData" />
      <div>
        <el-select v-model="status" placeholder="请选择" @change="init" clearable>
          <!-- 0，全部，1-已发售 4-已核实 5-待核实    -->
          <el-option label="全部" :value="0">全部</el-option>
          <el-option label="已发售" :value="1">已发售</el-option>
          <el-option label="已核实" :value="4">已核实</el-option>
          <el-option label="待核实" :value="5">待核实</el-option>
        </el-select>
      </div>
      <el-date-picker format="yyyy-MM-dd" style="margin-left: 20px" v-model="dataRange" type="daterange"
        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
      </el-date-picker>
      <CommonButton @click.native="add" class="btnAdd" title="新增有料" />
    </div>
    <div class="table">
      <el-table :row-style="{ height: '40px' }" :header-row-style="{ height: '40px' }" border v-loading="loading"
        ref="multipleTable" tooltip-effect="dark" :data="tableData" height="100%" style="width: 100%">
        <el-table-column type="index" width="80" align="center" label="序号"></el-table-column>
        <el-table-column prop="match_id" align="center" label="赛事ID" width="120"></el-table-column>
        <el-table-column prop="title" align="center" label="有料标题"></el-table-column>
        <el-table-column prop="type" width="100" align="center" label="类别">
          <template slot-scope="scope">
            <!-- 1 - 足球，2 - 篮球   -->
            <div>{{ scope.row.type == 1 ? '足球' : '篮球' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="coin" width="100" align="center" label="方案售价">
          <template slot-scope="scope">
            <div>{{ scope.row.coin == 0 ? "免费" : scope.row.coin }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" width="100" align="center" label="发布状态">
          <!-- 状态：1 - 已发售，2 - 待审核，3 - 未通过，4 - 已核实，5 - 待核实   -->
          <template slot-scope="scope">
            <div
              :style="scope.row.status == 1 ? 'color:#67c23a' : scope.row.status == 3 ? 'color:#f56c6c' : 'color:#409eff'">
              {{ scope.row.status == 1 ? '已发售' :
                scope.row.status == 2 ? '待审核' :
                  scope.row.status == 3 ? '未通过' :
                    scope.row.status == 4 ? '已核实' : '待核实'
              }}
            </div>
          </template>
        </el-table-column>
        <table-one-empty prop="url" align="center" label="链接地址"></table-one-empty>
        <el-table-column prop="state_text" align="center" label="赛事状态" width="120"></el-table-column>
        <el-table-column prop="create_time" align="center" label="创建时间"></el-table-column>
        <el-table-column prop="update_time" align="center" label="编辑时间"></el-table-column>
        <el-table-column prop="author" width="120" align="center" label="发布人"></el-table-column>
        <table-one-empty prop="edit_by" width="120" align="center" label="操作人"></table-one-empty>
        <el-table-column prop="" width="120" align="center" label="操作">
          <template slot-scope="scope">
            <el-button v-if="type != 2" @click="handleEdit(scope.$index, scope.row)" type="primary">编辑</el-button>
            <div v-else>-</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination block">
      <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
        :total="pagination.total" @loadData="init" />
    </div>
    <el-dialog :title="popupType === 'add' ? '新增' : '编辑'" :close-on-click-modal="false" :visible.sync="dialogFormVisible"
      width="35%" :before-close="cancel">
      <el-form :model="form" :rules="rules" ref="formName">
        <el-form-item label="专家" :label-width="formLabelWidth" prop="member_id">
          <el-select v-model="form.member_id" placeholder="请选择" :disabled="isEdit">
            <el-option v-for="item in expertListData" :label="item.nickname" :value="item.member_id"
              :key="item.member_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有料标题" :label-width="formLabelWidth" prop="title">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="类别" :label-width="formLabelWidth" prop="type">
          <el-radio-group v-model="form.type" @change="getleagues('clear')" :disabled="isEdit">
            <el-radio :label="1">足球</el-radio>
            <el-radio :label="2">篮球</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择赛事" :label-width="formLabelWidth" required>
          <el-date-picker v-model="day" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" @change="getleagues('clear')" :disabled="isEdit">
          </el-date-picker>
        </el-form-item>
        <div class="flex">
          <el-form-item label="联赛名称" :label-width="formLabelWidth" prop="league_id">
            <el-select v-model="form.league_id" placeholder="请选择" @change="getMatches('clear')" clearable :disabled="isEdit">
              <el-option :label="item.nameChsShort" :value="item.leagueId" v-for="item in selectLeaguesData"
                :key="item.leagueId">{{ item.nameChsShort }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="比赛" :label-width="formLabelWidth" prop="match_id">
            <el-select v-model="form.match_id" placeholder="请选择" clearable @change="getOddsList" :disabled="isEdit">
              <el-option :label="`${item.homeChs}-${item.awayChs}`" :value="item.matchId" v-for="item in scheduleData"
                :key="item.matchId">{{ `${item.homeChs}-${item.awayChs}` }}</el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="玩法" :label-width="formLabelWidth" v-if="form.match_id" prop="play">
          <el-radio-group v-model="form.play" :disabled="isEdit">
            <el-radio :label="form.type == 1 ? 11 : 21">{{ form.type == 1 ? '胜负平' : '胜负' }}</el-radio>
            <el-radio :label="form.type == 1 ? 12 : 22">{{ form.type == 1 ? '让球' : '让分' }}</el-radio>
            <el-radio :label="form.type == 1 ? 13 : 23">{{ form.type == 1 ? '大小' : '总分' }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 11 - 胜负平，12 - 让球，13 - 大小。篮球玩法：21 -胜负，22 - 让分，23 - 总分   -->
        <!-- 选择了玩法 && 选择了比赛 -->
        <el-form-item v-if="form.play && form.match_id" :label="form.play == 11 ? '胜负平' :
          form.play == 12 ? '让球' :
            form.play == 13 ? '大小' :
              form.play == 21 ? '胜负' :
                form.play == 22 ? '让分' : '总分'" :label-width="formLabelWidth" prop="odds">
          <!-- 足球胜负平 odds_europe-->
          <el-radio-group v-model="form.odds" v-if="form.play == 11" :disabled="isEdit">
            <el-radio :label="`主胜${form.odds_europe.init_home_win_odds}`">主胜{{ form.odds_europe.init_home_win_odds }}</el-radio>
            <el-radio :label="`平${form.odds_europe.init_tie_odds}`">平{{ form.odds_europe.init_tie_odds }}</el-radio>
            <el-radio :label="`客胜${form.odds_europe.init_away_win_odds}`">客胜{{ form.odds_europe.init_away_win_odds }}</el-radio>
          </el-radio-group>
          <!-- 足球让球  odds_handicap-->
          <el-radio-group v-model="form.odds" v-if="form.play == 12" :disabled="isEdit">
            <el-radio :label="`主胜${form.odds_handicap.init_home_odds}`">主胜{{ form.odds_handicap.init_home_odds }}</el-radio>
            <el-radio :label="`让${form.odds_handicap.init_odds}`" disabled>让{{ form.odds_handicap.init_odds }}</el-radio>
            <el-radio :label="`客胜${form.odds_handicap.init_away_odds}`">客胜{{ form.odds_handicap.init_away_odds }}</el-radio>
          </el-radio-group>
          <!-- 足球大小  odds_over_under-->
          <el-radio-group v-model="form.odds" v-if="form.play == 13" :disabled="isEdit">
            <el-radio :label="`大${form.odds_over_under.init_over_odds}`">大{{ form.odds_over_under.init_over_odds }}</el-radio>
            <el-radio :label="form.odds_over_under.init_odds" disabled>{{ form.odds_over_under.init_odds }}</el-radio>
            <el-radio :label="`小${form.odds_over_under.init_under_odds}`">小{{ form.odds_over_under.init_under_odds }}</el-radio>
          </el-radio-group>
          <!-- 篮球胜负  odds_moneyline-->
          <el-radio-group v-model="form.odds" v-if="form.play == 21" :disabled="isEdit">
            <el-radio :label="`主胜${form.odds_moneyline.init_home_win_odds}`">主胜{{ form.odds_moneyline.init_home_win_odds }}</el-radio>
            <el-radio :label="`客胜${form.odds_moneyline.init_away_win_odds}`">客胜{{ form.odds_moneyline.init_away_win_odds }}</el-radio>
          </el-radio-group>
          <!-- 篮球让分  odds_spread-->
          <el-radio-group v-model="form.odds" v-if="form.play == 22" :disabled="isEdit">
            <el-radio :label="`主胜${form.odds_spread.init_home_odds}`">主胜{{ form.odds_spread.init_home_odds }}</el-radio>
            <el-radio :label="`让${form.odds_spread.init_odds}`" disabled>让{{ form.odds_spread.init_odds }}</el-radio>
            <el-radio :label="`客胜${form.odds_spread.init_away_odds}`">客胜{{ form.odds_spread.init_away_odds }}</el-radio>
          </el-radio-group>
          <!-- 篮球大小  odds_total-->
          <el-radio-group v-model="form.odds" v-if="form.play == 23" :disabled="isEdit">
            <el-radio :label="`大${form.odds_total.init_over_odds}`">大{{ form.odds_total.init_over_odds }}</el-radio>
            <el-radio :label="form.odds_total.init_odds" disabled>{{ form.odds_total.init_odds }}</el-radio>
            <el-radio :label="`小${form.odds_total.init_under_odds}`">小{{ form.odds_total.init_under_odds }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="flex">
            <el-form-item label="售价类型" :label-width="formLabelWidth" prop="sales_type">
                <el-select v-model="form.sales_type" placeholder="请选择" @change="sellTypeChange('clear')" :disabled="isEdit">
                    <el-option label="球币" :value="1"></el-option>
                    <el-option label="球票" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="方案售价" :label-width="formLabelWidth" prop="coin">
                <el-select v-model="form.coin" placeholder="请选择" :disabled="isEdit">
                    <el-option v-for="item,index in sellMoneyTypes" :label="item.price" :value="item.price" :key="index" />
                </el-select>
            </el-form-item>
        </div>
        <el-form-item label="链接地址" :label-width="formLabelWidth" prop="url">
          <el-input v-model="form.url" autocomplete="off" :disabled="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="备注说明" :label-width="formLabelWidth">
          <el-input v-model="form.remark" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="推荐理由" :label-width="formLabelWidth">
          <el-input v-model="form.recommend" type="textarea" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="formValidate('formName')">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchInput from "@components/common/SearchInput"
import CommonButton from "@components/common/CommonButton"
import Pagination from "@/pages/Layout/Pagination"
import { haveList, addHaveList, editHaveList, expertList, oddsList, haveSellMoney, getOddsLeagues, getOddsSchedule } from "@/api/haveManage"
import dayjs from 'dayjs'
import { statusCode } from "@/util/statusCode"
import { getLeagues, getMatchesByDay } from '@/api/Host/Host'
import TableOneEmpty from '@/components/common/tableOneEmpty.vue'
export default {
  components: {
    SearchInput,
    CommonButton,
    Pagination,
    TableOneEmpty
  },
  data() {
    return {
      search: '',
      status: 0,
      type: '',
      dataRange: '',
      loading: false,
      tableData: [],
      total: 0,
      pagination: {
        currentPage: 1,
        pageSize: 20,
        total: 0
      },
      dialogFormVisible: false,
      popupType: 'add',  //add||edit
      day: dayjs().format('YYYY-MM-DD'),
      formLabelWidth: '100px',
      selectLeaguesData: [], //联赛
      scheduleData: [], // 赛事
      expertListData: [], //专家列表
      form: {
        odds_europe: { //足球胜负
          init_home_win_odds: '',
          init_tie_odds: '',
          init_away_win_odds: ''
        },
        odds_handicap: { // 足球让球
          init_home_odds: '',
          init_odds: '',
          init_away_odds: ''
        },
        odds_over_under: { // 足球大小
          init_over_odds: '',
          init_odds: '',
          init_under_odds: ''
        },
        odds_moneyline: { // 篮球胜负
          init_home_win_odds: '',
          init_away_win_odds:''
        },
        odds_spread: { // 篮球让分
          init_home_odds: '',
          init_odds: '',
          init_away_odds: ''
        },
        odds_total: { // 篮球大小
          init_over_odds: '',
          init_odds: '',
          init_under_odds:''
        }
      },
      rules: {
        member_id: [
            { required: true, message: '请选择专家', trigger: 'change' }
        ],
        title: [
            { required: true, message: '请输入有料标题', trigger: 'blur'}
        ],
        type: [
            { required: true, message: '请选择类别', trigger: 'change'}
        ],
        league_id: [
            { required: true, message: '请选择联赛名称', trigger: 'change'}
        ],
        match_id: [
            { required: true, message: '请选择比赛', trigger: 'change'}
        ],
        play: [
            { required: true, message: '请选择玩法', trigger: 'change'}
        ],
        odds: [
            { required: true, message: '请选择类别', trigger: 'change'}
        ],
        sales_type: [
            { required: true, message: '请选择售价类型', trigger: 'change'}
        ],
        coin: [
            { required: true, message: '请选择方案售价', trigger: 'change'}
        ],
        url: [
            { required: true, message: '请填写链接地址', trigger: 'blur'}
        ],
        remark: [
            { required: true, message: '请填写备注说明', trigger: 'blur'}
        ],
        recommend: [
            { required: true, message: '请填写推荐理由', trigger: 'blur'}
        ]
    },
    sellMoney: [], // 所有售卖金额
    sellMoneyTypes: [] // 选中类型金额
    }
  },
  computed: {
    apiParams() {
      return {
        status: this.status,
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
        search: this.search,
        start_date: this.dataRange && this.dataRange[0] ? dayjs(this.dataRange[0]).format('YYYY-MM-DD') : '',
        end_date: this.dataRange && this.dataRange[1] ? dayjs(this.dataRange[1]).format('YYYY-MM-DD') : '',
      }
    },
    isEdit() {
        return this.popupType === 'edit'
    }
  },
  watch: {
    dataRange(newVal, oldVal) {
      this.pagination.pageSize = 20
      this.pagination.currentPage = 1
      this.init()
    },
  },
  mounted() {
    this.init()
    this.getExpertList()
    this.getHaveSellMoney()
  },
  methods: {
    async init() {
      this.loading = true
      try {
        let { data } = await haveList(this.apiParams)
        if (data.code === statusCode.success) {
          this.total = data.total
          this.pagination.total = data.total
          if (data.rows && data.rows.length) {
            this.tableData = JSON.parse(JSON.stringify(data.rows))
          } else {
            this.tableData = []
          }
          this.loading = false
        }
      } catch (e) {
        console.log('error--error')
      }
    },
    searchData() {
      this.pagination.pageSize = 20
      this.pagination.currentPage = 1
      this.init()
    },
    add() {
      this.day = dayjs().format('YYYY-MM-DD')
      this.selectLeaguesData = []
      this.scheduleData = []
      this.dialogFormVisible = true
      this.popupType = "add"
    },
    handleEdit(index, row) {
      this.dialogFormVisible = true
      this.popupType = "edit"
      this.form = {
        id: row.id,
        member_id: row.member_id,
        title: row.title,
        type: row.type,
        play: row.play,
        league_id: row.league_id,
        match_id: row.match_id,
        odds: row.odds,
        coin: row.coin,
        sales_type: row.sales_type,
        url: row.url,
        recommend: row.recommend,
        remark: row.remark,
        odds_europe: row.odds_europe, // 足球：胜负平
        odds_handicap: row.odds_handicap, // 足球：让球
        odds_over_under: row.odds_over_under, // 足球： 大小
        odds_moneyline: row.odds_moneyline, //篮球：胜负
        odds_spread: row.odds_spread, //篮球：让分
        odds_total: row.odds_total // 篮球：大小
      }
      this.day = dayjs(row.matchTime).format('YYYY-MM-DD')
      // 获取对应联赛和比赛
      this.getleagues(1)
      this.getMatches(1)
      // this.getOddsList()
      this.sellTypeChange()
    },
    async getleagues(clear) {
      if (clear === 'clear') { //@change进来的需要清空之前的数据
        this.$set(this.form, 'league_id', '')
        this.$set(this.form, 'match_id', '')
        this.$set(this.form,'play',"")
        this.$set(this.form,'odds',"")
      }
      //type： 1足球 2篮球 3电竞 4其他
      let { data } = await getOddsLeagues(this.form.type, dayjs(this.day).format('YYYY-MM-DD'))
      this.selectLeaguesData = data.data
    },
    async getMatches(clear) {
      if (clear === 'clear') { //@change进来的需要清空之前的数据
        this.$set(this.form, 'match_id', '')
        this.$set(this.form,'play',"")
        this.$set(this.form,'odds',"")
      }
      // leagueType:1足球，2篮球
      let dataJson = {
        day: dayjs(this.day).format('YYYY-MM-DD'),
        leagueId: this.form.league_id,
        leagueType: this.form.type
      }
      let { data } = await getOddsSchedule(dataJson)
      this.scheduleData = data.data
    },
    async getOddsList() { //赔率获取
      let dataJson = {
        match_id: this.form.match_id,
        type: this.form.type
      }
      let { data } = await oddsList(dataJson)
      this.form.odds_europe = data.data.odds_europe, // 足球：胜负平
      this.form.odds_handicap = data.data.odds_handicap, // 足球：让球
      this.form.odds_over_under = data.data.odds_over_under, // 足球： 大小
      this.form.odds_moneyline = data.data.odds_moneyline, //篮球：胜负
      this.form.odds_spread = data.data.odds_spread, //篮球：让分
      this.form.odds_total = data.data.odds_total // 篮球：大小
    },
    async getExpertList() { //专家获取
      let dataJson = {
        pageNum: 1,
        pageSize: 10000
      }
      let { data } = await expertList(dataJson)
      this.expertListData = data.rows
    },
    async getHaveSellMoney() { // 售卖类型
        const { data } = await haveSellMoney()
        if (data.code === statusCode.success) {
            if (data.data && data.data.length) {
                this.sellMoney = data.data
            } else {
                this.sellMoney = []
            }
        }
    },
    sellTypeChange(clear) { // 售卖类型切换
        if (clear === 'clear') this.$set(this.form,'coin','')
        this.sellMoneyTypes = this.sellMoney.filter((item) => {
            if (item.type === this.form.sales_type) {
                return item
            }
        })
    },
    // 表单验证
    formValidate (formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
                this.submit()
            }
        })
    },
    async submit() {
      let dataJson = {
          type: this.form.type,
          play: this.form.play,
          match_id: this.form.match_id, //比赛id
          league_id: this.form.league_id, //联赛id
          member_id: this.form.member_id, //专家id
          title: this.form.title,
          odds: this.form.odds,
          coin: this.form.coin,
          sales_type: this.form.sales_type,
          url: this.form.url,
          recommend: this.form.recommend,
          remark: this.form.remark,
          matchTime: this.day
        }
        const request = this.popupType === 'add' ? addHaveList: editHaveList
        let message = '操作成功', type = 'success', duration = 2000
        if (this.popupType === 'edit') dataJson['id'] = this.form.id
        let { data } = await request(dataJson)
        if (data.code === statusCode.success) {
            this.cancel()
            await this.init()
        } else {
            message = data.msg, type = 'warning'
        }
        this.$message({message, type, duration})
    },
    cancel () {
        this.form = {}
        this.$refs['formName'] && this.$refs['formName'].resetFields()
        this.dialogFormVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
#customerList {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .filter-box {
    margin-bottom: 20px;
  }

  .pagination {
    margin-top: 10px;
  }

  .table {
    height: calc(100% - 100px);
  }
}

.btnAdd {
  margin-left: 15px;
}

/deep/ .el-table .descending .sort-caret.descending {
  border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
  border-bottom-color: #189e90;
}


/deep/ .el-table tr {
  cellspacing: 10px;
  border: 1px solid #CCCCCC;
  padding: 15px 0;
}

/deep/ .el-table__body {
  border-collapse: separate;
  //border-spacing: 0 10px;
  background-color: #f6f6f6;
}


/deep/ .el-table th.el-table__cell>.cell {
  color: #6d6d6e;
  font-size: 14px;
}


/deep/ .el-dialog {
  //弹窗
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}</style>
