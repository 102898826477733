<template>
    <div id="consumer-record">
        <div class="search flex">
            <search-input :search.sync="order_no" placeholder="订单号" @search="searchData" />
            <search-input :search.sync="search" placeholder="ID/昵称/手机号" @search="searchData" />
            <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" class="date">
            </el-date-picker>
            <div>
                <el-select v-model="status" placeholder="交易状态" @change="init" class="select">
                    <el-option label="全部" :value="0">全部</el-option>
                    <el-option label="交易成功" :value="1">交易成功</el-option>
                    <el-option label="交易失败" :value="2">交易失败</el-option>
                </el-select>
            </div>
        </div>
        <div class="table">
            <el-table v-loading="loading" :data="tableData" height="100%" style="width:100%" :row-style="{ height: '40px' }"
                :header-row-style="{ height: '40px' }" border>
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column label="订单号" prop="order_no" align="center" width="210" />
                <el-table-column label="会员ID" prop="member_id" align="center" />
                <el-table-column label="会员昵称" prop="member_nickname" align="center" />
                <table-one-empty label="手机号" prop="mobile" align="center" />
                <el-table-column label="主播/专家/会员ID" prop="expert_id" align="center" />
                <el-table-column label="主播/专家/会员昵称" prop="expert_nickname" align="center" width="160" />
                <el-table-column label="消费方式" prop="type" align="center" >
                    <template slot-scope="scope">
                        {{ ['-','球币','球票'][scope.row.type] }}
                    </template>
                </el-table-column>
                <el-table-column label="消费金额" prop="coin" align="center" />
                <el-table-column label="是否参与分成" prop="is_share" align="center">
                    <template slot-scope="scope">
                        <span>
                            {{ ['-','参与','不参与'][scope.row.is_share] }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="分成比例" prop="share_percent" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.share_percent ? `${scope.row.share_percent}%` : '-'}}
                    </template>
                </el-table-column>
                <el-table-column label="消费时间" prop="create_time" align="center" width="180" />
                <el-table-column label="交易状态" prop="status" align="center">
                    <template slot-scope="scope">
                        <span :style="{color: ['','#67C23A','#F56C6C'][scope.row.status]}">
                            {{ ['-','交易成功','交易失败'][scope.row.status] }}
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagin">
            <pagination :current-page.sync="pagination.currentPage" :page-size.sync="pagination.pageSize"
                :total="pagination.total" @loadData="init" />
        </div>
    </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput.vue';
import Pagination from '@/pages/Layout/Pagination.vue';
import { haveDetail } from '@/api/haveManage'
import { statusCode } from '@/util/statusCode'
import dayjs from 'dayjs'
import TableOneEmpty from '@/components/common/tableOneEmpty.vue';
export default {
    components: {
        SearchInput,
        Pagination,
        TableOneEmpty
    },
    data () {
        return {
            search: '',
            status: 0,
            order_no: '',
            dateRange: '',
            type: '',
            loading: false,
            tableData: [],
            pagination: {
                currentPage: 1,
                pageSize: 20,
                total: 0
            },
            formLabelWidth: '100px',
            form: {},
            dialogFormVisible: false
        }
    },
    computed: {
        apiParams() {
            return {
                pageNum: this.pagination.currentPage,
                pageSize: this.pagination.pageSize,
                status: this.status,
                search: this.search,
                order_no: this.order_no,
                start: this.dateRange && this.dateRange[0] ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : '',
                end: this.dateRange && this.dateRange[1] ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : '',
            }
        }
    },
    watch: {
        dateRange (newVal,oldVal) {
            this.init()
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        async init () {
            this.loading = true
            try {
                const { data } = await haveDetail(this.apiParams)
                if (data.code === statusCode.success) {
                    if (data.rows && data.rows.length) {
                        this.tableData = data.rows
                        this.pagination.total = data.total
                    } else {
                        this.tableData = []
                    }
                }
                this.loading = false
            } catch (e) {
                console.error(e)
            }
        },
        searchData () {
            this.pagination.currentPage = 1
            this.pagination.pageSize = 20
            this.init()
        }
    }
}
</script>

<style lang="scss" scoped>
#consumer-record {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .search {
        margin-bottom: 20px;

        .date {
            margin-right: 15px;
        }
        .select {
            margin-right: 15px;
        }
        .addBtn {
            margin-left: 15px;
        }
    }

    .table {
        height: calc(100% - 100px);
    }

    .pagin {
        margin-top: 10px;
    }
}

// 表格
/deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #189e90;
}

/deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #189e90;
}


/deep/ .el-table tr {
    cellspacing: 10px;
    border: 1px solid #CCCCCC;
    padding: 15px 0;
}

/deep/ .el-table__body {
    border-collapse: separate;
    //border-spacing: 0 10px;
    background-color: #f6f6f6;
}


//弹窗
/deep/ .el-dialog {
  width: 600px !important;
  border-radius: 20px;
}

/deep/ .el-dialog__header {
  background-color: #0E3F6A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 14px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid #B5B5B5;
}
</style>